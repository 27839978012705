import Admin from "./pages/Admin.vue";
import App from "./App.vue";
import Login from "./pages/Login.vue";
//import Home from './pages/Home.vue'
import Operateur from "./pages/Operateur.vue";
import Praticien from "./pages/Praticien.vue";
import Router from "vue-router";
import UserManagement from "./pages/UserManagement.vue";
import Vue from "vue";
import avatar from "./assets/icons/avatar.svg";
import dashboard from "./assets/icons/dashboard.svg";
import doctor from "./assets/icons/doctor.svg";
import folder from "./assets/icons/folder.svg";
import global from "./pages/global.vue";
import laboratories from "./pages/laboratories.vue";
import param from "./assets/icons/param.svg";
import parametre from "./pages/parameter.vue";
import noun from "./assets/icons/inventory2.svg";
import analytics from "./assets/icons/analytics.svg";
import screen from "./assets/icons/screen.svg";
import userDoctor from "./pages/userDoctor.vue";
import userScreen from "./pages/userScreen.vue";
import archive from "./pages/Archive.vue";
import reports from "./pages/Reports.vue";

//import list from './assets/icons/list.svg'

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Accueil",
      component: App,
      redirect: "/accueil",
      meta: {
        visible: false,
        title: "Accueil",
      },
    },
    {
      path: "/accueil",
      name: "Admin",
      icon: dashboard,
      meta: {
        visible: true,
        title: "Admin",
      },
      component: Admin,
    },
    {
      path: "/operateur",
      name: "Operateur",
      icon: folder,
      meta: {
        visible: true,
        title: "Operateur",
      },
      component: Operateur,
    },
    {
      path: "/archive",
      name: "Archives",
      icon: noun,
      meta: {
        visible: true,
        title: "Archives",
      },
      component: archive,
    },
    {
      path: "/rapport",
      name: "Rapports",
      icon: analytics,
      meta: {
        visible: true,
        title: "Rapports",
      },
      component: reports,
    },
    {
      path: "/user-management",
      name: "Gestion d'utilisateurs",
      icon: avatar,
      meta: {
        visible: true,
        title: "Gestion d'utilisateurs",
      },
      component: UserManagement,
    },
    {
      path: "/user-doctor",
      name: "Gestion des docteurs",
      icon: doctor,
      meta: {
        visible: true,
        title: "Gestion des docteurs",
      },
      component: userDoctor,
    },
    {
      path: "/user-screen",
      name: "Gestion des comptes écrans",
      icon: screen,
      meta: {
        visible: true,
        title: "Gestion des comptes écrans",
      },
      component: userScreen,
    },
    {
      path: "/parametre",
      name: "Parametre",
      icon: param,
      meta: {
        visible: true,
        title: "parametre",
      },
      component: parametre,
    },
    /*{
            path: "/admin",
            name: "Admin",
            icon: list,
            meta: {
                visible: true,
                title: "Admin"
            },
            component: Admin
        },*/
    {
      path: "/global",
      name: "Global",
      meta: {
        visible: false,
        title: "Global",
      },
      component: global,
    },
    {
      path: "/login",
      name: "Login",
      meta: {
        visible: false,
        title: "Login",
      },
      component: Login,
    },
    {
      path: "/praticien",
      name: "Praticien",
      meta: {
        visible: false,
        title: "Praticien",
      },
      component: Praticien,
    },
    {
      path: "/laboratories",
      name: "laboratories",
      meta: {
        visible: false,
        title: "laboratories",
      },
      component: laboratories,
    },
  ],
});
export default router;
