import { API_BASE_URL, API_ROUTES } from "../api-constant.js";
import store from "../store";
import axios from "axios";
export default {
  async getTags() {
    const request = await axios.get(
      API_BASE_URL + API_ROUTES.getTagsByLaboId + store.state.user.laboId,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async postTag(tag) {
    const request = await axios.post(
      API_BASE_URL + API_ROUTES.tags,
      {
        tag,
        laboId: store.state.user.laboId,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async putTag(tag) {
    const request = await axios.put(
      API_BASE_URL + API_ROUTES.tags + tag.id,
      {
        tag: tag.tag,
        laboId: store.state.user.laboId,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async deleteTag(tag) {
    const request = await axios.delete(
      API_BASE_URL + API_ROUTES.tags + tag.id,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async getTagsByLaboId(id) {
    const request = await axios.get(
      API_BASE_URL + API_ROUTES.getTagsByLaboId + id,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },
};
