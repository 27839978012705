import axios from "axios";
import { API_BASE_URL, API_ROUTES } from "../api-constant.js";
export default {
  async postUserLogin(login) {
    const request = await axios.post(API_BASE_URL + API_ROUTES.signup, {
      email: login.email,
      password: login.password,
      resetKey: login.resetKey,
    });
    return request;
  },
  async postConnection(login) {
    const request = await axios
      .post(API_BASE_URL + API_ROUTES.login, {
        email: login.email,
        password: login.password,
      })
      .catch(() => {
        return { status: 422 };
      });
    return request;
  },
  async checkEmailExist(email, token) {
    const request = await axios.get(
      API_BASE_URL + API_ROUTES.checkEmailExist + email,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return request;
  },

  async resetPassword(email) {
    const request = await axios
      .post(API_BASE_URL + API_ROUTES.resetPassword, {
        email: email,
      })
      .catch(() => {
        return { status: 422 };
      });
    return request;
  },

  async resetPasswordFinish(passwordInfos) {
    const request = await axios.put(
      API_BASE_URL + API_ROUTES.resetPasswordFinish,
      {
        resetKey: passwordInfos.resetKey,
        password: passwordInfos.password,
        confirmPassword: passwordInfos.confirmPassword,
      }
    );
    return request;
  },
};
