<template>
  <div class="tagPage">
    <v-snackbar
      v-model="snackbar.visible"
      auto-height
      :color="snackbar.color"
      :multi-line="snackbar.mode === 'multi-line'"
      :timeout="snackbar.timeout"
      :top="snackbar.position === 'top'"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>
          {{ snackbar.icon }}
        </v-icon>
        <v-layout column>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
    <v-btn
      color="#003b5b"
      style="margin-top: 25px"
      outlined
      dark
      class="mb-2 addCasBtn"
      @click="openModal()"
    >
      {{ addButton }}
      <v-icon class="ml-3"> mdi-plus-circle-outline </v-icon>
    </v-btn>
    <div v-if="itemToEdit === 'material'" class="tagsCards">
      <div v-for="(tag, index) in this.tags.materials" :key="tag.id">
        <v-chip class="tagCard" color="#dfe6e9">
          <span
            :class="'blue-grey--text' + ' tagName'"
            color="blue-grey--text"
            >{{ tag.name + " " }}</span
          >
          <v-icon
            small
            class="grey--text mr-2 deleteTag"
            @click="openModal(tag, index, 'edit')"
          >
            mdi-pencil
          </v-icon>
        </v-chip>
      </div>
    </div>
    <div v-if="itemToEdit === 'article'" class="tagsCards">
      <div v-for="(tag, index) in this.tags.articles" :key="tag.id">
        <v-chip class="tagCard" color="#D0E5F7">
          <span :class="'indigo--text' + ' tagName'" color="indigo--text">{{
            tag.name + " "
          }}</span>
          <v-icon
            small
            class="indigo--text mr-2 deleteTag"
            @click="openModal(tag, index, 'edit')"
          >
            mdi-pencil
          </v-icon>
        </v-chip>
      </div>
    </div>

    <template />
    <v-dialog v-model="modalAdd" max-width="1200px">
      <v-card class="modaltags">
        <v-card-title class="modalTitle">
          <span class="headline">
            {{ modalTitle }}
            <v-icon class="modalTagIcon" medium> {{ icon }} </v-icon>
          </span>
          <v-icon large @click="closeModal"> mdi-close </v-icon>
        </v-card-title>
        <v-form ref="form" lazy-validation>
          <div class="tagsForm">
            <v-text-field
              v-if="itemToEdit === 'material'"
              v-model="item.name"
              class="tagsInput"
              :rules="[rules.required]"
              label="Nom du matériau"
            >
              <v-icon slot="prepend" color="rgb(243, 156, 18)">
                mdi-tag-outline
              </v-icon>
            </v-text-field>
            <v-text-field
              v-if="itemToEdit === 'article'"
              v-model="item.name"
              class="tagsInput"
              :rules="[rules.required]"
              label="Nom de l'article"
            >
              <v-icon slot="prepend" color="rgb(243, 156, 18)">
                mdi-tag-outline
              </v-icon>
            </v-text-field>
            <v-text-field
              v-if="itemToEdit === 'article'"
              v-model="item.code"
              class="tagsInput"
              :rules="[rules.required]"
              label="Code de l'article"
            >
              <v-icon slot="prepend" color="rgb(243, 156, 18)">
                mdi-tag-outline
              </v-icon>
            </v-text-field>
          </div>
          <div class="center">
            <v-checkbox
              v-if="itemToEdit === 'material'"
              v-model="item.visible"
              label=" Afficher le matériau "
            />
            <v-checkbox
              v-if="itemToEdit === 'article'"
              v-model="item.visible"
              label=" Afficher l'article "
            />
          </div>
        </v-form>
        <v-btn
          v-if="!this.editModal"
          class="greenBtn"
          color="green darken-1"
          text
          @click="postTag"
        >
          Sauvegarder
        </v-btn>
        <v-btn
          v-if="this.editModal"
          class="deleteBtn"
          color="red darken-1"
          text
          @click="deleteTag()"
        >
          {{ deleteButton }}
        </v-btn>
        <v-btn
          v-if="this.editModal"
          class="greenBtn"
          color="green darken-1"
          text
          @click="editTag()"
        >
          Sauvegarder
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import materialRepository from "../../repository/materialRepository.js";
import articleRepository from "../../repository/articleRepository.js";

export default {
  props: {
    itemToEdit: String,
  },
  data() {
    return {
      active: false,
      modalAdd: false,
      tags: null,
      editModal: false,
      currentTag: [],
      originalTags: [],
      item: {
        name: "",
        code: "",
        color: "",
        visible: true,
      },
      tagId: null,
      rules: {
        required: (val) => val.length > 0 || "Ce champ est requis",
      },
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 7500,
        visible: false,
      },
      timeout: 7500,
    };
  },
  computed: {
    selector: function () {
      if (!this.selectedColor) {
        return "Couleur";
      } else {
        return (
          '<span style="background: ' +
          this.selectedColor +
          '"></span> ' +
          this.selectedColorName
        );
      }
    },
    modalTitle() {
      if (this.editModal === true && this.itemToEdit === "material") {
        return "Modifier un matériau";
      } else if (this.editModal === true && this.itemToEdit === "article") {
        return "Modifier un article";
      } else if (this.editModal === false && this.itemToEdit === "article") {
        return "Ajouter un article";
      } else {
        return "Ajouter un matériau";
      }
    },
    addButton() {
      if (this.itemToEdit === "material") {
        return "Ajouter un matériau";
      } else {
        return "Ajouter un article";
      }
    },
    deleteButton() {
      if (this.itemToEdit === "material") {
        return " Supprimer le matériau";
      } else {
        return "Supprimer l'article";
      }
    },
    icon() {
      if (this.editModal === true) {
        return "mdi-pencil-outline";
      } else {
        return "mdi-tag-outline";
      }
    },
  },
  async mounted() {
    this.getCurrentTag();
  },
  methods: {
    SnackbarShow(text, status, isOk) {
      if (status === isOk) {
        this.snackbar = {
          color: "success",
          icon: "mdi-check-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: text,
          visible: true,
        };
      } else {
        this.snackbar = {
          color: "error",
          icon: "mdi-alert-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: "Une erreur est survenue",
          visible: true,
        };
      }
    },
    toggleDropdown: function () {
      this.active = !this.active;
    },
    async getCurrentTag() {
      if (this.itemToEdit === "material") {
        const currentTag = await materialRepository.getMaterials();
        if (currentTag.data.length > 0) {
          this.tags = currentTag.data[0];
        } else {
          this.tags = { materials: [] };
        }
        return this.tags;
      } else if (this.itemToEdit === "article") {
        const currentTag = await articleRepository.getArticles();
        this.originalTags = currentTag.data;
        if (currentTag.data.length > 0) {
          if (currentTag.data.length >= 2) {
            const tmpArr = [];
            currentTag.data.forEach((element) => {
              element.articles.forEach((artcl) => {
                const editedArtcl = { ...artcl };
                editedArtcl.collectionId = element.id;
                tmpArr.push(editedArtcl);
              });
            });
            const final = {
              articles: tmpArr,
              id: currentTag.data[1].id,
              laboId: currentTag.data[1].laboId,
            };
            this.tags = final;
          } else {
            this.tags = currentTag.data[0];
          }
        } else {
          this.tags = { articles: [] };
        }
        return this.tags;
      }
    },
    openModal(tag, index, edit) {
      if (edit) {
        this.editModal = true;
        this.item.name = tag.name;
        this.item.visible = tag.visible;
        this.item.index = index;
        this.item.code = tag.code;
        this.item.collectionId = tag.collectionId;
      }
      this.modalAdd = true;
    },
    closeModal() {
      this.modalAdd = false;
      this.editModal = false;
      this.item.name = "";
    },
    async deleteTag() {
      const index = this.item.index;
      if (this.itemToEdit === "article") {
        const findCollection = this.lodash.find(this.originalTags, {
          id: this.item.collectionId,
        });
        findCollection.articles.splice(index, 1);
        this.tags.articles.splice(index, 1);
        const articleArray = {
          id: findCollection.id,
          articles: findCollection.articles,
        };
        const request = await articleRepository.putArticle(articleArray);
        this.modalAdd = false;
        this.SnackbarShow("L'article a été supprimé", request.status, 204);
        this.getCurrentTag();
        this.editModal = false;
      }
    },
    async editTag() {
      if (this.itemToEdit === "material") {
        this.tags.materials[this.item.index] = {
          name: this.item.name,
          visible: this.item.visible,
        };
        const materialArray = {
          id: this.tags.id,
          materials: this.tags.materials,
        };
        this.item.visible = true;
        const request = await materialRepository.putMaterial(materialArray);
        this.SnackbarShow(
          "Le matériau a bien était modifié ",
          request.status,
          204
        );
        this.editModal = false;
        this.item.name = "";
      } else if (this.itemToEdit === "article") {
        this.tags.articles[this.item.index] = {
          code: this.item.code,
          name: this.item.name,
          visible: this.item.visible,
        };
        const articleArray = {
          id: this.tags.id,
          articles: this.tags.articles,
        };
        this.item.visible = true;
        const request = await articleRepository.putArticle(articleArray);
        this.SnackbarShow(
          "L'article a bien était modifié ",
          request.status,
          204
        );
        this.editModal = false;
        this.item.name = "";
      }
      this.modalAdd = false;
    },
    async postTag() {
      if (this.$refs.form.validate()) {
        if (this.tags.id !== undefined) {
          if (this.itemToEdit === "material") {
            this.tags.materials.push({
              code: this.item.code,
              name: this.item.name,
              visible: this.item.visible,
            });
            const tagsArray = {
              id: this.tags.id,
              materials: this.tags.materials,
            };
            const request = await materialRepository.putMaterial(tagsArray);
            this.SnackbarShow(
              "Le matériau a bien était ajouté ",
              request.status,
              204
            );
            this.getCurrentTag();
          } else if (this.itemToEdit === "article") {
            this.tags.articles.push({
              code: this.item.code,
              name: this.item.name,
              visible: this.item.visible,
            });
            const tagsArray = {
              id: this.tags.id,
              articles: this.tags.articles,
            };
            const request = await articleRepository.putArticle(tagsArray);
            this.SnackbarShow(
              "L'article a bien était ajouté ",
              request.status,
              204
            );
            this.getCurrentTag();
          }
          this.item.visible = true;
          this.$refs.form.resetValidation();
          this.modalAdd = false;
          this.item.name = "";
        } else {
          const tags = [];
          tags.push({
            name: this.item.name,
            visible: this.item.visible,
          });
          if (this.itemToEdit === "material") {
            const request = await materialRepository.postMaterial(tags);
            this.SnackbarShow(
              "Le matériau a bien était ajouté ",
              request.status,
              200
            );
            this.getCurrentTag();
          } else if (this.itemToEdit === "article") {
            const request = await articleRepository.postArticle(tags);
            this.SnackbarShow(
              "L'article a bien était ajouté ",
              request.status,
              200
            );
            this.getCurrentTag();
          }
          this.item.visible = true;
          this.$refs.form.resetValidation();
          this.modalAdd = false;
          this.item.name = "";
        }
        this.modalAdd = false;
      }
    },
  },
};
</script>
<style>
.tagPage {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalTagIcon {
  color: green !important;
  background: #1abc9c40;
  padding: 5px;
  border-radius: 50px;
}
.tagName {
  margin-bottom: 0px !important;
  font-size: 15px;
  font-weight: bold;
}
.tagsForm {
  display: flex;
  flex-direction: row;
}
.tagsInput {
  margin: 20px 50px 20px 50px;
  width: 50% !important;
}
.modaltags {
  text-align: center !important;
  height: 350px;
}
#color-picker {
  width: 50% !important;
  margin: 30px 0px 0px 0px !important;
}
.tagsCards {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 50px;
  align-items: center;
  justify-content: center;
}
.tagCard {
  padding: 30px;
  height: 50px !important;
  text-align: center;
  border-radius: 30px !important;
  display: flex;
  align-items: center;
  margin: 20px;
  justify-content: center;
}
.center {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deleteTag {
  font-size: 25px !important;
  color: white !important;
  margin-left: 10px;
}
.v-chip .v-chip__content {
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: space-between;
}

* {
  box-sizing: border-box;
  font-family: "Arial";
}
.wrapper-dropdown {
  position: relative;
  width: 90% !important;
  background: #fff;
  color: #2e2e2e;
  outline: none;
  cursor: pointer;
  border-bottom: solid 1px rgba(0, 0, 0, 0.87);
  text-align: left;
}
.wrapper-dropdown > span {
  width: 100%;
  display: block;
  padding: 5px;
}
.wrapper-dropdown > span > span {
  padding: 5px 13px;
  margin-right: 10px;
  border-radius: 50px;
}
.wrapper-dropdown > span:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: 16px;
  top: calc(50% + 4px);
  margin-top: -6px;
  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #2e2e2e transparent;
}

.wrapper-dropdown .dropdown {
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  font-weight: normal;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  border-top: 0;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%),
    0 0 0 0 rgb(0 0 0 / 12%);
}

.wrapper-dropdown .dropdown li {
  display: block;
  text-decoration: none;
  color: #2e2e2e;
  padding: 5px;
  cursor: pointer;
}
.theme--light.v-tabs-items {
  background: transparent !important;
}
.wrapper-dropdown .dropdown li > span {
  padding: 5px 13px;
  margin-right: 10px;
  border-radius: 50px;
}

.wrapper-dropdown .dropdown li:hover {
  background: #eee;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .tagsForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tagsInput {
    margin: 0px 0px 0px 0px;
    width: 80% !important;
  }
  #color-picker {
    margin-left: 45px !important;
    width: 80% !important;
  }
}
</style>
