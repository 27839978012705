<template>
  <div></div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    pageAccess: String,
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted() {
    if (this.user == "" || this.user == null) {
      this.$router.push("login");
    } else {
      switch (this.pageAccess) {
        case "praticien":
          if (this.user.status === "praticien") {
            this.$router.push("praticien");
          }
          if (this.user.status === "operateur") {
            this.$router.push("operateur");
          }
          if (this.user.status === "admin") {
            this.$router.push("accueil");
          }
          break;
        case "admin":
          if (this.user.status !== "admin") {
            this.$router.push("operateur");
          }
          break;
        case "operateur":
          if (this.user.status === "ecran") {
            this.$router.push("global");
          } /* else {
            if (this.user.status === "admin") {
              this.$router.push("accueil");
            }
          } */
          if (this.user.status === "praticien") {
            this.$router.push("praticien");
          }
          break;
        case "ecran":
          if (this.user.status === "operateur") {
            this.$router.push("operateur");
          }
          if (this.user.status === "praticien") {
            this.$router.push("praticien");
          }
          break;
      }
    }
  },
};
</script>
<style>
.changeRowColor {
  background-color: #eeeeee !important;
}
</style>
