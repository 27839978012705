import { API_BASE_URL, API_ROUTES } from "../api-constant.js";
import axios from "axios";
import store from "../store";
export default {
  postAwsFile(cas, name, content) {
    var FormData = require("form-data");

    var form = new FormData();

    form.append("file", content);
    const request = axios({
      method: "post",
      url:
        API_BASE_URL +
        API_ROUTES.AwsFile +
        store.state.labo +
        "/" +
        cas +
        "/" +
        name,
      data: form,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${store.state.token}`,
      },
    }).then(function (response) {
      //handle success
      return response;
    });
    return request;
  },
  async findAwsFiles(cas) {
    const request = await axios.get(
      API_BASE_URL + API_ROUTES.AwsFile + store.state.labo + "/" + cas,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },
  async findAwsUniqueFiles(cas, name) {
    const request = await axios.get(
      API_BASE_URL +
        API_ROUTES.AwsFile +
        store.state.labo +
        "/" +
        cas +
        "/" +
        name,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },
  async deleteAwsFile(cas, name) {
    const request = await axios.delete(
      API_BASE_URL +
        API_ROUTES.AwsFile +
        store.state.labo +
        "/" +
        cas +
        "/" +
        name,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },
  async deleteAwsFolder(cas) {
    const request = await axios.delete(
      API_BASE_URL + API_ROUTES.AwsFolder + store.state.labo + "/" + cas,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },
};
