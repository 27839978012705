const env = "prod";
const config = {
  prod: {
    api_url: "https://back.cockpit.dentalinc.fr",
  },
  pp: {
    api_url: "https://back.preprod.cockpit.dentalinc.fr",
  },
  dev: {
    api_url: "http://localhost:3000",
  },
};

function getConfig(env) {
  return config[env];
}

export const API_BASE_URL = getConfig(env).api_url;
export const API_ROUTES = {
  projects: "/projects/",
  getProjectsByLaboId: "/projects/laboId?laboId=",
  operators: "/operators/",
  getOperatorsByLaboId: "/operators/laboId?laboId=",
  labo: "/laboratories/",
  admins: "/admins/",
  getAdminsByLaboId: "/admins/laboId?laboId=",
  users: "/users/",
  getUsersByLaboId: "/users/laboId?laboId=",
  getUserByEmail: "/users/email?email=",
  getOperatorByemail: "/operators/email?email=",
  getAdminsByemail: "/admins/email?email=",
  tags: "/tags/",
  materials: "/materials/",
  articles: "/articles/",
  getArticlesByLaboId: "/articles/laboId?laboId=",
  getMarerialsByLaboId: "/materials/laboId?laboId=",
  getTagsByLaboId: "/tags/laboId?laboId=",
  praticien: "/praticiens/",
  login: "/user/login",
  signup: "/signup",
  checkEmailExist: "/user/email?email=",
  resetPassword: "/reset-password/init",
  resetPasswordFinish: "/reset-password/finish",
  getPraticiensByLaboId: "/praticiens/laboId?laboId=",
  getPraticienByemail: "/praticiens/email?email=",
  getLaboratoryById: "/laboratories/",
  AwsFile: "/aws/file/",
  AwsFolder: "/aws/folder/",
};
