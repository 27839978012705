<template>
  <v-app-bar
    app
    class="app-bar-global"
    color="white"
    flat
    elevation="1"
    max-height="60px"
  >
    <router-link :to="{ name: 'Accueil' }" class="router">
      <img class="logo" alt="Logo" src="../assets/logo.png" />
    </router-link>
    <v-spacer />
    <div class="disconnect">
      <v-btn class="ma-10" outlined color="#2b3f53" @click="scroll">
        Activer le défilement
      </v-btn>
      <v-btn outlined color="#2b3f53" @click="logout"> Deconnexion </v-btn>
    </div>
    <div class="mobileNav">
      <v-app-bar-nav-icon color="black" @click="drawer = true" />
      <v-navigation-drawer v-model="drawer" absolute temporary height="1000px">
        <v-list nav dense>
          <v-list-item class="closeIcon" @click="closeMenu()">
            <v-icon>mdi-close</v-icon>
          </v-list-item>
          <div class="startMenu" />
          <v-list-item-group
            v-model="group"
            active-class="blue--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-title @click="scroll">
                Activer le défilement
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title @click="logout">
                Deconnexion
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <router-link :to="{ name: 'Accueil' }" class="router">
        <img class="logo1" alt="Logo" src="../assets/logo.png" />
      </router-link>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      group: null,
    };
  },
  methods: {
    closeMenu() {
      this.drawer = false;
    },
    logout() {
      this.$store.commit("SET_USER_INFO", "");
      this.$store.commit("SET_USER_LABO", "");
      this.$store.commit("SET_USER_TOKEN", "");
      this.$router.push("login");
    },
    scroll() {
      require("../../script.js");
    },
  },
};
</script>

<style>
.v-application .headline {
  font-size: 1.5rem !important;
  font-weight: 400;
  margin-top: 0.5rem;
  letter-spacing: normal !important;
}
</style>

<style scoped>
.router {
  max-width: 250px;
  max-height: 100px;
  margin-top: -60px;
}
.app-bar-global {
  position: fixed;
}

.logo1 {
  width: 170px;
  position: relative;
  top: 29px;
}
.logo {
  width: 170px;
  position: relative;
  top: 29px;
}
.avatar {
  margin-right: 40px;
  height: 24px;
  width: 24px;
}
.sheet-list {
  background-color: #003b5b !important;
}
.logo1 {
  display: none;
}
.list-btn-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.list-button-active {
  width: 100%;
  border-left: solid;
}
.icon {
  width: 35px;
  height: 35px;
}

.sheet-list.v-sheet.theme--light {
  min-height: 1000px;
}
.row {
  flex-wrap: nowrap !important;
  height: 100%;
}
.mobileNav {
  width: 100%;
  align-items: center;
  display: flex;
  position: relative;
  z-index: 0;
  justify-content: space-between !important;
}
.mobileNav {
  display: none;
}

@media screen and (max-width: 900px) {
  .mobileNav {
    display: block;
  }
  .app-bar {
    display: none;
  }
  .menuBar {
    display: none;
  }
  .disconnect {
    display: none;
  }
  .logo {
    display: none;
  }
}
</style>
