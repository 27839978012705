<template>
  <div>
    <v-main class="grey lighten-5" heigth="120%" style="padding: 0px">
      <v-row v-if="show">
        <v-col class="menuBar">
          <v-sheet class="sheet-list" max-width="75px" height="120%">
            <v-list
              class="list-btn-nav"
              color="transparent"
              min-height="30vh"
              style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 90%;
                margin-top: 70px;
              "
            >
              <v-btn
                v-for="(route, index) in routes"
                :key="index"
                color="white"
                height="50px"
                active-class="list-button-active"
                text
                :to="{ name: route.name }"
              >
                <div v-if="route.icon">
                  <img :src="route.icon" alt="icon" class="icon" />
                </div>
                <div v-else>
                  {{ route.name }}
                </div>
              </v-btn>
            </v-list>
          </v-sheet>
        </v-col>
        <v-col>
          <router-view />
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      group: null,
      routes: this.$router.options.routes,
      show: true,
    };
  },
  mounted() {
    if (this.$store.state.user.status === "operateur") {
      this.show = false;
    } else {
      if (this.$store.state.user.status === "admin") {
        this.routes.forEach((route) => {
          if (route.name === "Operateur") {
            route.meta.visible = true;
          }
        });
      }
      this.routes = this.routes.filter((route) => route.meta.visible === true);
    }
  },
};
</script>

<style scoped>
.router {
  max-width: 250px;
  max-height: 100px;
  margin-top: -75px;
}
.app-bar-global {
  justify-content: center;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 170px;
  position: relative;
  top: 29px;
}
.avatar {
  margin-right: 40px;
  height: 24px;
  width: 24px;
}
.sheet-list {
  background-color: #003b5b !important;
  position: fixed;
}

.list-btn-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.list-button-active {
  width: 100%;
  border-left: solid;
}
.icon {
  width: 35px;
  height: 35px;
}

.sheet-list.v-sheet.theme--light {
  min-height: 1000px;
}
.row {
  flex-wrap: nowrap !important;
  height: 100%;
}
.mobileNav {
  width: 100%;
  align-items: center;
  display: flex;
  position: relative;
  z-index: 0;
  justify-content: space-between !important;
}
.mobileMenu {
  display: none;
}
.menuBar {
  padding-top: 0px;
  padding-bottom: 0;
}
@media screen and (max-width: 900px) {
  .mobileMenu {
    display: block;
  }
  .app-bar {
    display: none;
  }
  .menuBar {
    display: none;
  }
}
</style>
