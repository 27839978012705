<template>
  <div class="tagPage">
    <v-snackbar
      v-model="snackbar.visible"
      auto-height
      :color="snackbar.color"
      :multi-line="snackbar.mode === 'multi-line'"
      :timeout="snackbar.timeout"
      :top="snackbar.position === 'top'"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>
          {{ snackbar.icon }}
        </v-icon>
        <v-layout column>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
    <div class="tagsCards">
      <div v-for="(tag, index) in this.tags.tag" :key="tag.color">
        <v-chip
          v-if="index > 2 && tag.visible == true"
          class="tagCard"
          :color="tag.color"
        >
          <span :class="tag.colorText + ' tagName'" :color="tag.colorText">{{
            tag.name + " "
          }}</span>
          <v-icon
            small
            :class="tag.colorText + ' mr-2 deleteTag'"
            @click="openModal(tag, index)"
          >
            mdi-pencil
          </v-icon>
        </v-chip>
        <v-chip
          v-else-if="tag.visible == false"
          class="tagCard"
          color="grey lighten-4"
        >
          <span class="tagName grey--text">{{ tag.name + " " }}</span>
          <v-icon
            small
            class="grey--text mr-2 deleteTag"
            @click="openModal(tag, index)"
          >
            mdi-pencil
          </v-icon>
        </v-chip>
        <v-chip v-else class="tagCard" :color="tag.color">
          <p :class="tag.colorText + ' tagName'">
            {{ tag.name + " " }}
          </p>
        </v-chip>
      </div>
    </div>
    <v-btn
      color="#003b5b"
      outlined
      dark
      class="mb-2 addCasBtn"
      @click="openModal"
    >
      Ajouter une compétence
      <v-icon class="ml-3"> mdi-plus-circle-outline </v-icon>
    </v-btn>
    <template />
    <v-dialog v-model="modalAdd" max-width="1200px">
      <v-card class="modaltags">
        <v-card-title class="modalTitle">
          <span class="headline">
            {{ modalTitle }}
            <v-icon class="modalTagIcon" medium> {{ icon }} </v-icon>
          </span>
          <v-icon large @click="closeModal"> mdi-close </v-icon>
        </v-card-title>
        <v-form ref="form" lazy-validation>
          <div class="tagsForm">
            <v-text-field
              v-model="item.name"
              class="tagsInput"
              :rules="[rules.required]"
              label="Nom du tag"
            >
              <v-icon slot="prepend" color="rgb(243, 156, 18)">
                mdi-tag-outline
              </v-icon>
            </v-text-field>
            <div id="color-picker" class="tagsInput">
              <div class="wrapper-dropdown">
                <span @click="toggleDropdown()" v-html="selector" />
                <ul v-show="active" class="dropdown">
                  <li
                    v-for="color in this.colors"
                    :key="color.name"
                    @click="setColor(color.hex, color.name, color.color)"
                  >
                    <span :style="{ background: color.hex }" />
                    {{ color.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="center">
            <v-checkbox
              v-model="item.visible"
              label=" Afficher la compétence "
            />
          </div>
        </v-form>
        <v-btn
          v-if="!this.editModal"
          class="greenBtn"
          color="green darken-1"
          text
          @click="postTag"
        >
          Sauvegarder
        </v-btn>
        <v-btn
          v-if="this.editModal"
          class="deleteBtn"
          color="red darken-1"
          text
          @click="deleteTag()"
        >
          Supprimer la compétence
        </v-btn>
        <v-btn
          v-if="this.editModal"
          class="greenBtn"
          color="green darken-1"
          text
          @click="editTag()"
        >
          Sauvegarder
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import tagRepository from "../../repository/tagsRepository.js";

export default {
  data() {
    return {
      colors: [
        {
          hex: "#D0E5F7",
          color: "indigo--text",
          name: "Bleu",
        },
        {
          hex: "#E74C3C39",
          color: "red--text",
          name: "Rouge",
        },
        {
          hex: "#1ABC9C40",
          color: "green--text",
          name: "Vert",
        },
        {
          hex: "#F39C1240",
          color: "orange--text",
          name: "Jaune",
        },
        {
          hex: "#ffe9da",
          color: "deep-orange--text",
          name: "Orange",
        },
        {
          hex: "#f0dbff",
          color: "deep-purple--text",
          name: "Violet",
        },
        {
          hex: "#ffd1de",
          color: "pink--text",
          name: "Rose",
        },
        {
          hex: "#dfe6e9",
          color: "blue-grey--text",
          name: "Gris",
        },
      ],
      active: false,
      selectedColor: "",
      selectedColorName: "",
      modalAdd: false,
      tags: null,
      editModal: false,
      currentTag: [],
      item: {
        name: "",
        color: "",
        visible: true,
      },
      tagId: null,
      color: [
        "#1f6ed5 ",
        "#f44336",
        "#2980b9",
        "rgb(39, 174, 96)",
        "#f1c40f",
        "rgb(243, 156, 18)",
        "rgb(142, 68, 173)",
      ],
      rules: {
        required: (val) => val.length > 0 || "Ce champ est requis",
      },
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 7500,
        visible: false,
      },
      timeout: 7500,
    };
  },
  computed: {
    selector: function () {
      if (!this.selectedColor) {
        return "Couleur";
      } else {
        return (
          '<span style="background: ' +
          this.selectedColor +
          '"></span> ' +
          this.selectedColorName
        );
      }
    },
    modalTitle() {
      if (this.editModal === true) {
        return "Modifier la compétence";
      } else {
        return "Ajouter une compétence  ";
      }
    },
    icon() {
      if (this.editModal === true) {
        return "mdi-pencil-outline";
      } else {
        return "mdi-tag-outline";
      }
    },
  },
  async mounted() {
    this.getCurrentTag();
  },
  methods: {
    SnackbarShow(text, status, isOk) {
      if (status === isOk) {
        this.snackbar = {
          color: "success",
          icon: "mdi-check-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: text,
          visible: true,
        };
      } else {
        this.snackbar = {
          color: "error",
          icon: "mdi-alert-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: "Une erreur est survenue",
          visible: true,
        };
      }
    },
    setColor: function (color, colorName, colortext) {
      this.selectedColor = color;
      this.selectedColorName = colorName;
      this.item.color = color;
      this.item.colorText = colortext;
      this.active = false;
    },
    toggleDropdown: function () {
      this.active = !this.active;
    },
    async getCurrentTag() {
      const currentTag = await tagRepository.getTags();
      if (currentTag.data.length > 0) {
        this.tags = currentTag.data[0];
      } else {
        this.tags = [];
      }
      return this.tags;
    },
    openModal(tag, index) {
      if (tag.color !== undefined) {
        this.editModal = true;
        this.item.name = tag.name;
        this.item.visible = tag.visible;
        this.item.index = index;
        this.item.color = tag.color;
        this.item.colorText = tag.colorText;
      }
      this.modalAdd = true;
    },
    closeModal() {
      this.modalAdd = false;
      this.editModal = false;
      this.item.name = "";
      this.item.color = "";
      this.item.colorText = "";
    },
    async deleteTag() {
      const index = this.item.index;
      this.tags.tag.splice(index, 1);
      const tagsArray = {
        id: this.tags.id,
        tag: this.tags.tag,
      };
      const request = await tagRepository.putTag(tagsArray);
      this.modalAdd = false;
      this.SnackbarShow("Le tag a été supprimé", request.status, 204);
    },
    async editTag() {
      this.tags.tag[this.item.index] = {
        name: this.item.name,
        color: this.item.color,
        colorText: this.item.colorText,
        visible: this.item.visible,
      };
      const tagsArray = {
        id: this.tags.id,
        tag: this.tags.tag,
      };
      this.item.visible = true;
      const request = await tagRepository.putTag(tagsArray);
      this.modalAdd = false;
      this.item.name = "";
      this.item.color = "";
      this.item.colorText = "";
      this.SnackbarShow("Le tag a bien était modifié ", request.status, 204);
    },
    async postTag() {
      if (this.$refs.form.validate()) {
        if (this.tags.id !== undefined) {
          if (this.item.color == "") {
            this.item.color == "#1f6ed5";
          }
          this.tags.tag.push({
            name: this.item.name,
            color: this.item.color,
            colorText: this.item.colorText,
            visible: this.item.visible,
          });
          const tagsArray = {
            id: this.tags.id,
            tag: this.tags.tag,
          };
          const request = await tagRepository.putTag(tagsArray);
          this.SnackbarShow("Le tag a bien était ajouté ", request.status, 204);

          this.item.visible = true;
          this.modalAdd = false;
          this.$refs.form.resetValidation();
          this.item.name = "";
          this.item.color = "";
          this.item.colorText = "";
          this.getCurrentTag();
        } else {
          const tags = [];
          if (this.item.color == "") {
            this.item.color == "#1f6ed5";
          }
          tags.push({
            name: this.item.name,
            color: this.item.color,
            colorText: this.item.colorText,
            visible: this.item.visible,
          });
          const request = await tagRepository.postTag(tags);
          this.SnackbarShow("Le tag a bien était ajouté ", request.status, 200);
          this.modalAdd = false;
          this.item.name = "";
          this.item.color = "";
          this.item.colorText = "";
          this.item.visible = true;
          this.$refs.form.resetValidation();
          this.getCurrentTag();
        }
        this.modalAdd = false;
      }
    },
  },
};
</script>
<style>
.tagPage {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalTagIcon {
  color: green !important;
  background: #1abc9c40;
  padding: 5px;
  border-radius: 50px;
}
.tagName {
  margin-bottom: 0px !important;
  font-size: 15px;
  font-weight: bold;
}
.tagsForm {
  display: flex;
  flex-direction: row;
}
.tagsInput {
  margin: 20px 50px 20px 50px;
  width: 50% !important;
}
.modaltags {
  text-align: center !important;
  height: 500px;
}
#color-picker {
  width: 50% !important;
  margin: 30px 0px 0px 0px !important;
}
.tagsCards {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 50px;
  align-items: center;
  justify-content: center;
}
.tagCard {
  padding: 30px;
  height: 50px !important;
  text-align: center;
  border-radius: 30px !important;
  display: flex;
  align-items: center;
  margin: 20px;
  justify-content: center;
}
.center {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deleteTag {
  font-size: 25px !important;
  color: white !important;
  margin-left: 10px;
}
.v-chip .v-chip__content {
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: space-between;
}

* {
  box-sizing: border-box;
  font-family: "Arial";
}
.wrapper-dropdown {
  position: relative;
  width: 90% !important;
  background: #fff;
  color: #2e2e2e;
  outline: none;
  cursor: pointer;
  border-bottom: solid 1px rgba(0, 0, 0, 0.87);
  text-align: left;
}
.wrapper-dropdown > span {
  width: 100%;
  display: block;
  padding: 5px;
}
.wrapper-dropdown > span > span {
  padding: 5px 13px;
  margin-right: 10px;
  border-radius: 50px;
}
.wrapper-dropdown > span:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: 16px;
  top: calc(50% + 4px);
  margin-top: -6px;
  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #2e2e2e transparent;
}

.wrapper-dropdown .dropdown {
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  font-weight: normal;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  border-top: 0;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%),
    0 0 0 0 rgb(0 0 0 / 12%);
}

.wrapper-dropdown .dropdown li {
  display: block;
  text-decoration: none;
  color: #2e2e2e;
  padding: 5px;
  cursor: pointer;
}

.wrapper-dropdown .dropdown li > span {
  padding: 5px 13px;
  margin-right: 10px;
  border-radius: 50px;
}

.wrapper-dropdown .dropdown li:hover {
  background: #eee;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .tagsForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tagsInput {
    margin: 0px 0px 0px 0px;
    width: 80% !important;
  }
  #color-picker {
    margin-left: 45px !important;
    width: 80% !important;
  }
}
</style>
