<template>
  <div>
    <mainLayout />
    <div class="mainContent">
      <leftMenu class="leftMenu" />
      <v-col
        class="homeContent"
        style="
          padding-top: 100px;
          display: flex;
          margin-left: 1%;
          width: calc(100% - 5%);
          flex-wrap: wrap;
          justify-content: flex-start;
        "
      >
        <div class="title-archived">
          <h3>Statistiques</h3>
          <p style="margin-left: 1rem; margin-right: 1rem;">
            Sur les CAS terminés et en cours.
          </p>
          <v-divider />
        </div>
        <div
          style="
            display: flex;
            flex-flow: column wrap;
            place-content: center;
            align-items: stretch;
            width: 99%;
            justify-content: center;
            align-content: stretch;
          "
        >
          <v-row
            style="margin-bottom: 1rem;"
            no-gutters
          >
            <v-col>
              <v-card
                class="mr-3"
                outlined
              >
                <template>
                  <v-card
                    class="mx-auto align-center"
                    height="150px"
                    color="#F6D7A7"
                    light
                    style="
                      align-content: center;
                      justify-content: center;
                      align-items: center;
                      text-align: center;
                    "
                    max-width="600"
                  >
                    <v-card-title
                      style="
                        align-content: center;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                      "
                    >
                      <span
                        style="text-align: center;"
                        class="text-h6 font-weight-light"
                      >CAS Ouverts</span>
                    </v-card-title>

                    <v-card-text class="text-h3 font-weight-bold">
                      {{ openCas }}
                    </v-card-text>
                  </v-card>
                </template>
              </v-card>
            </v-col>
            <v-col>
              <v-card
                class="mr-3"
                outlined
              >
                <template>
                  <v-card
                    class="mx-auto align-center"
                    color="#F6EABE"
                    light
                    style="
                      align-content: center;
                      justify-content: center;
                      align-items: center;
                      text-align: center;
                    "
                    height="150px"
                    max-width="600"
                  >
                    <v-card-title
                      style="
                        align-content: center;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                      "
                    >
                      <span class="text-h6 font-weight-light">CAS traités</span>
                      <span
                        class="font-weight-light"
                        style="
                          font-size: 0.75rem;
                          margin-left: 0.33rem;
                          margin-top: 0.33rem;
                        "
                      >cette semaine</span>
                    </v-card-title>

                    <v-card-text class="text-h3 font-weight-bold">
                      {{ processedThisWeek }}
                    </v-card-text>
                  </v-card>
                </template>
              </v-card>
            </v-col>
            <v-col>
              <v-card
                class="mr-3"
                outlined
              >
                <template>
                  <v-card
                    class="mx-auto align-center"
                    color="#C8E3D4"
                    light
                    style="
                      align-content: center;
                      justify-content: center;
                      align-items: center;
                      text-align: center;
                    "
                    height="150px"
                    max-width="600"
                  >
                    <v-card-title
                      style="
                        align-content: center;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                      "
                    >
                      <span class="text-h6 font-weight-light">CAS traités</span>
                      <span
                        class="font-weight-light"
                        style="
                          font-size: 0.75rem;
                          margin-left: 0.33rem;
                          margin-top: 0.33rem;
                        "
                      >ce mois</span>
                    </v-card-title>
                    <v-card-text class="text-h3 font-weight-bold">
                      {{ processedThisMonth }}
                    </v-card-text>
                  </v-card>
                </template>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined>
                <template>
                  <v-card
                    class="mx-auto align-center"
                    color="#87AAAA"
                    light
                    style="
                      align-content: center;
                      justify-content: center;
                      align-items: center;
                      text-align: center;
                    "
                    height="150px"
                    max-width="600"
                  >
                    <v-card-title
                      style="
                        align-content: center;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                      "
                    >
                      <span
                        class="text-h6 font-weight-light"
                      >Moyenne traitement</span>
                      <span
                        class="font-weight-light"
                        style="
                          font-size: 0.75rem;
                          margin-left: 0.33rem;
                          margin-top: 0.33rem;
                        "
                      >CAS</span>
                    </v-card-title>

                    <v-card-text
                      style="
                        align-content: center;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                      "
                      class="text-h3 font-weight-bold align-center"
                    >
                      {{ average ? average.toFixed(1) : 0 }}
                    </v-card-text>
                  </v-card>
                </template>
              </v-card>
            </v-col>
          </v-row>

          <v-row
            class=""
            dense
            style="margin-bottom: 1rem;"
          >
            <v-col cols="12">
              <div
                style="
                  padding: 1rem;
                  background: rgba(199, 199, 199, 0.26);
                  border-radius: 10px;
                  display: flex;
                  flex-direction: row;
                  align-content: center;
                  justify-content: flex-start;
                  align-items: stretch;
                  gap: 1rem;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    min-width: 20%;
                  "
                >
                  <span
                    class="font-weight-light"
                    style="font-size: 0.9rem;"
                  >
                    Selectionner une période
                  </span>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    style="display: inline-flex;min-width: 100%;"
                    :close-on-content-click="false"
                    :return-value.sync="selectedDateGraph"
                    transition="scale-transition"
                    offset-y
                    min-width="100%"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="handleFormatRange(selectedDateGraph)"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        style="min-width:100%;"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="selectedDateGraph"
                      no-title
                      range
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Annuler
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(selectedDateGraph)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 80%;
                    max-height: 130px;
                    min-height: 130px;
                  "
                >
                  <span
                    class="text-h8 font-weight-light"
                    style="font-size: 0.9rem;"
                  >
                    Selectionner des opérateurs
                  </span>
                  <v-select
                    v-model="selectedOperatorsGraph"
                    :items="opePickerTri"
                    multiple
                    chips
                    style="max-width: 100%; width: 100$;"
                    :menu-props="{ maxWidth: '100%' }"
                  />
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: flex-end;
                    gap: 1rem;
                  "
                >
                  <v-btn
                    color="#013b5b"
                    dark
                    depressed
                    @click="handleClick(tasks)"
                  >
                    Valider
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row
            class="mb-12"
            dense
          >
            <v-col cols="auto">
              <v-card
                v-if="chartData"
                class="card-graph"
                style="
                  display: flex;
                  flex-direction: column;
                  align-content: center;
                  justify-content: center;
                  align-items: center;
                  min-height: 600px;
                  min-width: 30vw;
                  padding-right: 2rem;
                "
                color="#ffffff"
              >
                <v-card-title
                  class="text-h5"
                  style="align-self: flex-start; padding-bottom: 1rem;"
                >
                  Performance par opérateur
                </v-card-title>
                <v-card-subtitle
                  style="align-self: flex-start; margin-top: -1rem;"
                >
                  <span class="text-h9 font-weight-light">
                    En temps total de traitement de taches
                  </span>
                </v-card-subtitle>
                <v-row>
                  <v-col
                    cols="12"
                    style="min-width: 290px; min-height: 450px;"
                  >
                    <div
                      v-if="!chartDataOperatorDate"
                      style="font-size: small; width: 270px; font-weight: bold;"
                    >
                      Veuillez selectioner une période et des opérateurs pour
                      visualiser ce graphique.
                    </div>
                    <PieChart
                      v-if="chartDataOperatorDate"
                      :data="chartDataOperatorDate"
                      :options="chartOptions"
                      :height="300"
                      style="
                        width: 360px;
                        height: 300px;
                        margin: 1rem;
                        margin-bottom: 2rem;
                      "
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="auto">
              <v-card
                v-if="chartData"
                style="
                  display: flex;
                  flex-direction: column;
                  align-content: center;
                  justify-content: flex-start;
                  align-items: center;
                  min-height: 600px;
                  min-width: 30vw;
                "
                color="#ffffff"
              >
                <v-card-title
                  class="text-h5"
                  style="align-self: flex-start; justify-content: flex-start;"
                >
                  Répartition du nombre d'articles utilisés
                </v-card-title>
                <v-card-subtitle
                  style="align-self: flex-start; margin-top: -1rem;"
                >
                  <span class="text-h9 font-weight-light">
                    Sur les CAS traités
                  </span>
                </v-card-subtitle>
                <DoughnutChart
                  v-if="chartData"
                  :data="chartData"
                  :options="chartOptions"
                  :height="300"
                  style="
                    width: 400px;
                    height: 400px;
                    margin: 1rem;
                    margin-bottom: 2rem;
                  "
                />
              </v-card>
            </v-col>
            <v-col cols="auto">
              <v-card
                style="
                  display: flex;
                  flex-direction: column;
                  align-content: center;
                  justify-content: flex-start;
                  align-items: center;
                  min-height: 600px;
                  min-width: 30vw;
                "
                color="#ffffff"
              >
                <v-card-title
                  class="text-h5"
                  style="align-self: flex-start; justify-content: flex-start;"
                >
                  Production semaine
                </v-card-title>
                <v-card-subtitle
                  style="align-self: flex-start; margin-top: -1rem;"
                >
                  <span class="text-h9 font-weight-light">
                    Sur les CAS traités
                  </span>
                </v-card-subtitle>

                <v-row>
                  <v-col
                    cols="12"
                    style="min-width: 290px; min-height: 450px;"
                  >
                    <div
                      v-if="!chartDataRangeDate"
                      style="font-size: small; width: 270px; font-weight: bold;"
                    >
                      Veuillez selectioner une période pour visualiser ce
                      graphique.
                    </div>
                    <BarChart
                      v-if="chartDataRangeDate"
                      :data="chartDataRangeDate"
                      :options="chartOptions"
                      :height="300"
                      style="
                        width: 400px;
                        height: 400px;
                        margin: 1rem;
                        margin-bottom: 2rem;
                      "
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import mainLayout from "../layout/mainLayout.vue";
import leftMenu from "../layout/leftMenu.vue";
import project from "../../repository/projectRepository.js";
import tags from "../../repository/tagsRepository.js";
import users from "../../repository/userRepository.js";
import operator from "../../repository/operatorRepository.js";
import admin from "../../repository/adminRepository.js";
import moment from "moment";
import DoughnutChart from "../components/DoughnutChart.js";
import PieChart from "../components/PieChart.js";
import BarChart from "../components/BarChart.js";

export default {
  components: {
    mainLayout,
    leftMenu,
    DoughnutChart,
    PieChart,
    BarChart,
  },
  data() {
    return {
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      profile: {},
      average: null,
      processedThisMonth: null,
      processedThisWeek: null,
      selectedOperatorsGraph: null,
      selectedDateGraph: null,
      selectedDateGraph2: null,
      openCas: null,
      isLoading: false,
      modalProfile: false,
      chartOptions: {
        hoverBorderWidth: 20,
      },
      date: null,
      colors: [
        "#01579b",
        "#0d47a1",
        "#42a5f5",
        "#64b5f6",
        "#546e7a",
        "#37474f",
        "#263238",
        "#1565c0",
        "#0d47a1",
        "#5c6bc0",
      ],
      chartData: null,
      chartDataOperatorDate: null,
      chartDataRangeDate: null,
      files: [],
      file: [],
      test: [],
      tags: [],
      tasks: [],
      visualizer: null,
      parsedYear: [],
      clickedTooth: null,
      baseTasks: {
        tasks: [],
      },
      allArticles: [],
      singleExpand: false,
      opePicker: [],
      opePickerTri: [],
      opePickerGraph: [],
      opeLabel: "Opérateur",
      columns: [
        {
          title: "Projets en cours",
          ref: "progress",
          icon: "mdi-backup-restore",
          drag: false,
          tasks: [],
        },
      ],
    };
  },
  computed: {
    formatDate() {
      return this.selectedDateGraph
        ? moment(this.selectedDateGraph).format("DD/MM/YYYY")
        : "";
    },
    formatDate2() {
      return this.selectedDateGraph2
        ? moment(this.selectedDateGraph2).format("DD/MM/YYYY")
        : "";
    },
  },

  created() {
    this.initialize();
  },
  async mounted() {
    this.getAllProjects();
    const tags = await this.getCurrentTag();
    this.tags = tags.data[0];
    const user = await users.getUser(
      this.$store.state.user.email,
      this.$store.state.token
    );
    const dateRangeInit = [];
    const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
    dateRangeInit.push(startOfMonth.toString());
    dateRangeInit.push(endOfMonth.toString());
    this.selectedDateGraph = dateRangeInit;
    this.profile = user.data[0];
    this.operators = await this.getAllOperators();
    this.operators = this.operators.data;
    this.operators.forEach((op) => {
      this.opePickerTri.push(op.firstName + " " + op.lastName);
      this.opePickerGraph.push({
        label: op.firstName + " " + op.lastName,
        value: op.id,
      });
    });
    this.opePickerTri = this.lodash.uniqBy(this.opePickerTri, function (e) {
      return e;
    });
    this.selectedOperatorsGraph = this.lodash.uniqBy(
      this.opePickerTri,
      function (e) {
        return e;
      }
    );

    setTimeout(() => {
      this.handleClick(this.tasks);
    }, 1000);
  },
  methods: {
    initialize() {
      this.tasks;
    },
    handleClick(tasks) {
      if (this.selectedDateGraph && this.selectedOperatorsGraph) {
        this.createPieChartDataOperators(tasks);
      }

      if (this.selectedDateGraph) {
        this.createBarChartData(tasks);
      }
    },
    handleFormatRange(date) {
      if (date) {
        if (date.length > 1) {
          const date1 = date[0];
          const date2 = date[1];
          //
          var dateUS1 = moment(date1, "YYYY-MM-DD");
          var dateUS2 = moment(date2, "YYYY-MM-DD");
          //
          return (
            dateUS1.format("DD/MM/YYYY") + " - " + dateUS2.format("DD/MM/YYYY")
          );
        } else {
          return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
        }
      } else {
        return "";
      }
    },
    createPieChartData(tasks) {
      //
      let counted = [];
      let labels = [];
      let values = [];
      tasks.forEach((task) => {
        if (task.teeths) {
          task.teeths.forEach((element) => {
            const count = task.teeths.filter((obj) => obj.code === element.code)
              .length;
            counted.push({ name: element.code, value: count });
          });
        }
      });
      const data = Array.from(
        counted.reduce(
          (m, { name, value }) => m.set(name, (m.get(name) || 0) + value),
          new Map()
        ),
        ([name, value]) => ({ name, value })
      );

      //
      data.forEach((x) => {
        labels.push(x.name);
        values.push(x.value);
      });
      //
      const chartData = {
        labels: labels,
        datasets: [
          {
            backgroundColor: this.colors,
            data: values,
          },
        ],
      };
      this.chartData = chartData;
      this.$forceUpdate();
    },
    createPieChartDataOperators(tasks) {
      //
      // let counted = [];
      let labels = [];
      let values = [];
      let workingArray = [];
      let workingParsed = [];
      let operatorsSelected = this.selectedOperatorsGraph;
      tasks.forEach((x) => {
        if (x.endDate) {
          const [day, month, year] = x.startDate.split("/");
          const parsedEndDate = new Date(+year, month - 1, +day);
          //
          const [year1, month1, day1] = this.selectedDateGraph[0].split("-");
          const startStr = new Date(+year1, month1 - 1, +day1);
          //
          const [year2, month2, day2] = this.selectedDateGraph[1].split("-");
          const endStr = new Date(+year2, month2 - 1, +day2);
          //
          if (x.tasks && parsedEndDate > startStr && parsedEndDate < endStr) {
            x.tasks.forEach((y) => {
              if (y.tasks && y.tasks.tasks) {
                y.tasks.tasks.forEach((z) => {
                  if (z.operator) {
                    workingArray.push({ name: z.operator, duration: z.value, cas: x.cas });
                  }
                });
              }
            });
          }
        }
      });
      //
      operatorsSelected.forEach((operator) => {
        const search = workingArray.filter((x) => x.name === operator);
        if (search.length > 0) {
          const sum = search.reduce((accumulator, object) => {
            return accumulator + parseInt(object.duration);
          }, 0);
          workingParsed.push({ name: operator, value: sum});
        }
      });
      //
      const data = Array.from(
        workingParsed.reduce(
          (m, { name, value }) => m.set(name, (m.get(name) || 0) + value),
          new Map()
        ),
        ([name, value]) => ({ name, value })
      );
      //
      data.forEach((x) => {
        labels.push(x.name);
        values.push(x.value);
      });
      //
      const chartData = {
        labels: labels,
        datasets: [
          {
            backgroundColor: this.colors,
            data: values,
          },
        ],
      };
      this.chartDataOperatorDate = chartData;
      this.$forceUpdate();
    },
    createBarChartData(tasks) {
      // let counted = [];
      let labels = [];
      let values = [];
      let workingArray = [];
      let workingParsed = [];
      // let operatorsSelected = this.selectedOperatorsGraph;
      tasks.forEach((x) => {
        if (x.startDate) {
          const [day, month, year] = x.startDate.split("/");
          const parsedEndDate = new Date(+year, month - 1, +day);
          //
          const [year1, month1, day1] = this.selectedDateGraph[0].split("-");
          const startStr = new Date(+year1, month1 - 1, +day1);
          //
          const [year2, month2, day2] = this.selectedDateGraph[1].split("-");
          const endStr = new Date(+year2, month2 - 1, +day2);
          //
          if (x.tasks && parsedEndDate > startStr && parsedEndDate < endStr) {
            x.tasks.forEach((y) => {
              if (y.tasks && y.tasks.tasks) {
                y.tasks.tasks.forEach((z) => {
                  if (z.operator) {
                    //
                    let zParsed;
                    if (z.maxDate) {
                      if (z.maxDate.toString().includes("/")) {
                        const [day3, month3, year3] = z.maxDate.split("/");
                        zParsed = new Date(+year3, month3 - 1, +day3);
                      } else {
                        const [year3, month3, day3] = z.maxDate.split("-");
                        zParsed = new Date(+year3, month3 - 1, +day3);
                      }
                    }
                    //
                    workingArray.push({
                      name: zParsed
                        ? zParsed.toLocaleDateString("en-GB")
                        : "Null",
                      duration: z.value,
                    });
                  }
                });
              }
            });
          }
        }
      });
      //
      workingArray.forEach((x) => {
        workingParsed.push({ name: x.name, value: parseInt(x.duration) });
      });
      //
      const data = Array.from(
        workingParsed.reduce(
          (m, { name, value }) => m.set(name, (m.get(name) || 0) + value),
          new Map()
        ),
        ([name, value]) => ({ name, value })
      );
      //
      data.forEach((x) => {
        labels.push(x.name);
        values.push(x.value);
      });
      //
      const chartData = {
        labels: labels,
        datasets: [
          {
            label: "Temps de production total des taches réalisés par jour",
            backgroundColor: [
              "#01579b",
              "#01579b",
              "#01579b",
              "#01579b",
              "#01579b",
              "#01579b",
              "#01579b",
              "#01579b",
              "#01579b",
              "#01579b",
              "#01579b",
              "#01579b",
              "#01579b",
            ],
            data: values,
          },
        ],
      };
      this.chartDataRangeDate = chartData;
      this.$forceUpdate();
    },
    calculateAvg(tasks) {
      let sum = 0;
      tasks.forEach((task) => {
        const start = moment(task.startDate, "DD-MM-YYYY");
        const end = moment(task.endDate, "DD-MM-YYYY");
        const dif = end.diff(start, "days");

        if (dif > 0) {
          sum += dif;
        }
      });
      return sum / tasks.length;
    },
    calculateProcessedDuringAmonth(tasks) {
      let sum = 0;
      tasks.forEach((task) => {
        const momentParsed = moment(task.endDate, "DD-MM-YYYY");
        const currentMonth = moment();
        const isInMonth =
          currentMonth.format("MM") === momentParsed.format("MM");
        if (isInMonth) {
          sum += 1;
        }
      });
      return sum;
    },
    calculateProcessedDuringAweek(tasks) {
      let sum = 0;
      tasks.forEach((task) => {
        const momentParsed = moment(task.endDate, "DD-MM-YYYY");
        const currentMonth = moment();
        const isInWeek = currentMonth.week() === momentParsed.week();
        if (isInWeek) {
          sum += 1;
        }
      });
      return sum;
    },
    calculateOpenTasks(tasks) {
      let sum = 0;
      tasks.forEach((task) => {
        if (!task.isArchived) {
          sum += 1;
        }
      });
      return sum;
    },
    visualizeFromChild: function (k) {
      this.visualizer = k;
    },
    returnCorrespondingArticle: function (iterator) {
      if (iterator.tasks) {
        const findCorrespondingArticle = iterator.tasks[0];
        //
        return findCorrespondingArticle;
      } else {
        return null;
      }
    },
    returnClickedTooth: function (iterator) {
      if (iterator.tasks) {
        return iterator.tasks[0].teethNbr;
      } else {
        return null;
      }
    },
    getName(firstName, lastName) {
      if (firstName.substr(0, 2) == "Dr") {
        const first = firstName.substr(3, 1);
        const second = lastName.substr(0, 1);
        return first.toUpperCase() + second.toUpperCase();
      } else {
        const first = firstName.substr(0, 1);
        const second = lastName.substr(0, 1);
        return first.toUpperCase() + second.toUpperCase();
      }
    },
    openModal() {
      this.modalProfile = true;
    },
    async closeModal() {
      this.modalProfile = false;
      this.file = [];
      const user = await users.getUser(
        this.$store.state.user.email,
        this.$store.state.token
      );
      this.profile = user.data[0];
    },
    async savePicture() {
      //this.files = this.fileToBase64(this.file)
      const base64 = await this.fileToBase64(this.file);
      Object.assign(this.profile, { file: base64 });
      this.isLoading = true;
      await users.putUser(this.profile);
      this.modalProfile = false;
      const user = await users.getUser(
        this.$store.state.user.email,
        this.$store.state.token
      );
      this.isLoading = false;
      this.profile = user.data[0];
    },
    fileToBase64(file) {
      return new Promise((resolve) => {
        let fileReader = new FileReader();
        let base64;
        fileReader.onload = (fileLoadedEvent) => {
          base64 = fileLoadedEvent.target.result;
          resolve(base64);
        };
        fileReader.readAsDataURL(file);
      });
    },
    async getCurrentTag() {
      return await tags.getTags();
    },
    clickToothTask: async function (nbr) {
      if (this.expanded && this.expanded[0].tasks) {
        const findCorrespondingArticle = this.expanded[0].tasks.find(
          (o) => o.teethNbr === nbr
        );
        //
        this.baseTasks = findCorrespondingArticle;
        this.clickedTooth = nbr;
      }
    },

    getTagColor(tagName) {
      if (tagName.length > 0) {
        const color = this.tags.tag.filter((tag) => tag.name == tagName);
        if (color.length > 0) return color[0].color;
      }
    },
    getTagTextColor(tagName) {
      if (tagName.length > 0) {
        const color = this.tags.tag.filter((tag) => tag.name == tagName);
        if (color.length > 0) return color[0].colorText;
      }
    },
    checkMove(e) {
      if (e.from.id === "progress" && e.to.id === "backlog") {
        return false;
      }
    },
    drop(event) {
      if (event.from.id === "progress" && event.to.id === "backlog") {
        this.tasks.forEach((task) => {
          if (task.cas === event.item.attributes.id.value) {
            task.operator = "";
            project.putProject(task);
          }
        });
      }
      if (event.from.id === "backlog" && event.to.id === "progress") {
        this.tasks.forEach((task) => {
          if (task.cas === event.item.attributes.id.value) {
            task.operator =
              this.$store.state.user.firstName +
              " " +
              this.$store.state.user.lastName;
            project.putProject(task);
          }
        });
      }
    },
    async getAllOperators() {
      const adminOperateur = await admin.getAdmins();
      const operatorData = await operator.getOperators();
      adminOperateur.data.forEach((admin) => {
        operatorData.data.push(admin);
      });
      return operatorData;
    },
    async getAllProjects() {
      this.columns[0].tasks = [];
      this.projects = await project.getProjects();
      this.tasks = this.projects.data;
      //
      var sources = this.projects.data
        .filter(function (x) {
          if (!x.isArchived || x.isArchived === false) {
            return false; // skip
          }
          return true;
        })
        .map(function (x) {
          return x;
        });
      //
      this.tasks = sources;
      const avg = this.calculateAvg(sources);
      this.average = avg;
      const thisMonth = this.calculateProcessedDuringAmonth(sources);
      this.processedThisMonth = thisMonth;
      const thisWeek = this.calculateProcessedDuringAweek(sources);
      this.processedThisWeek = thisWeek;
      const stillOpen = this.calculateOpenTasks(this.projects.data);
      this.openCas = stillOpen;
      //
      this.createPieChartData(sources);
    },
  },
};
</script>

<style>
.align-center {
  align-content: center;
  justify-content: center;
  align-items: center;
}

.card-graph {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vizu {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-top: 1.33rem;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 50vh;
  width: 100%;
  height: fit-content;
}

.title-archived {
  display: flex;
  align-content: center;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-bottom: 1.33rem;
}

.archived-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0rem 2rem;
  padding-top: 1rem;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.homeContent {
  padding-top: 100px;
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.idCard {
  min-width: 15%;
  margin-left: 1rem;
}

.subheading-profile {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text {
  justify-content: center;
}
.mainContent {
  display: flex;
  flex-direction: row;
}
.leftMenu {
  width: 5%;
}
.rounded {
  border-radius: 15px !important;
}
.operateurContent {
  margin-top: 100px;
  margin-left: 0px;
  margin-right: 15px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 99%;
  flex-direction: row;
}
.operator-column {
  min-width: 33%;
  max-width: 70%;
}
.initial {
  color: white;
  font-size: 15px;
  margin-top: 15px;
}
.kanban {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.test {
  display: flex;
  flex-direction: row;
}
/* .avatar:hover {
  opacity: 0.7;
} */
.avatar {
  z-index: 1;
}
.label {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  bottom: -50px;
  color: #fff;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  text-align: center;
  transition: 0.1s all;
  z-index: 100;
}

.avatar:hover .label {
  bottom: -20px;
}
.inital:hover .label {
  bottom: -20px;
}
.zone {
  height: 800px;
}
.cardHome {
  display: flex;
  border-radius: 25px !important;
  width: 350px;
  max-height: 71px;
  width: 100%;
}
.dragcontent {
  display: flex;
  justify-content: center;
  min-height: 45%;
  overflow: scroll;
  max-height: 100%;
  height: 90%;
  overflow-x: hidden;
}
.card {
  width: 250px;
  height: 31%;
  margin: 10px;
}
.modalTitle {
  display: flex;
  justify-content: space-between;
}
.iconDuring {
  width: 51px;
  height: 47px;
  background: #3498db40 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin: 10px;
}
#backlog {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
#progress {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
.bg-white
  shadow
  .rounded-xl
  .px-3
  .pt-2
  .pb-2
  .border
  .border-white
  .card
  .mt-5
  .mb-2
  .cursor-move {
  height: 100% !important;
}
.iconAttente {
  width: 51px;
  height: 47px;
  background: #f39c1240 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin: 10px;
}
.idCardAdmin {
  min-width: 15%;
  max-width: 25%;
  margin-left: 8.33%;
}
@media screen and (min-width: 1500px) {
  .card {
    width: 300px;
  }
}
@media screen and (max-width: 900px) {
  .leftMenu {
    display: none;
  }
  .casTable {
    width: 100%;
  }
  .kanban {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .operateurContent {
    overflow-x: scroll;
    width: 100%;
    display: flex;
    height: 99%;
    flex-direction: column;
    margin-top: 80px !important;
    align-items: center;
    margin: 0px;
  }
  .dragcontent {
    display: flex;
    justify-content: center;
    overflow: scroll;
    max-height: 83%;
  }
  .zone {
    width: 300px;
    height: 550px;
    margin-top: 10px;
  }
  .card {
    width: 250px;
  }
  .idCardAdmin {
    min-width: 15%;
    max-width: 60%;
  }
}
@media screen and (max-width: 800px) {
  .operateurContent {
    overflow-x: scroll;
    width: 100%;
    display: flex;
    height: 99%;
    flex-direction: row;
    margin-top: 80px !important;
    margin: 0px;
    flex-direction: column;
    align-items: center;
  }
  .operator-column {
    width: 100% !important;
  }
  .card {
    height: 50%;
  }
}
</style>
