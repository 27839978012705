<template>
  <div class="tagPage">
    <v-snackbar
      v-model="snackbar.visible"
      auto-height
      :color="snackbar.color"
      :multi-line="snackbar.mode === 'multi-line'"
      :timeout="snackbar.timeout"
      :top="snackbar.position === 'top'"
    >
      <v-layout
        align-center
        pr-4
      >
        <v-icon
          class="pr-3"
          dark
          large
        >
          {{ snackbar.icon }}
        </v-icon>
        <v-layout column>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
    <div
      v-if="itemToEdit === 'material'"
      class="tagsCards"
    >
      <div
        v-for="(tag, index) in this.tags.materials"
        :key="tag.id"
      >
        <v-chip
          class="tagCard"
          color="#dfe6e9"
        >
          <span
            :class="'blue-grey--text' + ' tagName'"
            color="blue-grey--text"
          >{{ tag.name + " " }}</span>
          <v-icon
            small
            class="grey--text mr-2 deleteTag"
            @click="openModal(tag, index, 'edit')"
          >
            mdi-pencil
          </v-icon>
        </v-chip>
      </div>
    </div>
    <div
      style="
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 25px;
        justify-content: center;
      "
    >
      <v-btn
        color="#003b5b"
        outlined
        dark
        class="mb-2 addCasBtn"
        @click="openModal()"
      >
        {{ addButton }}
        <v-icon class="ml-3">
          mdi-plus-circle-outline
        </v-icon>
      </v-btn>
      <v-btn
        color="red"
        style="margin-left: 25px"
        outlined
        dark
        class="mb-2 addCasBtn"
        @click="deleteAllTag()"
      >
        Supprimer le reférienciel d'articles
        <v-icon class="ml-3">
          mdi-minus-circle-outline
        </v-icon>
      </v-btn>
    </div>

    <div
      v-if="itemToEdit === 'article'"
      class="tagsCards"
    >
      <div
        v-for="tag in this.tags.articles"
        :key="tag.id"
      >
        <v-chip
          class="tagCard"
          color="#D0E5F7"
        >
          <span
            :class="'indigo--text' + ' tagName'"
            color="indigo--text"
          >{{
            tag.name + " "
          }}</span>
        </v-chip>
      </div>
    </div>

    <template />
    <v-dialog
      v-model="modalAdd"
      max-width="1200px"
      height="95%"
    >
      <v-card class="modaltags">
        <v-card-title class="modalTitle">
          <span class="headline">
            {{ modalTitle }}
            <v-icon
              class="modalTagIcon"
              medium
            > {{ icon }} </v-icon>
          </span>
          <v-icon
            large
            @click="closeModal"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <div>
          <v-container class="lighten-5">
            <v-row>
              <v-col>
                <v-card
                  class="pa-2"
                  outlined
                  tile
                >
                  <div class="header-col1">
                    <h5>Selectioner votre fichier CSV où XLSX</h5>
                    <p>Choissisez ensuite les colones corréspondantes</p>
                    <p>
                      Selectionez le nombre maximum de tâches à réaliser
                      (default : 20)
                    </p>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      width: 50%;
                      margin-left: 25%;
                      padding-top: 5%;
                      align-items: center;
                      align-content: center;
                      justify-content: center;
                    "
                  >
                    <v-select
                      v-model="maxTask"
                      :items="taskPicker"
                      item-text="label"
                      item-value="value"
                      style="margin-top: -10px"
                      @change="onChange($event)"
                    />
                  </div>

                  <v-divider />
                  <div style="display: flex; width: 100%">
                    <vue-csv-import
                      :key="taskArray"
                      v-model="parseCsv"
                      :map-fields="taskArray"
                    >
                      <vue-csv-input
                        id="fileUpload"
                        v-model="file"
                        name="fileUpload"
                        class="input-file input-file--hidden"
                      />
                    </vue-csv-import>
                  </div>
                </v-card>
              </v-col>

              <v-col>
                <v-card
                  style="height: 100%"
                  class="pa-2"
                  outlined
                  tile
                >
                  <v-simple-table
                    v-if="parseCsv"
                    dense
                    fixed-header
                    height="70vh"
                  >
                    <thead>
                      <tr>
                        <template
                          v-for="(item2, index) in parseCsv2"
                          class="text-left"
                        >
                          <template v-if="index === 0">
                            <th
                              v-for="item3 in item2"
                              :key="item3.value"
                            >
                              {{ item3.label }}
                            </th>
                          </template>
                        </template>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-for="item2 in parseCsv2"
                        class="text-left"
                      >
                        <tr :key="item2">
                          <td
                            v-for="item3 in item2"
                            :key="item3.value"
                            class="text-left"
                          >
                            {{ item3.value ? item3.value : " - " }}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <v-btn
          v-if="!this.editModal"
          class="greenBtn"
          color="green darken-1"
          text
          @click="postImport"
        >
          Importer
        </v-btn>
        <v-btn
          v-if="this.editModal"
          class="deleteBtn"
          color="red darken-1"
          text
          @click="deleteTag()"
        >
          {{ deleteButton }}
        </v-btn>
        <v-btn
          v-if="this.editModal"
          class="greenBtn"
          color="green darken-1"
          text
          @click="editTag()"
        >
          Sauvegarder
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import materialRepository from "../../repository/materialRepository.js";
import articleRepository from "../../repository/articleRepository.js";
import VueCsvImport from "vue-csv-import";

export default {
  components: {
    VueCsvImport,
  },
  props: {
    itemToEdit: String,
  },
  data() {
    return {
      maxTask: 20,
      taskArray: ["Code", "Article"],
      taskPicker: [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
        { value: 7, label: "7" },
        { value: 8, label: "8" },
        { value: 9, label: "9" },
        { value: 10, label: "10" },
        { value: 11, label: "11" },
        { value: 12, label: "12" },
        { value: 13, label: "13" },
        { value: 14, label: "14" },
        { value: 15, label: "15" },
        { value: 16, label: "16" },
        { value: 17, label: "17" },
        { value: 18, label: "18" },
        { value: 19, label: "19" },
        { value: 20, label: "20" },
      ],
      file: null,
      parseCsv: [],
      parseCsv2: [],
      articlesToSave: [],
      active: false,
      modalAdd: false,
      tags: null,
      editModal: false,
      currentTag: [],
      item: {
        name: "",
        color: "",
        visible: true,
      },
      tagId: null,
      rules: {
        required: (val) => val.length > 0 || "Ce champ est requis",
      },
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 7500,
        visible: false,
      },
      timeout: 7500,
    };
  },
  computed: {
    selector: function () {
      if (!this.selectedColor) {
        return "Couleur";
      } else {
        return (
          '<span style="background: ' +
          this.selectedColor +
          '"></span> ' +
          this.selectedColorName
        );
      }
    },
    modalTitle() {
      if (this.editModal === true && this.itemToEdit === "material") {
        return "Modifier un matériau";
      } else if (this.editModal === true && this.itemToEdit === "article") {
        return "Modifier un article";
      } else if (this.editModal === false && this.itemToEdit === "article") {
        return "Importer des articles";
      } else {
        return "Ajouter un matériau";
      }
    },
    addButton() {
      if (this.itemToEdit === "material") {
        return "Ajouter un matériau";
      } else {
        return "Importer des articles";
      }
    },
    deleteButton() {
      if (this.itemToEdit === "material") {
        return " Supprimer le matériau";
      } else {
        return "Supprimer l'article";
      }
    },
    icon() {
      if (this.editModal === true) {
        return "mdi-pencil-outline";
      } else {
        return "mdi-tag-outline";
      }
    },
  },
  watch: {
    parseCsv: function (val) {
      //do something when the data changes.
      if (val) {
        let matchedLabels = [];
        let finalizedArray = [];
        val.forEach(function (element, i) {
          if (i === 0) {
            for (const [key, value] of Object.entries(element)) {
              if (key.includes("Tache")) {
                matchedLabels.push({
                  label: value,
                  id: key,
                });
              }
            }
          }
        });
        //
        val.forEach(function (element, i) {
          let tmpParent = [];
          if (i !== 0) {
            for (const [key, value] of Object.entries(element)) {
              if (element.Code && element.Code !== "") {
                if (key.includes("Tache")) {
                  const t = matchedLabels.find((element) => element.id === key);
                  tmpParent.push({
                    label: t.label,
                    value: value,
                    id: key,
                  });
                } else {
                  tmpParent.push({
                    label: key,
                    value: value,
                    id: key,
                  });
                }
              }
            }
          }
          if (tmpParent.length > 0) {
            finalizedArray.push(tmpParent);
          }
        });
        this.parseCsv2 = finalizedArray;
      }
    },
  },
  async mounted() {
    this.getCurrentTag();
  },
  methods: {
    onChange() {
      let taskTmp = ["Code", "Article"];
      for (let i = 0; i < this.maxTask; i++) {
        let labelHelper = i;
        labelHelper++;
        taskTmp.push("Tache " + labelHelper);
      }
      this.taskArray = taskTmp;
      this.$forceUpdate();
    },
    SnackbarShow(text, status, isOk) {
      if (status === isOk) {
        this.snackbar = {
          color: "success",
          icon: "mdi-check-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: text,
          visible: true,
        };
      } else {
        this.snackbar = {
          color: "error",
          icon: "mdi-alert-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: "Une erreur est survenue",
          visible: true,
        };
      }
    },
    toggleDropdown: function () {
      this.active = !this.active;
    },
    async getCurrentTag() {
      if (this.itemToEdit === "material") {
        const currentTag = await materialRepository.getMaterials();
        if (currentTag.data.length > 0) {
          this.tags = currentTag.data[0];
        } else {
          this.tags = { materials: [] };
        }
        return this.tags;
      } else if (this.itemToEdit === "article") {
        const currentTag = await articleRepository.getArticles();
        this.originalTags = currentTag.data;
        if (currentTag.data.length > 0) {
          if (currentTag.data.length >= 2) {
            const tmpArr = [];
            currentTag.data.forEach((element) => {
              element.articles.forEach((artcl) => {
                const editedArtcl = { ...artcl };
                editedArtcl.collectionId = element.id;
                tmpArr.push(editedArtcl);
              });
            });
            const final = {
              articles: tmpArr,
              id: currentTag.data[1].id,
              laboId: currentTag.data[1].laboId,
            };
            this.tags = final;
          } else {
            this.tags = currentTag.data[0];
          }
        } else {
          this.tags = { articles: [] };
        }
        return this.tags;
      }
    },
    openModal(tag, index, edit) {
      if (edit) {
        this.editModal = true;
        this.item.name = tag.name;
        this.item.code = tag.code;
        this.item.visible = tag.visible;
        this.item.index = index;
      }
      this.modalAdd = true;
    },
    closeModal() {
      this.modalAdd = false;
      this.editModal = false;
      this.item.name = "";
    },
    async deleteTag() {
      const index = this.item.index;
      if (this.itemToEdit === "article") {
        const findCollection = this.lodash.find(this.originalTags, {
          id: this.item.collectionId,
        });
        findCollection.articles.splice(index, 1);
        this.tags.articles.splice(index, 1);
        const articleArray = {
          id: findCollection.id,
          articles: findCollection.articles,
        };
        const request = await articleRepository.putArticle(articleArray);
        this.modalAdd = false;
        this.SnackbarShow("L'article a été supprimé", request.status, 204);
        this.getCurrentTag();
        this.editModal = false;
      }
    },
    async deleteAllTag() {
      if (this.itemToEdit === "article") {
        this.tags.articles = [];
        const articleArray = {
          id: this.tags.id,
          articles: [],
        };
        const request = await articleRepository.putArticle(articleArray);
        this.SnackbarShow(
          "Le reférienciel a été supprimé",
          request.status,
          204
        );
      }
    },
    async editTag() {
      if (this.itemToEdit === "material") {
        this.tags.materials[this.item.index] = {
          name: this.item.name,
          visible: this.item.visible,
        };
        const materialArray = {
          id: this.tags.id,
          materials: this.tags.materials,
        };
        this.item.visible = true;
        const request = await materialRepository.putMaterial(materialArray);
        this.SnackbarShow(
          "Le matériau a bien était modifié ",
          request.status,
          204
        );
        this.editModal = false;
        this.item.name = "";
      } else if (this.itemToEdit === "article") {
        this.tags.articles[this.item.index] = {
          name: this.item.name,
          visible: this.item.visible,
        };
        const articleArray = {
          id: this.tags.id,
          articles: this.tags.articles,
        };
        this.item.visible = true;
        const request = await articleRepository.putArticle(articleArray);
        this.SnackbarShow(
          "L'article a bien était modifié ",
          request.status,
          204
        );
        this.editModal = false;
        this.item.name = "";
      }
      this.modalAdd = false;
    },
    async postImport() {
      if (this.parseCsv2.length !== 0) {
        const _articles = [];
        //
        this.parseCsv2.forEach((element) => {
          const isExisting = this.lodash.findIndex(this.tags.articles, [
            "code",
            element.code,
          ]);
          if (isExisting === -1) {
            let tmpTasks = [];
            let tmpOriginalArticle = {
              code: null,
              name: null,
              visible: true,
              tasks: [],
            };
            element.forEach((x) => {
              if (x.id === "Code") {
                tmpOriginalArticle.code = x.value;
              } else if (x.id === "Article") {
                tmpOriginalArticle.name = x.value;
              } else {
                tmpTasks.push({
                  id: x.label,
                  value: x.value,
                });
              }
            });
            tmpOriginalArticle.tasks = tmpTasks;
            _articles.push(tmpOriginalArticle);
          }
        });

        const request = await articleRepository.postArticle(_articles);
        this.SnackbarShow(
          "Les articles on bien été ajoutés ",
          request.status,
          200
        );
        this.getCurrentTag();
      }
    },
    async postTag() {
      if (this.$refs.form.validate()) {
        if (this.tags.id !== undefined) {
          if (this.itemToEdit === "material") {
            this.tags.materials.push({
              name: this.item.name,
              visible: this.item.visible,
            });
            const tagsArray = {
              id: this.tags.id,
              materials: this.tags.materials,
            };
            const request = await materialRepository.putMaterial(tagsArray);
            this.SnackbarShow(
              "Le matériau a bien était ajouté ",
              request.status,
              204
            );
            this.getCurrentTag();
          } else if (this.itemToEdit === "article") {
            this.tags.articles.push({
              name: this.item.name,
              visible: this.item.visible,
            });
            const tagsArray = {
              id: this.tags.id,
              articles: this.tags.articles,
            };
            const request = await articleRepository.putArticle(tagsArray);
            this.SnackbarShow(
              "L'article a bien était ajouté ",
              request.status,
              204
            );
            this.getCurrentTag();
          }
          this.item.visible = true;
          this.$refs.form.resetValidation();
          this.modalAdd = false;
          this.item.name = "";
        } else {
          const tags = [];
          tags.push({
            name: this.item.name,
            visible: this.item.visible,
          });
          if (this.itemToEdit === "material") {
            const request = await materialRepository.postMaterial(tags);
            this.SnackbarShow(
              "Le matériau a bien était ajouté ",
              request.status,
              200
            );
            this.getCurrentTag();
          } else if (this.itemToEdit === "article") {
            const request = await articleRepository.postArticle(tags);
            this.SnackbarShow(
              "L'article a bien était ajouté ",
              request.status,
              200
            );
            this.getCurrentTag();
          }
          this.item.visible = true;
          this.$refs.form.resetValidation();
          this.modalAdd = false;
          this.item.name = "";
        }
        this.modalAdd = false;
      }
    },
  },
};
</script>
<style>
input[type="file"] {
  display: flex;
}

.theme--light.v-card {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 10px !important;
}

.header-col1 {
  display: flex;
  padding-top: 20px;
  padding-left: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.v-data-table > .v-data-table__wrapper > table {
  box-shadow: none !important;
}

.csv-import-checkbox {
  display: none !important;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.vue-csv-uploader-part-two {
  width: 100%;
  display: flex;
}

.form-check {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.form-check-label {
  padding-left: 20px;
}

.form-check-input:checked {
  background-color: #003b5b;
  border-color: #003b5b;
}

.btn-primary:disabled {
  color: #fff !important;
  background-color: #003b5b;
  border-color: #003b5b;
}

.btn-primary {
  color: #fff !important;
  background-color: #003b5b;
  border-color: #003b5b;
}

.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #003b5b;
  border-color: #003b5b;
}

.vue-csv-uploader {
  display: flex;
  width: 100%;
}

.vue-csv-mapping {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  width: 100%;
}
.form-group {
  display: flex;
}

.form-control-file {
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  margin-bottom: 25px;
  justify-content: inherit;
  line-height: normal;
  position: relative;
  transition: inherit;
  transition-property: opacity;
}

.form-control-file + label {
  margin-left: 50%;
  transform: translateX(-50%);
  font-size: 1.25em;
  background-color: white;
  width: 13ch;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
  display: inline-block;
}

.form-control-file:focus + label,
.form-control-file + label:hover {
  background-color: #84ceeb;
  cursor: pointer;
}
/* end input stlyes */

select {
  moz-appearance: block !important;
  webkit-appearance: block !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  height: fit-content;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

.tagPage {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalTagIcon {
  color: green !important;
  background: #1abc9c40;
  padding: 5px;
  border-radius: 50px;
}
.tagName {
  margin-bottom: 0px !important;
  font-size: 15px;
  font-weight: bold;
}
.tagsForm {
  display: flex;
  flex-direction: row;
}
.tagsInput {
  margin: 20px 50px 20px 50px;
  width: 50% !important;
}
.modaltags {
  text-align: center !important;
  height: 350px;
}
#color-picker {
  width: 50% !important;
  margin: 30px 0px 0px 0px !important;
}
.tagsCards {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 50px;
  align-items: center;
  justify-content: center;
}
.tagCard {
  padding: 30px;
  height: 50px !important;
  text-align: center;
  border-radius: 30px !important;
  display: flex;
  align-items: center;
  margin: 20px;
  justify-content: center;
}
.center {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deleteTag {
  font-size: 25px !important;
  color: white !important;
  margin-left: 10px;
}
.v-chip .v-chip__content {
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: space-between;
}

* {
  box-sizing: border-box;
  font-family: "Arial";
}
.wrapper-dropdown {
  position: relative;
  width: 90% !important;
  background: #fff;
  color: #2e2e2e;
  outline: none;
  cursor: pointer;
  border-bottom: solid 1px rgba(0, 0, 0, 0.87);
  text-align: left;
}
.wrapper-dropdown > span {
  width: 100%;
  display: block;
  padding: 5px;
}
.wrapper-dropdown > span > span {
  padding: 5px 13px;
  margin-right: 10px;
  border-radius: 50px;
}
.wrapper-dropdown > span:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: 16px;
  top: calc(50% + 4px);
  margin-top: -6px;
  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #2e2e2e transparent;
}

.wrapper-dropdown .dropdown {
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  font-weight: normal;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  border-top: 0;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%),
    0 0 0 0 rgb(0 0 0 / 12%);
}

.upload {
  display: flex;
  flex-direction: column;
}
.upload > input {
  margin: 0 auto 20px auto;
}

.wrapper-dropdown .dropdown li {
  display: block;
  text-decoration: none;
  color: #2e2e2e;
  padding: 5px;
  cursor: pointer;
}
.theme--light.v-tabs-items {
  background: transparent !important;
}
.wrapper-dropdown .dropdown li > span {
  padding: 5px 13px;
  margin-right: 10px;
  border-radius: 50px;
}

.wrapper-dropdown .dropdown li:hover {
  background: #eee;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .tagsForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tagsInput {
    margin: 0px 0px 0px 0px;
    width: 80% !important;
  }
  #color-picker {
    margin-left: 45px !important;
    width: 80% !important;
  }
}
</style>
