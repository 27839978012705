<template>
  <div class="background">
    <v-app-bar
      app
      class="app-bar"
      color="white"
      flat
      elevation="1"
      max-height="60px"
    >
      <router-link
        :to="{ name: 'Accueil' }"
        class="router"
      >
        <img
          class="logo"
          alt="Logo"
          src="../assets/logo.png"
        >
      </router-link>
      <v-spacer />
      <!-- <v-avatar width="32" height="32" color="transparent" class="avatar">
        <img src="../assets/user.png" alt="Profile" />
      </v-avatar> -->
      <div class="disconnect">
        <v-btn
          outlined
          color="#2b3f53"
          @click="logout"
        >
          Deconnexion
        </v-btn>
      </div>
    </v-app-bar>

    <v-app-bar
      app
      color="white"
      flat
      class="mobileMenu"
      elevation="1"
      max-height="60px"
    >
      <div class="mobileNav">
        <v-app-bar-nav-icon
          color="black"
          @click="drawer = true"
        />
        <v-navigation-drawer
          v-model="drawer"
          absolute
          temporary
          height="1000px"
        >
          <v-list
            nav
            dense
          >
            <v-list-item
              class="closeIcon"
              @click="closeMenu()"
            >
              <v-icon>mdi-close</v-icon>
            </v-list-item>
            <div class="startMenu" />
            <v-list-item-group
              v-model="group"
              active-class="blue--text text--accent-4"
            >
              <div v-if="show">
                <v-list-item
                  v-for="(route, index) in routes"
                  :key="index"
                  text
                  :to="{ name: route.name }"
                >
                  <v-list-item-title>{{ route.name }}</v-list-item-title>
                </v-list-item>
              </div>
              <v-list-item>
                <v-list-item-title @click="logout">
                  Deconnexion
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
        <router-link
          :to="{ name: 'Accueil' }"
          class="router"
        >
          <img
            class="logo"
            alt="Logo"
            src="../assets/logo.png"
          >
        </router-link>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import project from "../../repository/projectRepository.js";

export default {
  data() {
    return {
      isConnected: false,
      socketMessage: "",
      drawer: false,
      group: null,
      routes: this.$router.options.routes,
      show: true,
      projects: null,
    };
  },
  sockets: {
    notificationEvent: async function (data) {
      if (data && data.cas) {
        await this.getAllProjects();
        if (this.projects.some((e) => e.cas === data.cas)) {
          //
          let stringArticles = "";
          if (data.teeths) {
            data.teeths.forEach((e) => {
              if (e.article) {
                stringArticles += e.article + " ";
              }
            });
          }
          //
          this.$nativeNotification.push(
            {
              title: "🔔 Un nouveau CAS est disponible",
              body:
                "CAS : " +
                data.cas +
                "\n" +
                "Praticien : " +
                data.customer +
                "\n" +
                "Article(s) : " +
                stringArticles +
                "\n",
              tag: "newCas" + data.cas,
              icon: "https://i.imgur.com/OFm63X7.jpg",
              image: "https://i.imgur.com/OFm63X7.jpg",
              data: { item: data },
            },
            {
              onerror: (e) => {
                console.log(e);
              },
              onclick: () => {
                window.open(window.location.origin + "/");
              },
              onclose: (e) => {
                console.log(e);
              },
              onshow: (e) => {
                console.log(e);
              },
            }
          );
        }
      }
    },
  },
  mounted() {
    this.getAllProjects();
    this.$socket.$subscribe();
    this.routes = this.routes.filter((route) => route.meta.visible === true);
    if (
      this.$store.state.user.status === "operateur" ||
      this.$store.state.user.status === "praticien"
    ) {
      this.show = false;
    } else {
      if (this.$store.state.user.status === "admin") {
        this.routes.forEach((route) => {
          if (route.name === "Operateur") {
            route.meta.visible = true;
          }
        });
      }
    }
  },

  methods: {
    closeMenu() {
      this.drawer = false;
    },
    logout() {
      this.$store.commit("SET_USER_INFO", "");
      this.$store.commit("SET_USER_LABO", "");
      this.$store.commit("SET_USER_TOKEN", "");
      this.$router.push("login");
      //document.location.reload();
    },
    async getAllProjects() {
      const tmp = await project.getProjects();

      var sources = tmp.data
        .filter(function (x) {
          if (x.isArchived === true) {
            return false; // skip
          }
          return true;
        })
        .map(function (x) {
          return x;
        });

      this.projects = sources;

      return this.projects;
    },
  },
};
</script>

<style scoped>
.router {
  max-width: 250px;
  max-height: 100px;
  margin-top: -60px;
}
.app-bar-global {
  justify-content: center;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 170px;
  position: relative;
  top: 29px;
}
.avatar {
  margin-right: 40px;
  height: 24px;
  width: 24px;
}
.sheet-list {
  background-color: #003b5b !important;
}

.list-btn-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.list-button-active {
  width: 100%;
  border-left: solid;
}
.icon {
  width: 35px;
  height: 35px;
}

.sheet-list.v-sheet.theme--light {
  min-height: 1000px;
}
.row {
  flex-wrap: nowrap !important;
  height: 100%;
}
.mobileNav {
  width: 100%;
  align-items: center;
  display: flex;
  z-index: 0;
  justify-content: space-between !important;
}
.mobileMenu {
  display: none;
}
.menuBar {
  padding-top: 0px;
}
.closeIcon {
  float: right;
}
.startMenu {
  margin-top: 30px;
}
@media screen and (max-width: 900px) {
  .mobileMenu {
    display: block;
  }
  .app-bar {
    display: none;
  }
  .menuBar {
    display: none;
  }
}
</style>
