import { SET_USER_INFO, SET_USER_LABO, SET_USER_TOKEN } from "./type";

import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    labo: null,
    token: null,
  },
  mutations: {
    [SET_USER_INFO](state, user) {
      state.user = user;
    },
    [SET_USER_LABO](state, labo) {
      state.labo = labo;
    },
    [SET_USER_TOKEN](state, token) {
      state.token = token;
    },
  },
  actions: {
    setUserInformations(context, user) {
      context.commit("SET_USER_INFO", user);
    },
    setUserLabo(context, labo) {
      context.commit("SET_USER_LABO", labo);
    },
    setUserToken(context, token) {
      context.commit("SET_USER_TOKEN", token);
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
