import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props: ["data", "options", "height", "width"],
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.data, {
      borderWidth: "10px",
      hoverBackgroundColor: "red",
      hoverBorderWidth: "10px",
    });
  },
  watch: {
    data: function () {
      this.$data._chart.destroy();
      this.renderChart(this.data, {
        borderWidth: "10px",
        hoverBackgroundColor: "red",
        hoverBorderWidth: "10px",
      });
    },
  },
};
