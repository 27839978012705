import { API_BASE_URL, API_ROUTES } from "../api-constant.js";

import axios from "axios";
import store from "../store";

export default {
  async getAdmins() {
    const request = await axios.get(
      API_BASE_URL + API_ROUTES.getAdminsByLaboId + store.state.user.laboId,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async getAdminsById(id) {
    const request = await axios.get(API_BASE_URL + API_ROUTES.admins + id, {
      headers: {
        Authorization: `Bearer ${store.state.token}`,
      },
    });
    return request;
  },

  async getAdminsByemail(email) {
    const request = await axios.get(
      API_BASE_URL + API_ROUTES.getAdminsByemail + email,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async postAdmins(admins) {
    const request = await axios.post(
      API_BASE_URL + API_ROUTES.admins,
      {
        email: admins.email,
        firstName: admins.firstName,
        lastName: admins.lastName,
        userId: admins.userId,
        laboId: store.state.user.laboId,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async putAdmins(admins) {
    const request = await axios.put(
      API_BASE_URL + API_ROUTES.admins + admins.id,
      {
        email: admins.email,
        firstName: admins.firstName,
        lastName: admins.lastName,
        userId: admins.userId,
        laboId: store.state.user.laboId,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async deleteAdmins(id) {
    const request = await axios.delete(API_BASE_URL + API_ROUTES.admins + id, {
      headers: {
        Authorization: `Bearer ${store.state.token}`,
      },
    });
    return request;
  },
};
