import { API_BASE_URL, API_ROUTES } from "../api-constant.js";
import store from "../store";
import axios from "axios";
export default {
  async getMaterials() {
    const request = await axios.get(
      API_BASE_URL + API_ROUTES.getMarerialsByLaboId + store.state.user.laboId,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async postMaterial(materials) {
    const request = await axios.post(
      API_BASE_URL + API_ROUTES.materials,
      {
        materials,
        laboId: store.state.user.laboId,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async putMaterial(material) {
    const request = await axios.put(
      API_BASE_URL + API_ROUTES.materials + material.id,
      {
        materials: material.materials,
        laboId: store.state.user.laboId,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async deleteMaterial(material) {
    const request = await axios.delete(
      API_BASE_URL + API_ROUTES.materials + material.id,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },
};
