import { API_BASE_URL, API_ROUTES } from "../api-constant.js";
import axios from "axios";
import store from "../store";
export default {
  async getUser(email, token) {
    const request = await axios.get(
      API_BASE_URL + API_ROUTES.getUserByEmail + email,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return request;
  },

  async getAllUser() {
    const request = await axios.get(
      API_BASE_URL + API_ROUTES.getUsersByLaboId + store.state.user.laboId,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async postUser(user) {
    const request = await axios.post(
      API_BASE_URL + API_ROUTES.users,
      {
        email: user.email,
        password: user.password,
        firstName: user.firstName,
        lastName: user.lastName,
        avatar: "",
        status: user.status.value,
        tags: user.tags,
        send: Boolean(user.send),
        laboId: store.state.user.laboId,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async putUser(user) {
    const request = await axios.put(
      API_BASE_URL + API_ROUTES.users + user.id,
      {
        email: user.email,
        password: user.password,
        firstName: user.firstName,
        lastName: user.lastName,
        avatar: user.file,
        status: user.status,
        tags: user.tags,
        send: Boolean(user.send),
        laboId: store.state.user.laboId,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async deleteUser(user) {
    const request = await axios.delete(
      API_BASE_URL + API_ROUTES.users + user.id,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async getUserByLaboId(id) {
    const request = await axios.get(
      API_BASE_URL + API_ROUTES.getUsersByLaboId + id,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },
};
