<template>
  <div :id="task.cas">
    <v-icon @click="editItem(task)">
      mdi-lead-pencil
    </v-icon>
    <v-dialog
      v-model="dialog"
      max-width="100%"
      height="95%"
    >
      <template />
      <v-card>
        <v-card-title class="modalTitle">
          <span
            class="headline"
          >{{ formTitle }}
            <v-icon
              class="modalIcon"
              medium
            >
              {{ icon }}
            </v-icon>
          </span>
          <v-icon
            large
            @click="close"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text
          v-if="editedTask !== {}"
          id="printMe"
        >
          <v-form
            ref="form"
            lazy-validation
          >
            <div class="modalForm">
              <div class="container fluid">
                <div
                  class="row"
                  style="margin-top: 25px; color: #000;"
                >
                  <div class="col-sm">
                    <blockquote class="blockquote text-right">
                      <h1
                        class="mb-0"
                        style="color: #000;"
                      >
                        Cas {{ task.cas }}
                      </h1>
                      <footer
                        class="blockquote-footer"
                        style="margin-top: 20px;"
                      >
                        <b>Date : </b>
                        <cite title="Source Title">{{ task.startDate }}</cite>
                      </footer>
                    </blockquote>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm">
                    <p>
                      <b style="color: #000;">Patient : </b>
                      {{ task.customer }} |
                      <b style="color: #000;">Praticien : </b>
                      {{ task.praticien }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm">
                    <div class="card">
                      <div
                        class="card-header"
                        style="color: #000;"
                      >
                        <b>Commentaire :</b>
                      </div>
                      <div class="card-body">
                        <blockquote class="blockquote mb-3">
                          <p
                            style="
                              padding: 15px;
                              height: 85px;
                              display: -webkit-box;
                              max-width: 100%;
                              -webkit-line-clamp: 5;
                              -webkit-box-orient: vertical;
                              overflow: hidden;
                              width: 1200px;
                              color: #000;
                            "
                          >
                            {{ task.comment }}
                          </p>
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>

                <v-divider />

                <div class="row">
                  <div class="col-6">
                    <div
                      style="
                        background: #d1d5db52;
                        border-radius: 15px;
                        transition: background 0.8s ease 0s;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        flex-wrap: nowrap;
                        flex-direction: row;
                        align-content: center;
                        color: #000;
                      "
                    >
                      <div
                        id="teinte"
                        style="
                          display: flex;
                          position: absolute;
                          z-index: 999;
                          width: 105px;
                          height: 105px;
                          padding: 10px;
                          background: rgba(223, 223, 223, 0.9);
                          border-radius: 10px;
                          flex-flow: row nowrap;
                          place-content: center;
                          align-items: center;
                        "
                      >
                        <v-text-field
                          v-model="editedItem.teinte"
                          label="Teinte"
                        />
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="392.289"
                        height="644"
                        viewBox="0 0 392.289 644"
                      >
                        <defs>
                          <filter
                            id="shadow"
                            x="0"
                            y="0"
                            width="392.289"
                            height="644"
                            filterUnits="userSpaceOnUse"
                          >
                            <feOffset input="SourceAlpha" />
                            <feGaussianBlur
                              stdDeviation="10"
                              result="blur"
                            />
                            <feFlood flood-opacity="0.18" />
                            <feComposite
                              operator="in"
                              in2="blur"
                            />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g
                          id="dentures"
                          style="cursor: pointer;"
                          transform="translate(-728.846 -173.501)"
                        >
                          <g
                            id="_48"
                            style="cursor: pointer;"
                            data-name="48"
                            transform="translate(764.576 502.128)"
                            @click="clickTooth(48)"
                          >
                            <path
                              id="_48-2"
                              data-name="48"
                              d="M66.96,548.119c-2.39,6.871-9.38,10.754-16.251,11.591-8.544,1.016-22.285,1.016-28.678-5.616-6.691-6.93-3.824-21.269-1.792-29.574.836-3.166,1.912-6.273,4.481-8.543,5.8-5.138,13.084-5.556,20.313-6.512a26.1,26.1,0,0,1,4.361-.179c4.481.179,9.141,1.494,12.307,4.541,3.943,4.063,5.258,10.635,5.8,16.071S68.812,542.862,66.96,548.119Z"
                              transform="translate(-17.853 -509.262)"
                              :fill="[
                                tooths.some((el) => el.id === 48)
                                  ? tooths.find((element) => element.id === 48)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(13.425 12.872)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26"
                              data-name="26"
                              transform="translate(14.925 30.372)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >48</tspan>
                            </text>
                          </g>
                          <g
                            id="_47"
                            style="cursor: pointer;"
                            data-name="47"
                            transform="translate(766.695 553.39)"
                            @click="clickTooth(47)"
                          >
                            <path
                              id="_47-2"
                              data-name="47"
                              d="M75.5,635.472c-2.689,5.8-9.5,9.858-15.713,10.694-9.977,1.314-25.631,2.868-33.756-4.481-2.39-2.151-3.644-5.5-4.242-8.6-.836-4.421-.119-9.021.3-13.443.6-5.974.956-11.77,5.736-16.071,6.512-5.855,16.012-7.229,24.2-8.3a18.114,18.114,0,0,1,7.169.358c13.5,3.465,17.326,15.892,17.326,28.5C76.513,627.825,77.111,632.007,75.5,635.472Z"
                              transform="translate(-21.4 -595.062)"
                              :fill="[
                                tooths.some((el) => el.id === 47)
                                  ? tooths.find((element) => element.id === 47)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-2"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(15.305 15.61)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-2"
                              data-name="26"
                              transform="translate(17.805 33.11)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >47</tspan>
                            </text>
                          </g>
                          <g
                            id="_46"
                            style="cursor: pointer;"
                            data-name="46"
                            transform="translate(774.48 606.106)"
                            @click="clickTooth(46)"
                          >
                            <path
                              id="_46-2"
                              data-name="46"
                              d="M91.139,721.357c-2.509,10.157-11.113,14.458-19.955,18.282-2.987,1.255-5.676,3.286-8.962,3.7-6.811.836-16.37-1.912-20.313-7.707-2.509-3.7-2.928-7.827-4.3-11.949-1.494-4.541-3.585-9.44-3.107-14.339.358-3.7,2.39-5.915,3.644-9.26,1.016-2.808,1.075-5.915,2.33-8.663,2.689-6.034,8.364-7.169,14.219-7.946,1.255-.119,2.569-.179,3.824-.179a76.407,76.407,0,0,1,11.471,1.494c4.063.657,8.006,1.075,11.352,3.824,4.839,4,6.811,10.4,8.066,16.31C90.542,710.364,92.514,715.741,91.139,721.357Z"
                              transform="translate(-34.432 -683.3)"
                              :fill="[
                                tooths.some((el) => el.id === 46)
                                  ? tooths.find((element) => element.id === 46)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-3"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(15.519 17.893)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-3"
                              data-name="26"
                              transform="translate(18.019 35.393)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >46</tspan>
                            </text>
                          </g>
                          <g
                            id="_45"
                            style="cursor: pointer;"
                            data-name="45"
                            transform="translate(792.83 661.956)"
                            @click="clickTooth(45)"
                          >
                            <path
                              id="_45-2"
                              data-name="45"
                              d="M111.448,798.838c-2.569,9.5-12.965,17.625-22.7,18.521-8.783.777-22.524-5.019-23.54-14.936-.6-5.974,3.166-12.546,6.811-17.027,4.9-5.974,13.921-7.886,21.209-8.543A17.224,17.224,0,0,1,111.448,798.838Z"
                              transform="translate(-65.143 -776.78)"
                              :fill="[
                                tooths.some((el) => el.id === 45)
                                  ? tooths.find((element) => element.id === 45)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-4"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(11.17 8.044)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-4"
                              data-name="26"
                              transform="translate(13.67 25.544)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >45</tspan>
                            </text>
                          </g>
                          <g
                            id="_44"
                            style="cursor: pointer;"
                            data-name="44"
                            transform="translate(808.939 698.343)"
                            @click="clickTooth(44)"
                          >
                            <path
                              id="_44-2"
                              data-name="44"
                              d="M132.7,856.931c-2.868,8.663-8.3,17.983-18.1,19.357-10.754,1.494-24.017-8.065-22.345-19.656,1.494-10.157,11.172-17.326,20.851-18.7a20.438,20.438,0,0,1,2.748-.239c6.751-.179,15.354,2.091,17.864,8.484C135.148,849.7,133.834,853.526,132.7,856.931Z"
                              transform="translate(-92.107 -837.684)"
                              :fill="[
                                tooths.some((el) => el.id === 44)
                                  ? tooths.find((element) => element.id === 44)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-5"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(9.061 6.657)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-5"
                              data-name="26"
                              transform="translate(10.561 24.157)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >44</tspan>
                            </text>
                          </g>
                          <g
                            id="_43"
                            style="cursor: pointer;"
                            data-name="43"
                            transform="translate(833.707 728.288)"
                            @click="clickTooth(43)"
                          >
                            <path
                              id="_43-2"
                              data-name="43"
                              d="M169.819,901.783c-1.434,10.037-5.556,23.838-17.684,24.615-12.188.836-22.345-14.7-17.207-25.511,4.66-9.858,14.219-12.188,24.077-13.024C167.788,887.085,170.894,894.494,169.819,901.783Z"
                              transform="translate(-133.562 -887.806)"
                              :fill="[
                                tooths.some((el) => el.id === 43)
                                  ? tooths.find((element) => element.id === 43)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-6"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(6.293 6.712)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-6"
                              data-name="26"
                              transform="translate(8.793 24.212)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >43</tspan>
                            </text>
                          </g>
                          <g
                            id="_42"
                            style="cursor: pointer;"
                            data-name="42"
                            transform="translate(861.418 742.682)"
                            @click="clickTooth(42)"
                          >
                            <path
                              id="_42-2"
                              data-name="42"
                              d="M210.776,940.875c-1.016,4.421-2.808,8.065-7.647,8.842-6.691,1.016-12.726-3.883-17.923-7.349-2.39-1.613-5.2-3.346-5.258-6.572-.119-7.647,9.44-13.741,14.219-18.521,2.39-2.45,4.9-4.66,8.364-5.258a6.582,6.582,0,0,1,1.075-.119c5.138-.12,6.632,5.317,7.229,9.559C211.791,928.269,212.329,934.064,210.776,940.875Z"
                              transform="translate(-179.946 -911.897)"
                              :fill="[
                                tooths.some((el) => el.id === 42)
                                  ? tooths.find((element) => element.id === 42)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-7"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(6.581 8.318)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-7"
                              data-name="26"
                              transform="translate(9.081 25.818)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >42</tspan>
                            </text>
                          </g>
                          <g
                            id="_31"
                            style="cursor: pointer;"
                            data-name="31"
                            transform="translate(925.156 748.43)"
                            @click="clickTooth(31)"
                          >
                            <path
                              id="_31-2"
                              data-name="31"
                              d="M320.025,952.814c-1.255,2.39-4.541,3.405-6.93,4.182a49.185,49.185,0,0,1-20.612,2.33c-3.764-.478-5.915-2.509-5.855-6.512.119-3.465,1.494-6.811,2.27-10.157,1.255-5.377,2.091-10.276,4.66-15.235,1.733-3.226,4.122-6.333,8.185-5.855,5.915.717,8.066,5.915,10.515,10.635,1.912,3.7,4,7.289,5.735,11.053C319.308,946.122,321.7,949.587,320.025,952.814Z"
                              transform="translate(-286.626 -921.518)"
                              :fill="[
                                tooths.some((el) => el.id === 31)
                                  ? tooths.find((element) => element.id === 31)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-8"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(3.845 10.57)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-8"
                              data-name="26"
                              transform="translate(9.345 28.07)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >31</tspan>
                            </text>
                          </g>
                          <g
                            id="_32"
                            style="cursor: pointer;"
                            data-name="32"
                            transform="translate(957.275 742.204)"
                            @click="clickTooth(32)"
                          >
                            <path
                              id="_32-2"
                              data-name="32"
                              d="M372.2,935c-.06,3.226-2.868,4.959-5.258,6.572-5.2,3.465-11.232,8.364-17.923,7.349-4.839-.777-6.632-4.421-7.647-8.842-1.553-6.811-1.016-12.606-.06-19.417.6-4.242,2.091-9.679,7.229-9.559a6.583,6.583,0,0,1,1.075.12c3.465.6,5.974,2.808,8.364,5.258C362.757,921.255,372.316,927.349,372.2,935Z"
                              transform="translate(-340.389 -911.097)"
                              :fill="[
                                tooths.some((el) => el.id === 32)
                                  ? tooths.find((element) => element.id === 32)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-9"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(1.724 8.796)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-9"
                              data-name="26"
                              transform="translate(5.224 26.296)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >32</tspan>
                            </text>
                          </g>
                          <g
                            id="_33"
                            style="cursor: pointer;"
                            data-name="33"
                            transform="translate(980.336 727.811)"
                            @click="clickTooth(33)"
                          >
                            <path
                              id="_33-2"
                              data-name="33"
                              d="M396.875,925.6c-12.128-.777-16.251-14.578-17.685-24.615-1.075-7.289,2.031-14.7,10.814-13.921,9.858.836,19.417,3.167,24.077,13.024C419.219,910.9,409.063,926.434,396.875,925.6Z"
                              transform="translate(-378.986 -887.006)"
                              :fill="[
                                tooths.some((el) => el.id === 33)
                                  ? tooths.find((element) => element.id === 33)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-10"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(5.664 7.189)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-10"
                              data-name="26"
                              transform="translate(9.164 24.689)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >33</tspan>
                            </text>
                          </g>
                          <g
                            id="_34"
                            style="cursor: pointer;"
                            data-name="34"
                            transform="translate(999.328 697.865)"
                            @click="clickTooth(34)"
                          >
                            <path
                              id="_34-2"
                              data-name="34"
                              d="M430.523,875.489c-9.8-1.374-15.235-10.694-18.1-19.357-1.135-3.405-2.45-7.229-1.016-10.754,2.509-6.393,11.113-8.663,17.864-8.484a20.429,20.429,0,0,1,2.748.239c9.679,1.374,19.357,8.543,20.851,18.7C454.54,867.423,441.277,876.982,430.523,875.489Z"
                              transform="translate(-410.776 -836.884)"
                              :fill="[
                                tooths.some((el) => el.id === 34)
                                  ? tooths.find((element) => element.id === 34)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-11"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(9.671 7.135)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-11"
                              data-name="26"
                              transform="translate(12.171 24.635)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >34</tspan>
                            </text>
                          </g>
                          <g
                            id="_35"
                            style="cursor: pointer;"
                            data-name="35"
                            transform="translate(1010.695 661.479)"
                            @click="clickTooth(35)"
                          >
                            <path
                              id="_35-2"
                              data-name="35"
                              d="M476.718,801.623c-1.016,9.918-14.757,15.713-23.54,14.936-9.738-.9-20.134-9.022-22.7-18.521A17.224,17.224,0,0,1,448.7,776.052c7.289.657,16.31,2.569,21.209,8.544C473.552,789.076,477.315,795.648,476.718,801.623Z"
                              transform="translate(-429.804 -775.98)"
                              :fill="[
                                tooths.some((el) => el.id === 35)
                                  ? tooths.find((element) => element.id === 35)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-12"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(11.304 8.522)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-12"
                              data-name="26"
                              transform="translate(14.804 26.022)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >35</tspan>
                            </text>
                          </g>
                          <g
                            id="_36"
                            style="cursor: pointer;"
                            data-name="36"
                            transform="translate(1018.846 605.629)"
                            @click="clickTooth(36)"
                          >
                            <path
                              id="_36-2"
                              data-name="36"
                              d="M497.443,722.888c-1.374,4.122-1.792,8.245-4.3,11.949-3.943,5.8-13.5,8.543-20.313,7.707-3.286-.418-5.974-2.45-8.962-3.7-8.842-3.824-17.445-8.125-19.955-18.282-1.374-5.616.6-10.993,1.733-16.43,1.255-5.915,3.226-12.307,8.066-16.31,3.346-2.748,7.289-3.167,11.352-3.824a76.383,76.383,0,0,1,11.471-1.494c1.255,0,2.569.06,3.824.179,5.855.777,11.531,1.912,14.219,7.946,1.255,2.748,1.314,5.855,2.33,8.663,1.255,3.346,3.286,5.556,3.644,9.26C501.028,713.448,498.937,718.347,497.443,722.888Z"
                              transform="translate(-443.443 -682.5)"
                              :fill="[
                                tooths.some((el) => el.id === 36)
                                  ? tooths.find((element) => element.id === 36)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-13"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(17.155 18.371)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-13"
                              data-name="26"
                              transform="translate(20.655 35.871)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >36</tspan>
                            </text>
                          </g>
                          <g
                            id="_37"
                            style="cursor: pointer;"
                            data-name="37"
                            transform="translate(1028.584 552.911)"
                            @click="clickTooth(37)"
                          >
                            <path
                              id="_37-2"
                              data-name="37"
                              d="M514.581,632.283c-.6,3.107-1.852,6.452-4.242,8.6-8.125,7.349-23.778,5.8-33.756,4.481-6.213-.836-13.024-4.9-15.713-10.694-1.613-3.465-1.016-7.647-1.016-11.352,0-12.606,3.824-25.033,17.326-28.5a18.114,18.114,0,0,1,7.169-.359c8.185,1.075,17.685,2.45,24.2,8.3,4.78,4.3,5.138,10.1,5.736,16.071C514.7,623.261,515.418,627.861,514.581,632.283Z"
                              transform="translate(-459.747 -594.262)"
                              :fill="[
                                tooths.some((el) => el.id === 37)
                                  ? tooths.find((element) => element.id === 37)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-14"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(15.415 16.089)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-14"
                              data-name="26"
                              transform="translate(18.915 33.589)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >37</tspan>
                            </text>
                          </g>
                          <g
                            id="_38"
                            style="cursor: pointer;"
                            data-name="38"
                            transform="translate(1035.652 501.65)"
                            @click="clickTooth(38)"
                          >
                            <path
                              id="_38-2"
                              data-name="38"
                              d="M517.67,553.294c-6.393,6.632-20.134,6.632-28.678,5.616-6.871-.836-13.861-4.72-16.251-11.591-1.852-5.258-1.075-12.785-.538-18.222s1.852-12.009,5.8-16.071c3.166-3.047,7.827-4.361,12.307-4.541a26.1,26.1,0,0,1,4.361.179c7.229.956,14.518,1.374,20.313,6.512,2.569,2.27,3.644,5.377,4.481,8.544C521.493,532.024,524.361,546.363,517.67,553.294Z"
                              transform="translate(-471.574 -508.462)"
                              :fill="[
                                tooths.some((el) => el.id === 38)
                                  ? tooths.find((element) => element.id === 38)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-15"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(13.348 13.35)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-15"
                              data-name="26"
                              transform="translate(16.848 30.85)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >38</tspan>
                            </text>
                          </g>
                          <g
                            id="_18"
                            style="cursor: pointer;"
                            data-name="18"
                            transform="translate(761.709 442.317)"
                            @click="clickTooth(18)"
                          >
                            <path
                              id="_18-2"
                              data-name="18"
                              d="M56.425,451.122c-4.481,2.868-11.172,4.242-16.43,4.9-10.933,1.314-16.848-9.619-21.807-17.266-2.808-4.242-5.915-8.663-4.959-14.04,1.135-6.154,9.38-12.845,15.115-14.518,2.031-.6,4.182-.3,6.273-.6,11.352-1.494,26.527.358,32.262,11.83C71.719,431.108,64.729,445.745,56.425,451.122Z"
                              transform="translate(-13.052 -409.153)"
                              :fill="[
                                tooths.some((el) => el.id === 18)
                                  ? tooths.find((element) => element.id === 18)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-16"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(16.291 9.682)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-16"
                              data-name="26"
                              transform="translate(20.791 27.182)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >18</tspan>
                            </text>
                          </g>
                          <g
                            id="_17"
                            style="cursor: pointer;"
                            data-name="17"
                            transform="translate(759.105 391.019)"
                            @click="clickTooth(17)"
                          >
                            <path
                              id="_17-2"
                              data-name="17"
                              d="M66.56,352.631c-3.465,10.455-8.125,18.64-19.9,20.851-9.38,1.792-21.09.836-28.08-6.213-3.226-3.286-4.481-8.006-5.915-12.248-1.494-4.421-4.3-9.2-3.943-14.04.538-6.751,6.213-9.918,11.352-13.144,2.808-1.733,5.676-3.7,8.962-4.3a10.29,10.29,0,0,1,2.33-.239,53.324,53.324,0,0,1,6.93.6c7.707.836,19.417,1.434,24.914,7.827,2.33,2.748,5.2,6.811,5.736,10.455C69.488,345.641,67.635,349.4,66.56,352.631Z"
                              transform="translate(-8.696 -323.29)"
                              :fill="[
                                tooths.some((el) => el.id === 17)
                                  ? tooths.find((element) => element.id === 17)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-17"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(17.894 12.981)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-17"
                              data-name="26"
                              transform="translate(22.394 30.481)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >17</tspan>
                            </text>
                          </g>
                          <g
                            id="_16"
                            style="cursor: pointer;"
                            data-name="16"
                            transform="translate(764.34 337.688)"
                            @click="clickTooth(16)"
                          >
                            <path
                              id="_16-2"
                              data-name="16"
                              d="M75.524,265.137c-.657,2.868-.119,5.8-1.016,8.663-2.151,7.11-9.559,13.682-16.908,15.056-3.7.657-6.811,0-10.455-.717-9.738-1.912-20.911-7.289-25.272-16.729-3.166-6.871-6.333-18.461-2.987-25.69,1.792-3.883,5.2-6.333,8.842-8.424a19.237,19.237,0,0,1,6.572-2.748c10.575-1.912,20.194,1.733,29.574,5.855,3.405,1.434,7.886,1.673,10.157,5.019C78.451,251.874,77.017,258.147,75.524,265.137Z"
                              transform="translate(-17.457 -234.025)"
                              :fill="[
                                tooths.some((el) => el.id === 16)
                                  ? tooths.find((element) => element.id === 16)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-18"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(17.661 15.312)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-18"
                              data-name="26"
                              transform="translate(22.16 32.812)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >16</tspan>
                            </text>
                          </g>
                          <g
                            id="_15"
                            style="cursor: pointer;"
                            data-name="15"
                            transform="translate(777.354 302.822)"
                            @click="clickTooth(15)"
                          >
                            <path
                              id="_15-2"
                              data-name="15"
                              d="M69.715,211.893c-6.99.538-18.7-1.255-23.778-6.572-1.852-1.852-1.494-3.824-2.509-5.915-1.374-2.868-3.764-4.66-4.122-8.066-1.016-9.738,10.276-15.115,18.461-15.653,3.525-.239,6.811,1.912,9.858,3.286,2.151.956,4.481,1.255,6.572,2.27A25.237,25.237,0,0,1,85.727,193.97C89.491,203.469,78.079,211.3,69.715,211.893Z"
                              transform="translate(-39.241 -175.669)"
                              :fill="[
                                tooths.some((el) => el.id === 15)
                                  ? tooths.find((element) => element.id === 15)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-19"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(11.646 6.177)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-19"
                              data-name="26"
                              transform="translate(17.146 23.677)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >15</tspan>
                            </text>
                          </g>
                          <g
                            id="_14"
                            style="cursor: pointer;"
                            data-name="14"
                            transform="translate(792.035 267.585)"
                            @click="clickTooth(14)"
                          >
                            <path
                              id="_14-2"
                              data-name="14"
                              d="M105.3,149.794a11.791,11.791,0,0,1-4.541,4.361c-2.868,1.494-6.452,2.031-9.559,2.689-6.393,1.314-11.053-1.075-16.729-3.644-2.45-1.135-5.5-2.031-7.11-4.3-2.211-3.047-1.494-5.915-2.33-9.26-1.075-4.182-2.091-7.767,0-11.949,2.211-4.421,5.736-4.481,9.44-6.811,2.27-1.434,3.764-3.465,6.572-4.063,5.258-1.135,11.113,5.676,14.7,8.723,1.852,1.673,4.122,2.868,5.915,4.6C107.029,135.515,109.3,142.923,105.3,149.794Z"
                              transform="translate(-63.812 -116.689)"
                              :fill="[
                                tooths.some((el) => el.id === 14)
                                  ? tooths.find((element) => element.id === 14)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-20"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(8.966 9.415)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-20"
                              data-name="26"
                              transform="translate(13.466 26.915)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >14</tspan>
                            </text>
                          </g>
                          <g
                            id="_13"
                            style="cursor: pointer;"
                            data-name="13"
                            transform="translate(812.984 234.313)"
                            @click="clickTooth(13)"
                          >
                            <path
                              id="_13-2"
                              data-name="13"
                              d="M133.213,107.721c-2.868.418-6.154-.478-8.962-.956-10.157-1.673-20.97-5.975-24.794-16.549-1.553-4.3.358-7.707,1.673-11.65,1.075-3.107,1.314-7.11,3.226-9.858,2.569-3.7,7.767-4.481,11.77-5.8A32.871,32.871,0,0,1,122.4,61.06a6.493,6.493,0,0,1,1.075-.06c5.2,0,8.663,4.3,10.993,8.544a66.059,66.059,0,0,1,7.767,24.5C143.071,100.85,140.561,106.7,133.213,107.721Z"
                              transform="translate(-98.876 -61)"
                              :fill="[
                                tooths.some((el) => el.id === 13)
                                  ? tooths.find((element) => element.id === 13)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-21"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(9.016 11.687)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-21"
                              data-name="26"
                              transform="translate(14.516 29.187)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >13</tspan>
                            </text>
                          </g>
                          <g
                            id="_12"
                            style="cursor: pointer;"
                            data-name="12"
                            transform="translate(845.875 216.477)"
                            @click="clickTooth(12)"
                          >
                            <path
                              id="_12-2"
                              data-name="12"
                              d="M186.621,64.873c-.956,3.824-2.151,8.245-6.632,9.081-6.154,1.195-10.157-2.748-14.339-6.632-5.377-4.959-13.024-11.889-11.531-20.313.418-2.33,2.569-4.063,4.063-5.676A34.188,34.188,0,0,1,177.062,31.3c5.258-.956,8.125,2.689,9.38,7.289A54.359,54.359,0,0,1,186.621,64.873Z"
                              transform="translate(-153.93 -31.143)"
                              :fill="[
                                tooths.some((el) => el.id === 12)
                                  ? tooths.find((element) => element.id === 12)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-22"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(7.124 8.524)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-22"
                              data-name="26"
                              transform="translate(12.624 26.024)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >12</tspan>
                            </text>
                          </g>
                          <g
                            id="_11"
                            style="cursor: pointer;"
                            data-name="11"
                            transform="translate(878.869 203.48)"
                            @click="clickTooth(11)"
                          >
                            <path
                              id="_11-2"
                              data-name="11"
                              d="M253.9,25.527c-.9,3.644-2.031,7.169-3.167,10.754-2.629,8.424-4,22.046-14.936,23.48-6.93.956-10.933-8.066-14.936-12.128-8.544-8.663-17.684-21.628-6.572-32.262a16.544,16.544,0,0,1,5.975-3.405,45.1,45.1,0,0,1,10.455-2.45c1.733-.179,3.585.179,5.377.179s4.122-.239,6.452-.3c3.943-.06,8.006.3,10.1,2.987C255.453,16.028,254.915,21.345,253.9,25.527Z"
                              transform="translate(-209.155 -9.39)"
                              :fill="[
                                tooths.some((el) => el.id === 11)
                                  ? tooths.find((element) => element.id === 11)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-23"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(11.13 8.52)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-23"
                              data-name="26"
                              transform="translate(17.63 26.02)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >11</tspan>
                            </text>
                          </g>
                          <g
                            id="_21"
                            style="cursor: pointer;"
                            data-name="21"
                            transform="translate(926.068 203.001)"
                            @click="clickTooth(21)"
                          >
                            <path
                              id="_21-2"
                              data-name="21"
                              d="M322.015,46.833c-4,4.063-8.006,13.084-14.936,12.128-10.933-1.434-12.307-15.056-14.936-23.48-1.135-3.585-2.27-7.11-3.166-10.754-1.016-4.182-1.553-9.5,1.255-13.144,2.091-2.689,6.154-3.047,10.1-2.987,2.33.06,4.66.3,6.452.3s3.644-.358,5.377-.179a45.1,45.1,0,0,1,10.455,2.45,16.544,16.544,0,0,1,5.975,3.405C339.7,25.205,330.558,38.17,322.015,46.833Z"
                              transform="translate(-288.156 -8.59)"
                              :fill="[
                                tooths.some((el) => el.id === 21)
                                  ? tooths.find((element) => element.id === 21)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-24"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(9.932 8.999)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-24"
                              data-name="26"
                              transform="translate(14.432 26.499)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >21</tspan>
                            </text>
                          </g>
                          <g
                            id="_22"
                            style="cursor: pointer;"
                            data-name="22"
                            transform="translate(970.41 215.998)"
                            @click="clickTooth(22)"
                          >
                            <path
                              id="_22-2"
                              data-name="22"
                              d="M384.871,66.523c-4.182,3.883-8.185,7.827-14.339,6.632-4.481-.836-5.676-5.258-6.632-9.081a54.359,54.359,0,0,1,.179-26.288c1.255-4.6,4.122-8.245,9.38-7.289a34.188,34.188,0,0,1,18.879,10.037c1.494,1.613,3.644,3.346,4.063,5.676C397.9,54.634,390.248,61.564,384.871,66.523Z"
                              transform="translate(-362.376 -30.343)"
                              :fill="[
                                tooths.some((el) => el.id === 22)
                                  ? tooths.find((element) => element.id === 22)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-25"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(3.589 9.002)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-25"
                              data-name="26"
                              transform="translate(7.089 26.502)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >22</tspan>
                            </text>
                          </g>
                          <g
                            id="_23"
                            style="cursor: pointer;"
                            data-name="23"
                            transform="translate(994.008 233.836)"
                            @click="clickTooth(23)"
                          >
                            <path
                              id="_23-2"
                              data-name="23"
                              d="M444.8,89.415c-3.824,10.575-14.638,14.876-24.794,16.549-2.808.478-6.094,1.374-8.962.956-7.349-1.016-9.858-6.871-9.021-13.682a66.058,66.058,0,0,1,7.767-24.5c2.33-4.242,5.8-8.544,10.993-8.544a6.493,6.493,0,0,1,1.075.06,32.87,32.87,0,0,1,6.273,1.852c4,1.314,9.2,2.091,11.77,5.8,1.912,2.748,2.151,6.751,3.226,9.858C444.443,81.708,446.355,85.114,444.8,89.415Z"
                              transform="translate(-401.87 -60.2)"
                              :fill="[
                                tooths.some((el) => el.id === 23)
                                  ? tooths.find((element) => element.id === 23)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-26"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(8.993 12.164)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_23-3"
                              data-name="23"
                              transform="translate(12.493 29.664)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >23</tspan>
                            </text>
                          </g>
                          <g
                            id="_24"
                            style="cursor: pointer;"
                            data-name="24"
                            transform="translate(1014.949 267.107)"
                            @click="clickTooth(24)"
                          >
                            <path
                              id="_24-2"
                              data-name="24"
                              d="M479.226,138.837c-.836,3.346-.119,6.213-2.33,9.26-1.613,2.27-4.66,3.166-7.11,4.3-5.676,2.569-10.336,4.959-16.729,3.644-3.107-.657-6.691-1.195-9.559-2.689a11.791,11.791,0,0,1-4.541-4.361c-4-6.871-1.733-14.279,3.644-19.656,1.792-1.733,4.063-2.927,5.915-4.6,3.585-3.047,9.44-9.858,14.7-8.723,2.808.6,4.3,2.629,6.572,4.063,3.7,2.33,7.229,2.39,9.44,6.811C481.317,131.071,480.3,134.655,479.226,138.837Z"
                              transform="translate(-436.924 -115.889)"
                              :fill="[
                                tooths.some((el) => el.id === 24)
                                  ? tooths.find((element) => element.id === 24)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-27"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(10.05 9.893)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_24-3"
                              data-name="24"
                              transform="translate(13.55 27.393)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >24</tspan>
                            </text>
                          </g>
                          <g
                            id="_25"
                            style="cursor: pointer;"
                            data-name="25"
                            transform="translate(1025.924 302.345)"
                            @click="clickTooth(25)"
                          >
                            <path
                              id="_25-2"
                              data-name="25"
                              d="M502.452,190.541c-.359,3.405-2.748,5.2-4.122,8.066-1.016,2.091-.657,4.063-2.509,5.915-5.078,5.317-16.788,7.11-23.778,6.572-8.364-.6-19.776-8.424-16.012-17.923a25.237,25.237,0,0,1,11.531-12.726c2.091-1.016,4.421-1.314,6.572-2.27,3.047-1.374,6.333-3.525,9.858-3.286C492.175,175.425,503.467,180.8,502.452,190.541Z"
                              transform="translate(-455.29 -174.869)"
                              :fill="[
                                tooths.some((el) => el.id === 25)
                                  ? tooths.find((element) => element.id === 25)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-28"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(12.077 6.655)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-26"
                              data-name="26"
                              transform="translate(15.577 24.155)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >25</tspan>
                            </text>
                          </g>
                          <g
                            id="_26-27"
                            style="cursor: pointer;"
                            data-name="26"
                            transform="translate(1026.625 337.209)"
                            @click="clickTooth(26)"
                          >
                            <path
                              id="_26-28"
                              data-name="26"
                              d="M511.588,270.61c-4.361,9.44-15.534,14.817-25.272,16.729-3.644.717-6.751,1.374-10.455.717-7.349-1.374-14.757-7.946-16.908-15.056-.9-2.868-.358-5.8-1.016-8.663-1.494-6.99-2.927-13.263,1.494-19.716,2.27-3.346,6.751-3.585,10.157-5.019,9.38-4.122,19-7.767,29.574-5.855a19.235,19.235,0,0,1,6.572,2.748c3.644,2.091,7.05,4.541,8.842,8.424C517.921,252.149,514.754,263.74,511.588,270.61Z"
                              transform="translate(-456.465 -233.225)"
                              :fill="[
                                tooths.some((el) => el.id === 26)
                                  ? tooths.find((element) => element.id === 26)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-29"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(18.374 15.791)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-29"
                              data-name="26"
                              transform="translate(21.874 33.291)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >26</tspan>
                            </text>
                          </g>
                          <g
                            id="_27"
                            style="cursor: pointer;"
                            data-name="27"
                            transform="translate(1031.047 390.541)"
                            @click="clickTooth(27)"
                          >
                            <path
                              id="_27-2"
                              data-name="27"
                              d="M520.243,354.221c-1.434,4.242-2.688,8.962-5.915,12.248-6.99,7.05-18.7,8.006-28.08,6.214-11.77-2.211-16.43-10.4-19.9-20.851-1.075-3.226-2.928-6.99-2.39-10.455.538-3.644,3.405-7.707,5.736-10.455,5.5-6.393,17.207-6.99,24.914-7.827a53.339,53.339,0,0,1,6.93-.6,10.29,10.29,0,0,1,2.33.239c3.286.6,6.154,2.569,8.962,4.3,5.138,3.226,10.814,6.393,11.352,13.144C524.545,345.02,521.737,349.8,520.243,354.221Z"
                              transform="translate(-463.868 -322.49)"
                              :fill="[
                                tooths.some((el) => el.id === 27)
                                  ? tooths.find((element) => element.id === 27)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-30"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(17.953 13.459)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-30"
                              data-name="26"
                              transform="translate(21.453 30.959)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >27</tspan>
                            </text>
                          </g>
                          <g
                            id="_28"
                            style="cursor: pointer;"
                            data-name="28"
                            transform="translate(1033.379 441.84)"
                            @click="clickTooth(28)"
                          >
                            <path
                              id="_28-2"
                              data-name="28"
                              d="M518.049,437.955c-4.959,7.647-10.874,18.581-21.807,17.266-5.258-.657-11.949-2.031-16.43-4.9-8.3-5.377-15.295-20.015-10.455-29.693,5.736-11.471,20.911-13.323,32.262-11.829,2.091.3,4.242,0,6.273.6,5.736,1.673,13.98,8.364,15.115,14.518C523.964,429.292,520.857,433.713,518.049,437.955Z"
                              transform="translate(-467.767 -408.353)"
                              :fill="[
                                tooths.some((el) => el.id === 28)
                                  ? tooths.find((element) => element.id === 28)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-31"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(15.622 10.16)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-31"
                              data-name="26"
                              transform="translate(19.122 27.66)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >28</tspan>
                            </text>
                          </g>
                          <g
                            id="_41"
                            style="cursor: pointer;"
                            data-name="41"
                            transform="translate(891.377 748.908)"
                            @click="clickTooth(41)"
                          >
                            <path
                              id="_41-2"
                              data-name="41"
                              d="M264.059,953.614c.06,4-2.091,6.034-5.855,6.512a49.184,49.184,0,0,1-20.612-2.33c-2.39-.777-5.676-1.792-6.93-4.182-1.673-3.226.717-6.691,2.031-9.559,1.733-3.764,3.824-7.349,5.736-11.053,2.45-4.72,4.6-9.918,10.515-10.635,4.063-.478,6.452,2.629,8.185,5.855,2.569,4.959,3.405,9.858,4.66,15.235C262.565,946.8,263.939,950.148,264.059,953.614Z"
                              transform="translate(-230.088 -922.318)"
                              :fill="[
                                tooths.some((el) => el.id === 41)
                                  ? tooths.find((element) => element.id === 41)
                                    .color
                                  : '#FFF',
                              ]"
                              stroke="#7f7f7f"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <circle
                              id="Ellipse_9-32"
                              data-name="Ellipse 9"
                              cx="12"
                              cy="12"
                              r="12"
                              transform="translate(6.623 10.092)"
                              fill="none"
                              stroke="#a08ec2"
                              stroke-width="2"
                              opacity="0"
                            />
                            <text
                              id="_26-32"
                              data-name="26"
                              transform="translate(10.123 27.592)"
                              fill="#502e8a"
                              font-size="15"
                              font-family="Montserrat-Medium, Montserrat"
                              font-weight="500"
                            >
                              <tspan
                                x="0"
                                y="0"
                              >41</tspan>
                            </text>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>

                  <div class="col-6">
                    <table class="table table-bordered">
                      <thead class="thead-dark">
                        <tr style="text-align: center; color: #000;">
                          <th
                            scope="col"
                            style="color: #000;"
                          >
                            Dent Numéro
                          </th>
                          <th
                            scope="col"
                            style="color: #000;"
                          >
                            Code
                          </th>
                          <th
                            scope="col"
                            style="color: #000;"
                          >
                            Article
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(item, index) in values">
                          <tr :key="item.id">
                            <td style="text-align: center; color: #000;">
                              {{ index }}
                            </td>
                            <td style="text-align: center; color: #000;">
                              {{ item }}
                            </td>
                            <td style="text-align: center; color: #000;">
                              {{
                                optionsArticles.find((x) => x.value === item)
                                  .label
                              }}
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </v-form>
        </v-card-text>

        <v-card-text>
          <v-form
            ref="form"
            lazy-validation
          >
            <div class="modalForm">
              <Keypress
                key-event="keyup"
                :key-code="17"
                @success="consoleKeyPress"
              />
              <div class="modalFormInput tags">
                <v-text-field
                  v-model="editedItem.customer"
                  label="Patient"
                  :rules="[(v) => !!v || 'Champ requis']"
                  :disabled="isReadOnly"
                >
                  <v-icon
                    slot="prepend"
                    color="#1f6ed5"
                  >
                    mdi-account
                  </v-icon>
                </v-text-field>

                <div
                  style="
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    gap: 25px;
                    margin-top: -25px;
                  "
                >
                  <v-select
                    v-model="editedItem.sexe"
                    :items="sexePicker"
                    label="Sexe"
                    item-text="text"
                    item-value="value"
                    :disabled="isReadOnly"
                  >
                    <v-icon
                      slot="prepend"
                      color="dark grey"
                    >
                      mdi-gender-male-female
                    </v-icon>
                  </v-select>
                  <v-text-field
                    v-model="editedItem.age"
                    label="Age"
                    :disabled="isReadOnly"
                  />
                </div>

                <v-select
                  v-model="editedItem.praticien"
                  :items="praticienPicker"
                  label="Practicien"
                  item-text="name"
                  :disabled="isReadOnly"
                  item-value="name"
                  style="margin-top: -30px;"
                >
                  <v-icon
                    slot="prepend"
                    color="#27AE60"
                  >
                    mdi-account
                  </v-icon>
                </v-select>
                <DatePicker
                  :method="getEndDate"
                  :get-end-date="isEndDate"
                  :disabled="isReadOnly"
                />

                <v-text-field
                  v-model="editedItem.alert"
                  persistent-hint
                  return-object
                  single-line
                  class="alertInput"
                  label="Alerte"
                  color="red"
                  :disabled="isReadOnly || getDisabled()"
                >
                  <v-icon
                    slot="prepend"
                    color="red"
                  >
                    mdi-alert-circle-outline
                  </v-icon>
                </v-text-field>
              </div>
              <div
                class="modalFormInputTag"
                style="
                  background: #d1d5db52;
                  border-radius: 15px;
                  transition: background 0.8s ease 0s;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: relative;
                  margin-left: 25px;
                  margin-right: 25px;
                  flex-wrap: nowrap;
                  flex-direction: row;
                  align-content: center;
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="392.289"
                  height="644"
                  viewBox="0 0 392.289 644"
                >
                  <defs>
                    <filter
                      id="shadow"
                      x="0"
                      y="0"
                      width="392.289"
                      height="644"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset input="SourceAlpha" />
                      <feGaussianBlur
                        stdDeviation="10"
                        result="blur"
                      />
                      <feFlood flood-opacity="0.18" />
                      <feComposite
                        operator="in"
                        in2="blur"
                      />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g
                    id="dentures"
                    style="cursor: pointer;"
                    transform="translate(-728.846 -173.501)"
                  >
                    <g
                      id="_48"
                      style="cursor: pointer;"
                      data-name="48"
                      transform="translate(764.576 502.128)"
                      @click="clickTooth(48)"
                    >
                      <path
                        id="_48-2"
                        data-name="48"
                        d="M66.96,548.119c-2.39,6.871-9.38,10.754-16.251,11.591-8.544,1.016-22.285,1.016-28.678-5.616-6.691-6.93-3.824-21.269-1.792-29.574.836-3.166,1.912-6.273,4.481-8.543,5.8-5.138,13.084-5.556,20.313-6.512a26.1,26.1,0,0,1,4.361-.179c4.481.179,9.141,1.494,12.307,4.541,3.943,4.063,5.258,10.635,5.8,16.071S68.812,542.862,66.96,548.119Z"
                        transform="translate(-17.853 -509.262)"
                        :fill="[
                          tooths.some((el) => el.id === 48)
                            ? tooths.find((element) => element.id === 48).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(13.425 12.872)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26"
                        data-name="26"
                        transform="translate(14.925 30.372)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >48</tspan>
                      </text>
                    </g>
                    <g
                      id="_47"
                      style="cursor: pointer;"
                      data-name="47"
                      transform="translate(766.695 553.39)"
                      @click="clickTooth(47)"
                    >
                      <path
                        id="_47-2"
                        data-name="47"
                        d="M75.5,635.472c-2.689,5.8-9.5,9.858-15.713,10.694-9.977,1.314-25.631,2.868-33.756-4.481-2.39-2.151-3.644-5.5-4.242-8.6-.836-4.421-.119-9.021.3-13.443.6-5.974.956-11.77,5.736-16.071,6.512-5.855,16.012-7.229,24.2-8.3a18.114,18.114,0,0,1,7.169.358c13.5,3.465,17.326,15.892,17.326,28.5C76.513,627.825,77.111,632.007,75.5,635.472Z"
                        transform="translate(-21.4 -595.062)"
                        :fill="[
                          tooths.some((el) => el.id === 47)
                            ? tooths.find((element) => element.id === 47).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-2"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(15.305 15.61)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-2"
                        data-name="26"
                        transform="translate(17.805 33.11)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >47</tspan>
                      </text>
                    </g>
                    <g
                      id="_46"
                      style="cursor: pointer;"
                      data-name="46"
                      transform="translate(774.48 606.106)"
                      @click="clickTooth(46)"
                    >
                      <path
                        id="_46-2"
                        data-name="46"
                        d="M91.139,721.357c-2.509,10.157-11.113,14.458-19.955,18.282-2.987,1.255-5.676,3.286-8.962,3.7-6.811.836-16.37-1.912-20.313-7.707-2.509-3.7-2.928-7.827-4.3-11.949-1.494-4.541-3.585-9.44-3.107-14.339.358-3.7,2.39-5.915,3.644-9.26,1.016-2.808,1.075-5.915,2.33-8.663,2.689-6.034,8.364-7.169,14.219-7.946,1.255-.119,2.569-.179,3.824-.179a76.407,76.407,0,0,1,11.471,1.494c4.063.657,8.006,1.075,11.352,3.824,4.839,4,6.811,10.4,8.066,16.31C90.542,710.364,92.514,715.741,91.139,721.357Z"
                        transform="translate(-34.432 -683.3)"
                        :fill="[
                          tooths.some((el) => el.id === 46)
                            ? tooths.find((element) => element.id === 46).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-3"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(15.519 17.893)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-3"
                        data-name="26"
                        transform="translate(18.019 35.393)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >46</tspan>
                      </text>
                    </g>
                    <g
                      id="_45"
                      style="cursor: pointer;"
                      data-name="45"
                      transform="translate(792.83 661.956)"
                      @click="clickTooth(45)"
                    >
                      <path
                        id="_45-2"
                        data-name="45"
                        d="M111.448,798.838c-2.569,9.5-12.965,17.625-22.7,18.521-8.783.777-22.524-5.019-23.54-14.936-.6-5.974,3.166-12.546,6.811-17.027,4.9-5.974,13.921-7.886,21.209-8.543A17.224,17.224,0,0,1,111.448,798.838Z"
                        transform="translate(-65.143 -776.78)"
                        :fill="[
                          tooths.some((el) => el.id === 45)
                            ? tooths.find((element) => element.id === 45).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-4"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(11.17 8.044)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-4"
                        data-name="26"
                        transform="translate(13.67 25.544)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >45</tspan>
                      </text>
                    </g>
                    <g
                      id="_44"
                      style="cursor: pointer;"
                      data-name="44"
                      transform="translate(808.939 698.343)"
                      @click="clickTooth(44)"
                    >
                      <path
                        id="_44-2"
                        data-name="44"
                        d="M132.7,856.931c-2.868,8.663-8.3,17.983-18.1,19.357-10.754,1.494-24.017-8.065-22.345-19.656,1.494-10.157,11.172-17.326,20.851-18.7a20.438,20.438,0,0,1,2.748-.239c6.751-.179,15.354,2.091,17.864,8.484C135.148,849.7,133.834,853.526,132.7,856.931Z"
                        transform="translate(-92.107 -837.684)"
                        :fill="[
                          tooths.some((el) => el.id === 44)
                            ? tooths.find((element) => element.id === 44).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-5"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(9.061 6.657)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-5"
                        data-name="26"
                        transform="translate(10.561 24.157)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >44</tspan>
                      </text>
                    </g>
                    <g
                      id="_43"
                      style="cursor: pointer;"
                      data-name="43"
                      transform="translate(833.707 728.288)"
                      @click="clickTooth(43)"
                    >
                      <path
                        id="_43-2"
                        data-name="43"
                        d="M169.819,901.783c-1.434,10.037-5.556,23.838-17.684,24.615-12.188.836-22.345-14.7-17.207-25.511,4.66-9.858,14.219-12.188,24.077-13.024C167.788,887.085,170.894,894.494,169.819,901.783Z"
                        transform="translate(-133.562 -887.806)"
                        :fill="[
                          tooths.some((el) => el.id === 43)
                            ? tooths.find((element) => element.id === 43).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-6"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(6.293 6.712)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-6"
                        data-name="26"
                        transform="translate(8.793 24.212)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >43</tspan>
                      </text>
                    </g>
                    <g
                      id="_42"
                      style="cursor: pointer;"
                      data-name="42"
                      transform="translate(861.418 742.682)"
                      @click="clickTooth(42)"
                    >
                      <path
                        id="_42-2"
                        data-name="42"
                        d="M210.776,940.875c-1.016,4.421-2.808,8.065-7.647,8.842-6.691,1.016-12.726-3.883-17.923-7.349-2.39-1.613-5.2-3.346-5.258-6.572-.119-7.647,9.44-13.741,14.219-18.521,2.39-2.45,4.9-4.66,8.364-5.258a6.582,6.582,0,0,1,1.075-.119c5.138-.12,6.632,5.317,7.229,9.559C211.791,928.269,212.329,934.064,210.776,940.875Z"
                        transform="translate(-179.946 -911.897)"
                        :fill="[
                          tooths.some((el) => el.id === 42)
                            ? tooths.find((element) => element.id === 42).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-7"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(6.581 8.318)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-7"
                        data-name="26"
                        transform="translate(9.081 25.818)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >42</tspan>
                      </text>
                    </g>
                    <g
                      id="_31"
                      style="cursor: pointer;"
                      data-name="31"
                      transform="translate(925.156 748.43)"
                      @click="clickTooth(31)"
                    >
                      <path
                        id="_31-2"
                        data-name="31"
                        d="M320.025,952.814c-1.255,2.39-4.541,3.405-6.93,4.182a49.185,49.185,0,0,1-20.612,2.33c-3.764-.478-5.915-2.509-5.855-6.512.119-3.465,1.494-6.811,2.27-10.157,1.255-5.377,2.091-10.276,4.66-15.235,1.733-3.226,4.122-6.333,8.185-5.855,5.915.717,8.066,5.915,10.515,10.635,1.912,3.7,4,7.289,5.735,11.053C319.308,946.122,321.7,949.587,320.025,952.814Z"
                        transform="translate(-286.626 -921.518)"
                        :fill="[
                          tooths.some((el) => el.id === 31)
                            ? tooths.find((element) => element.id === 31).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-8"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(3.845 10.57)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-8"
                        data-name="26"
                        transform="translate(9.345 28.07)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >31</tspan>
                      </text>
                    </g>
                    <g
                      id="_32"
                      style="cursor: pointer;"
                      data-name="32"
                      transform="translate(957.275 742.204)"
                      @click="clickTooth(32)"
                    >
                      <path
                        id="_32-2"
                        data-name="32"
                        d="M372.2,935c-.06,3.226-2.868,4.959-5.258,6.572-5.2,3.465-11.232,8.364-17.923,7.349-4.839-.777-6.632-4.421-7.647-8.842-1.553-6.811-1.016-12.606-.06-19.417.6-4.242,2.091-9.679,7.229-9.559a6.583,6.583,0,0,1,1.075.12c3.465.6,5.974,2.808,8.364,5.258C362.757,921.255,372.316,927.349,372.2,935Z"
                        transform="translate(-340.389 -911.097)"
                        :fill="[
                          tooths.some((el) => el.id === 32)
                            ? tooths.find((element) => element.id === 32).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-9"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(1.724 8.796)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-9"
                        data-name="26"
                        transform="translate(5.224 26.296)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >32</tspan>
                      </text>
                    </g>
                    <g
                      id="_33"
                      style="cursor: pointer;"
                      data-name="33"
                      transform="translate(980.336 727.811)"
                      @click="clickTooth(33)"
                    >
                      <path
                        id="_33-2"
                        data-name="33"
                        d="M396.875,925.6c-12.128-.777-16.251-14.578-17.685-24.615-1.075-7.289,2.031-14.7,10.814-13.921,9.858.836,19.417,3.167,24.077,13.024C419.219,910.9,409.063,926.434,396.875,925.6Z"
                        transform="translate(-378.986 -887.006)"
                        :fill="[
                          tooths.some((el) => el.id === 33)
                            ? tooths.find((element) => element.id === 33).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-10"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(5.664 7.189)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-10"
                        data-name="26"
                        transform="translate(9.164 24.689)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >33</tspan>
                      </text>
                    </g>
                    <g
                      id="_34"
                      style="cursor: pointer;"
                      data-name="34"
                      transform="translate(999.328 697.865)"
                      @click="clickTooth(34)"
                    >
                      <path
                        id="_34-2"
                        data-name="34"
                        d="M430.523,875.489c-9.8-1.374-15.235-10.694-18.1-19.357-1.135-3.405-2.45-7.229-1.016-10.754,2.509-6.393,11.113-8.663,17.864-8.484a20.429,20.429,0,0,1,2.748.239c9.679,1.374,19.357,8.543,20.851,18.7C454.54,867.423,441.277,876.982,430.523,875.489Z"
                        transform="translate(-410.776 -836.884)"
                        :fill="[
                          tooths.some((el) => el.id === 34)
                            ? tooths.find((element) => element.id === 34).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-11"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(9.671 7.135)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-11"
                        data-name="26"
                        transform="translate(12.171 24.635)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >34</tspan>
                      </text>
                    </g>
                    <g
                      id="_35"
                      style="cursor: pointer;"
                      data-name="35"
                      transform="translate(1010.695 661.479)"
                      @click="clickTooth(35)"
                    >
                      <path
                        id="_35-2"
                        data-name="35"
                        d="M476.718,801.623c-1.016,9.918-14.757,15.713-23.54,14.936-9.738-.9-20.134-9.022-22.7-18.521A17.224,17.224,0,0,1,448.7,776.052c7.289.657,16.31,2.569,21.209,8.544C473.552,789.076,477.315,795.648,476.718,801.623Z"
                        transform="translate(-429.804 -775.98)"
                        :fill="[
                          tooths.some((el) => el.id === 35)
                            ? tooths.find((element) => element.id === 35).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-12"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(11.304 8.522)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-12"
                        data-name="26"
                        transform="translate(14.804 26.022)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >35</tspan>
                      </text>
                    </g>
                    <g
                      id="_36"
                      style="cursor: pointer;"
                      data-name="36"
                      transform="translate(1018.846 605.629)"
                      @click="clickTooth(36)"
                    >
                      <path
                        id="_36-2"
                        data-name="36"
                        d="M497.443,722.888c-1.374,4.122-1.792,8.245-4.3,11.949-3.943,5.8-13.5,8.543-20.313,7.707-3.286-.418-5.974-2.45-8.962-3.7-8.842-3.824-17.445-8.125-19.955-18.282-1.374-5.616.6-10.993,1.733-16.43,1.255-5.915,3.226-12.307,8.066-16.31,3.346-2.748,7.289-3.167,11.352-3.824a76.383,76.383,0,0,1,11.471-1.494c1.255,0,2.569.06,3.824.179,5.855.777,11.531,1.912,14.219,7.946,1.255,2.748,1.314,5.855,2.33,8.663,1.255,3.346,3.286,5.556,3.644,9.26C501.028,713.448,498.937,718.347,497.443,722.888Z"
                        transform="translate(-443.443 -682.5)"
                        :fill="[
                          tooths.some((el) => el.id === 36)
                            ? tooths.find((element) => element.id === 36).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-13"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(17.155 18.371)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-13"
                        data-name="26"
                        transform="translate(20.655 35.871)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >36</tspan>
                      </text>
                    </g>
                    <g
                      id="_37"
                      style="cursor: pointer;"
                      data-name="37"
                      transform="translate(1028.584 552.911)"
                      @click="clickTooth(37)"
                    >
                      <path
                        id="_37-2"
                        data-name="37"
                        d="M514.581,632.283c-.6,3.107-1.852,6.452-4.242,8.6-8.125,7.349-23.778,5.8-33.756,4.481-6.213-.836-13.024-4.9-15.713-10.694-1.613-3.465-1.016-7.647-1.016-11.352,0-12.606,3.824-25.033,17.326-28.5a18.114,18.114,0,0,1,7.169-.359c8.185,1.075,17.685,2.45,24.2,8.3,4.78,4.3,5.138,10.1,5.736,16.071C514.7,623.261,515.418,627.861,514.581,632.283Z"
                        transform="translate(-459.747 -594.262)"
                        :fill="[
                          tooths.some((el) => el.id === 37)
                            ? tooths.find((element) => element.id === 37).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-14"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(15.415 16.089)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-14"
                        data-name="26"
                        transform="translate(18.915 33.589)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >37</tspan>
                      </text>
                    </g>
                    <g
                      id="_38"
                      style="cursor: pointer;"
                      data-name="38"
                      transform="translate(1035.652 501.65)"
                      @click="clickTooth(38)"
                    >
                      <path
                        id="_38-2"
                        data-name="38"
                        d="M517.67,553.294c-6.393,6.632-20.134,6.632-28.678,5.616-6.871-.836-13.861-4.72-16.251-11.591-1.852-5.258-1.075-12.785-.538-18.222s1.852-12.009,5.8-16.071c3.166-3.047,7.827-4.361,12.307-4.541a26.1,26.1,0,0,1,4.361.179c7.229.956,14.518,1.374,20.313,6.512,2.569,2.27,3.644,5.377,4.481,8.544C521.493,532.024,524.361,546.363,517.67,553.294Z"
                        transform="translate(-471.574 -508.462)"
                        :fill="[
                          tooths.some((el) => el.id === 38)
                            ? tooths.find((element) => element.id === 38).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-15"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(13.348 13.35)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-15"
                        data-name="26"
                        transform="translate(16.848 30.85)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >38</tspan>
                      </text>
                    </g>
                    <g
                      id="_18"
                      style="cursor: pointer;"
                      data-name="18"
                      transform="translate(761.709 442.317)"
                      @click="clickTooth(18)"
                    >
                      <path
                        id="_18-2"
                        data-name="18"
                        d="M56.425,451.122c-4.481,2.868-11.172,4.242-16.43,4.9-10.933,1.314-16.848-9.619-21.807-17.266-2.808-4.242-5.915-8.663-4.959-14.04,1.135-6.154,9.38-12.845,15.115-14.518,2.031-.6,4.182-.3,6.273-.6,11.352-1.494,26.527.358,32.262,11.83C71.719,431.108,64.729,445.745,56.425,451.122Z"
                        transform="translate(-13.052 -409.153)"
                        :fill="[
                          tooths.some((el) => el.id === 18)
                            ? tooths.find((element) => element.id === 18).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-16"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(16.291 9.682)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-16"
                        data-name="26"
                        transform="translate(20.791 27.182)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >18</tspan>
                      </text>
                    </g>
                    <g
                      id="_17"
                      style="cursor: pointer;"
                      data-name="17"
                      transform="translate(759.105 391.019)"
                      @click="clickTooth(17)"
                    >
                      <path
                        id="_17-2"
                        data-name="17"
                        d="M66.56,352.631c-3.465,10.455-8.125,18.64-19.9,20.851-9.38,1.792-21.09.836-28.08-6.213-3.226-3.286-4.481-8.006-5.915-12.248-1.494-4.421-4.3-9.2-3.943-14.04.538-6.751,6.213-9.918,11.352-13.144,2.808-1.733,5.676-3.7,8.962-4.3a10.29,10.29,0,0,1,2.33-.239,53.324,53.324,0,0,1,6.93.6c7.707.836,19.417,1.434,24.914,7.827,2.33,2.748,5.2,6.811,5.736,10.455C69.488,345.641,67.635,349.4,66.56,352.631Z"
                        transform="translate(-8.696 -323.29)"
                        :fill="[
                          tooths.some((el) => el.id === 17)
                            ? tooths.find((element) => element.id === 17).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-17"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(17.894 12.981)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-17"
                        data-name="26"
                        transform="translate(22.394 30.481)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >17</tspan>
                      </text>
                    </g>
                    <g
                      id="_16"
                      style="cursor: pointer;"
                      data-name="16"
                      transform="translate(764.34 337.688)"
                      @click="clickTooth(16)"
                    >
                      <path
                        id="_16-2"
                        data-name="16"
                        d="M75.524,265.137c-.657,2.868-.119,5.8-1.016,8.663-2.151,7.11-9.559,13.682-16.908,15.056-3.7.657-6.811,0-10.455-.717-9.738-1.912-20.911-7.289-25.272-16.729-3.166-6.871-6.333-18.461-2.987-25.69,1.792-3.883,5.2-6.333,8.842-8.424a19.237,19.237,0,0,1,6.572-2.748c10.575-1.912,20.194,1.733,29.574,5.855,3.405,1.434,7.886,1.673,10.157,5.019C78.451,251.874,77.017,258.147,75.524,265.137Z"
                        transform="translate(-17.457 -234.025)"
                        :fill="[
                          tooths.some((el) => el.id === 16)
                            ? tooths.find((element) => element.id === 16).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-18"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(17.661 15.312)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-18"
                        data-name="26"
                        transform="translate(22.16 32.812)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >16</tspan>
                      </text>
                    </g>
                    <g
                      id="_15"
                      style="cursor: pointer;"
                      data-name="15"
                      transform="translate(777.354 302.822)"
                      @click="clickTooth(15)"
                    >
                      <path
                        id="_15-2"
                        data-name="15"
                        d="M69.715,211.893c-6.99.538-18.7-1.255-23.778-6.572-1.852-1.852-1.494-3.824-2.509-5.915-1.374-2.868-3.764-4.66-4.122-8.066-1.016-9.738,10.276-15.115,18.461-15.653,3.525-.239,6.811,1.912,9.858,3.286,2.151.956,4.481,1.255,6.572,2.27A25.237,25.237,0,0,1,85.727,193.97C89.491,203.469,78.079,211.3,69.715,211.893Z"
                        transform="translate(-39.241 -175.669)"
                        :fill="[
                          tooths.some((el) => el.id === 15)
                            ? tooths.find((element) => element.id === 15).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-19"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(11.646 6.177)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-19"
                        data-name="26"
                        transform="translate(17.146 23.677)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >15</tspan>
                      </text>
                    </g>
                    <g
                      id="_14"
                      style="cursor: pointer;"
                      data-name="14"
                      transform="translate(792.035 267.585)"
                      @click="clickTooth(14)"
                    >
                      <path
                        id="_14-2"
                        data-name="14"
                        d="M105.3,149.794a11.791,11.791,0,0,1-4.541,4.361c-2.868,1.494-6.452,2.031-9.559,2.689-6.393,1.314-11.053-1.075-16.729-3.644-2.45-1.135-5.5-2.031-7.11-4.3-2.211-3.047-1.494-5.915-2.33-9.26-1.075-4.182-2.091-7.767,0-11.949,2.211-4.421,5.736-4.481,9.44-6.811,2.27-1.434,3.764-3.465,6.572-4.063,5.258-1.135,11.113,5.676,14.7,8.723,1.852,1.673,4.122,2.868,5.915,4.6C107.029,135.515,109.3,142.923,105.3,149.794Z"
                        transform="translate(-63.812 -116.689)"
                        :fill="[
                          tooths.some((el) => el.id === 14)
                            ? tooths.find((element) => element.id === 14).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-20"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(8.966 9.415)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-20"
                        data-name="26"
                        transform="translate(13.466 26.915)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >14</tspan>
                      </text>
                    </g>
                    <g
                      id="_13"
                      style="cursor: pointer;"
                      data-name="13"
                      transform="translate(812.984 234.313)"
                      @click="clickTooth(13)"
                    >
                      <path
                        id="_13-2"
                        data-name="13"
                        d="M133.213,107.721c-2.868.418-6.154-.478-8.962-.956-10.157-1.673-20.97-5.975-24.794-16.549-1.553-4.3.358-7.707,1.673-11.65,1.075-3.107,1.314-7.11,3.226-9.858,2.569-3.7,7.767-4.481,11.77-5.8A32.871,32.871,0,0,1,122.4,61.06a6.493,6.493,0,0,1,1.075-.06c5.2,0,8.663,4.3,10.993,8.544a66.059,66.059,0,0,1,7.767,24.5C143.071,100.85,140.561,106.7,133.213,107.721Z"
                        transform="translate(-98.876 -61)"
                        :fill="[
                          tooths.some((el) => el.id === 13)
                            ? tooths.find((element) => element.id === 13).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-21"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(9.016 11.687)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-21"
                        data-name="26"
                        transform="translate(14.516 29.187)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >13</tspan>
                      </text>
                    </g>
                    <g
                      id="_12"
                      style="cursor: pointer;"
                      data-name="12"
                      transform="translate(845.875 216.477)"
                      @click="clickTooth(12)"
                    >
                      <path
                        id="_12-2"
                        data-name="12"
                        d="M186.621,64.873c-.956,3.824-2.151,8.245-6.632,9.081-6.154,1.195-10.157-2.748-14.339-6.632-5.377-4.959-13.024-11.889-11.531-20.313.418-2.33,2.569-4.063,4.063-5.676A34.188,34.188,0,0,1,177.062,31.3c5.258-.956,8.125,2.689,9.38,7.289A54.359,54.359,0,0,1,186.621,64.873Z"
                        transform="translate(-153.93 -31.143)"
                        :fill="[
                          tooths.some((el) => el.id === 12)
                            ? tooths.find((element) => element.id === 12).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-22"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(7.124 8.524)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-22"
                        data-name="26"
                        transform="translate(12.624 26.024)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >12</tspan>
                      </text>
                    </g>
                    <g
                      id="_11"
                      style="cursor: pointer;"
                      data-name="11"
                      transform="translate(878.869 203.48)"
                      @click="clickTooth(11)"
                    >
                      <path
                        id="_11-2"
                        data-name="11"
                        d="M253.9,25.527c-.9,3.644-2.031,7.169-3.167,10.754-2.629,8.424-4,22.046-14.936,23.48-6.93.956-10.933-8.066-14.936-12.128-8.544-8.663-17.684-21.628-6.572-32.262a16.544,16.544,0,0,1,5.975-3.405,45.1,45.1,0,0,1,10.455-2.45c1.733-.179,3.585.179,5.377.179s4.122-.239,6.452-.3c3.943-.06,8.006.3,10.1,2.987C255.453,16.028,254.915,21.345,253.9,25.527Z"
                        transform="translate(-209.155 -9.39)"
                        :fill="[
                          tooths.some((el) => el.id === 11)
                            ? tooths.find((element) => element.id === 11).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-23"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(11.13 8.52)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-23"
                        data-name="26"
                        transform="translate(17.63 26.02)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >11</tspan>
                      </text>
                    </g>
                    <g
                      id="_21"
                      style="cursor: pointer;"
                      data-name="21"
                      transform="translate(926.068 203.001)"
                      @click="clickTooth(21)"
                    >
                      <path
                        id="_21-2"
                        data-name="21"
                        d="M322.015,46.833c-4,4.063-8.006,13.084-14.936,12.128-10.933-1.434-12.307-15.056-14.936-23.48-1.135-3.585-2.27-7.11-3.166-10.754-1.016-4.182-1.553-9.5,1.255-13.144,2.091-2.689,6.154-3.047,10.1-2.987,2.33.06,4.66.3,6.452.3s3.644-.358,5.377-.179a45.1,45.1,0,0,1,10.455,2.45,16.544,16.544,0,0,1,5.975,3.405C339.7,25.205,330.558,38.17,322.015,46.833Z"
                        transform="translate(-288.156 -8.59)"
                        :fill="[
                          tooths.some((el) => el.id === 21)
                            ? tooths.find((element) => element.id === 21).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-24"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(9.932 8.999)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-24"
                        data-name="26"
                        transform="translate(14.432 26.499)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >21</tspan>
                      </text>
                    </g>
                    <g
                      id="_22"
                      style="cursor: pointer;"
                      data-name="22"
                      transform="translate(970.41 215.998)"
                      @click="clickTooth(22)"
                    >
                      <path
                        id="_22-2"
                        data-name="22"
                        d="M384.871,66.523c-4.182,3.883-8.185,7.827-14.339,6.632-4.481-.836-5.676-5.258-6.632-9.081a54.359,54.359,0,0,1,.179-26.288c1.255-4.6,4.122-8.245,9.38-7.289a34.188,34.188,0,0,1,18.879,10.037c1.494,1.613,3.644,3.346,4.063,5.676C397.9,54.634,390.248,61.564,384.871,66.523Z"
                        transform="translate(-362.376 -30.343)"
                        :fill="[
                          tooths.some((el) => el.id === 22)
                            ? tooths.find((element) => element.id === 22).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-25"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(3.589 9.002)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-25"
                        data-name="26"
                        transform="translate(7.089 26.502)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >22</tspan>
                      </text>
                    </g>
                    <g
                      id="_23"
                      style="cursor: pointer;"
                      data-name="23"
                      transform="translate(994.008 233.836)"
                      @click="clickTooth(23)"
                    >
                      <path
                        id="_23-2"
                        data-name="23"
                        d="M444.8,89.415c-3.824,10.575-14.638,14.876-24.794,16.549-2.808.478-6.094,1.374-8.962.956-7.349-1.016-9.858-6.871-9.021-13.682a66.058,66.058,0,0,1,7.767-24.5c2.33-4.242,5.8-8.544,10.993-8.544a6.493,6.493,0,0,1,1.075.06,32.87,32.87,0,0,1,6.273,1.852c4,1.314,9.2,2.091,11.77,5.8,1.912,2.748,2.151,6.751,3.226,9.858C444.443,81.708,446.355,85.114,444.8,89.415Z"
                        transform="translate(-401.87 -60.2)"
                        :fill="[
                          tooths.some((el) => el.id === 23)
                            ? tooths.find((element) => element.id === 23).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-26"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(8.993 12.164)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_23-3"
                        data-name="23"
                        transform="translate(12.493 29.664)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >23</tspan>
                      </text>
                    </g>
                    <g
                      id="_24"
                      style="cursor: pointer;"
                      data-name="24"
                      transform="translate(1014.949 267.107)"
                      @click="clickTooth(24)"
                    >
                      <path
                        id="_24-2"
                        data-name="24"
                        d="M479.226,138.837c-.836,3.346-.119,6.213-2.33,9.26-1.613,2.27-4.66,3.166-7.11,4.3-5.676,2.569-10.336,4.959-16.729,3.644-3.107-.657-6.691-1.195-9.559-2.689a11.791,11.791,0,0,1-4.541-4.361c-4-6.871-1.733-14.279,3.644-19.656,1.792-1.733,4.063-2.927,5.915-4.6,3.585-3.047,9.44-9.858,14.7-8.723,2.808.6,4.3,2.629,6.572,4.063,3.7,2.33,7.229,2.39,9.44,6.811C481.317,131.071,480.3,134.655,479.226,138.837Z"
                        transform="translate(-436.924 -115.889)"
                        :fill="[
                          tooths.some((el) => el.id === 24)
                            ? tooths.find((element) => element.id === 24).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-27"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(10.05 9.893)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_24-3"
                        data-name="24"
                        transform="translate(13.55 27.393)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >24</tspan>
                      </text>
                    </g>
                    <g
                      id="_25"
                      style="cursor: pointer;"
                      data-name="25"
                      transform="translate(1025.924 302.345)"
                      @click="clickTooth(25)"
                    >
                      <path
                        id="_25-2"
                        data-name="25"
                        d="M502.452,190.541c-.359,3.405-2.748,5.2-4.122,8.066-1.016,2.091-.657,4.063-2.509,5.915-5.078,5.317-16.788,7.11-23.778,6.572-8.364-.6-19.776-8.424-16.012-17.923a25.237,25.237,0,0,1,11.531-12.726c2.091-1.016,4.421-1.314,6.572-2.27,3.047-1.374,6.333-3.525,9.858-3.286C492.175,175.425,503.467,180.8,502.452,190.541Z"
                        transform="translate(-455.29 -174.869)"
                        :fill="[
                          tooths.some((el) => el.id === 25)
                            ? tooths.find((element) => element.id === 25).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-28"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(12.077 6.655)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-26"
                        data-name="26"
                        transform="translate(15.577 24.155)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >25</tspan>
                      </text>
                    </g>
                    <g
                      id="_26-27"
                      style="cursor: pointer;"
                      data-name="26"
                      transform="translate(1026.625 337.209)"
                      @click="clickTooth(26)"
                    >
                      <path
                        id="_26-28"
                        data-name="26"
                        d="M511.588,270.61c-4.361,9.44-15.534,14.817-25.272,16.729-3.644.717-6.751,1.374-10.455.717-7.349-1.374-14.757-7.946-16.908-15.056-.9-2.868-.358-5.8-1.016-8.663-1.494-6.99-2.927-13.263,1.494-19.716,2.27-3.346,6.751-3.585,10.157-5.019,9.38-4.122,19-7.767,29.574-5.855a19.235,19.235,0,0,1,6.572,2.748c3.644,2.091,7.05,4.541,8.842,8.424C517.921,252.149,514.754,263.74,511.588,270.61Z"
                        transform="translate(-456.465 -233.225)"
                        :fill="[
                          tooths.some((el) => el.id === 26)
                            ? tooths.find((element) => element.id === 26).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-29"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(18.374 15.791)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-29"
                        data-name="26"
                        transform="translate(21.874 33.291)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >26</tspan>
                      </text>
                    </g>
                    <g
                      id="_27"
                      style="cursor: pointer;"
                      data-name="27"
                      transform="translate(1031.047 390.541)"
                      @click="clickTooth(27)"
                    >
                      <path
                        id="_27-2"
                        data-name="27"
                        d="M520.243,354.221c-1.434,4.242-2.688,8.962-5.915,12.248-6.99,7.05-18.7,8.006-28.08,6.214-11.77-2.211-16.43-10.4-19.9-20.851-1.075-3.226-2.928-6.99-2.39-10.455.538-3.644,3.405-7.707,5.736-10.455,5.5-6.393,17.207-6.99,24.914-7.827a53.339,53.339,0,0,1,6.93-.6,10.29,10.29,0,0,1,2.33.239c3.286.6,6.154,2.569,8.962,4.3,5.138,3.226,10.814,6.393,11.352,13.144C524.545,345.02,521.737,349.8,520.243,354.221Z"
                        transform="translate(-463.868 -322.49)"
                        :fill="[
                          tooths.some((el) => el.id === 27)
                            ? tooths.find((element) => element.id === 27).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-30"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(17.953 13.459)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-30"
                        data-name="26"
                        transform="translate(21.453 30.959)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >27</tspan>
                      </text>
                    </g>
                    <g
                      id="_28"
                      style="cursor: pointer;"
                      data-name="28"
                      transform="translate(1033.379 441.84)"
                      @click="clickTooth(28)"
                    >
                      <path
                        id="_28-2"
                        data-name="28"
                        d="M518.049,437.955c-4.959,7.647-10.874,18.581-21.807,17.266-5.258-.657-11.949-2.031-16.43-4.9-8.3-5.377-15.295-20.015-10.455-29.693,5.736-11.471,20.911-13.323,32.262-11.829,2.091.3,4.242,0,6.273.6,5.736,1.673,13.98,8.364,15.115,14.518C523.964,429.292,520.857,433.713,518.049,437.955Z"
                        transform="translate(-467.767 -408.353)"
                        :fill="[
                          tooths.some((el) => el.id === 28)
                            ? tooths.find((element) => element.id === 28).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-31"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(15.622 10.16)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-31"
                        data-name="26"
                        transform="translate(19.122 27.66)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >28</tspan>
                      </text>
                    </g>
                    <g
                      id="_41"
                      style="cursor: pointer;"
                      data-name="41"
                      transform="translate(891.377 748.908)"
                      @click="clickTooth(41)"
                    >
                      <path
                        id="_41-2"
                        data-name="41"
                        d="M264.059,953.614c.06,4-2.091,6.034-5.855,6.512a49.184,49.184,0,0,1-20.612-2.33c-2.39-.777-5.676-1.792-6.93-4.182-1.673-3.226.717-6.691,2.031-9.559,1.733-3.764,3.824-7.349,5.736-11.053,2.45-4.72,4.6-9.918,10.515-10.635,4.063-.478,6.452,2.629,8.185,5.855,2.569,4.959,3.405,9.858,4.66,15.235C262.565,946.8,263.939,950.148,264.059,953.614Z"
                        transform="translate(-230.088 -922.318)"
                        :fill="[
                          tooths.some((el) => el.id === 41)
                            ? tooths.find((element) => element.id === 41).color
                            : '#FFF',
                        ]"
                        stroke="#7f7f7f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_9-32"
                        data-name="Ellipse 9"
                        cx="12"
                        cy="12"
                        r="12"
                        transform="translate(6.623 10.092)"
                        fill="none"
                        stroke="#a08ec2"
                        stroke-width="2"
                        opacity="0"
                      />
                      <text
                        id="_26-32"
                        data-name="26"
                        transform="translate(10.123 27.592)"
                        fill="#502e8a"
                        font-size="15"
                        font-family="Montserrat-Medium, Montserrat"
                        font-weight="500"
                      >
                        <tspan
                          x="0"
                          y="0"
                        >41</tspan>
                      </text>
                    </g>
                  </g>
                </svg>
              </div>
              <div
                class="modalFormInput"
                style="max-height: 73vh; overflow: scroll;"
              >
                <FormulateForm
                  class="login-form"
                  :disabled="isReadOnly"
                  @submit="submitHandler"
                >
                  <div
                    v-if="schema.length !== 0"
                    class="header-tooth"
                  >
                    Numéro de dent / Article / Code
                  </div>
                  <div
                    v-for="item in schema"
                    :key="item.id"
                  >
                    <div class="double-wide">
                      <p
                        v-if="item.hasOwnProperty('grouped')"
                        class="tooth-label"
                      >
                        {{ item.label.join() }}
                      </p>

                      <p
                        v-if="!item.hasOwnProperty('grouped')"
                        class="tooth-label"
                      >
                        {{ item.id }}
                      </p>
                      <div
                        v-if="item.hasOwnProperty('grouped')"
                        style="display: flex; flex-direction: row; width: 100%;"
                      >
                        <FormulateInput
                          v-model="values[item.label.join()]"
                          :name="item.label.join()"
                          type="vue-select"
                          :options="optionsCode"
                          placeholder="Code"
                          :disabled="isReadOnly"
                          :clearable="false"
                        />
                        <FormulateInput
                          v-model="values[item.label.join()]"
                          style="width: calc(80% - 0.5em);"
                          :name="item.label.join()"
                          type="vue-select"
                          :options="optionsArticles"
                          :disabled="isReadOnly"
                          placeholder="Article"
                          :clearable="false"
                        />
                      </div>

                      <div
                        v-if="!item.hasOwnProperty('grouped')"
                        style="display: flex; flex-direction: row; width: 100%;"
                      >
                        <FormulateInput
                          v-model="values[item.name]"
                          :name="item.name"
                          type="vue-select"
                          :disabled="isReadOnly"
                          :options="optionsCode"
                          placeholder="Code"
                        />
                        <FormulateInput
                          v-model="values[item.name]"
                          style="width: calc(80% - 0.5em);"
                          :name="item.name"
                          type="vue-select"
                          :disabled="isReadOnly"
                          :options="optionsArticles"
                          placeholder="Article"
                        />
                      </div>
                    </div>
                  </div>
                </FormulateForm>
              </div>
            </div>
            <v-textarea
              v-model="editedItem.comment"
              outlined
              name="input-7-4"
              label="Commentaires"
              color="blue"
              :disabled="isReadOnly && !isOperator"
              prepend-icon="mdi-message-reply-text"
            />
            <div class="addFile">
              <v-icon
                medium
                style="margin: 5px;"
                :disabled="isReadOnly && !isOperator"
              >
                mdi-paperclip
              </v-icon>
              <label class="custom-file-upload">
                <image-uploader
                  v-if="isReadOnly && !isOperator"
                  :preview="false"
                  :class-name="['fileinput', { 'fileinput--loaded': hasImage }]"
                  capture="environment"
                  :debug="1"
                  accept="*"
                  do-not-resize="gif"
                  :auto-rotate="true"
                  :disabled="isReadOnly && !isOperator"
                  output-format="file"
                  @input="setImage"
                >
                  <label
                    slot="upload-label"
                    for="fileInput"
                    :disabled="isReadOnly && !isOperator"
                  >
                    Cliquez pour sélectionner vos fichiers
                  </label>
                </image-uploader>
              </label>
            </div>
            <div>
              <div
                v-for="file in editedTask.files"
                :key="file.name"
                class="fileCard"
              >
                <v-icon
                  medium
                  color="#3498DB40"
                >
                  mdi-file
                </v-icon>
                <h2 class="uploadFileTitle">
                  {{ file.name }}
                </h2>
                <div class="uploadFiles">
                  <v-icon
                    medium
                    class="downloadIcon"
                    color="green"
                    :disabled="isReadOnly && !isOperator"
                    @click="downloadFile(file)"
                  >
                    mdi-download
                  </v-icon>
                  <v-icon
                    medium
                    class="deleteIcon"
                    color="red"
                    :disabled="isReadOnly && !isOperator"
                    @click="removeImage(index, file.name)"
                  >
                    mdi-delete
                  </v-icon>
                </div>
              </div>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions class="saveBtnCard">
          <div :style="deleteFileModal" />
          <v-btn
            class="greenBtn"
            color="green darken-1"
            text
            :loading="isLoadingSave"
            :disabled="isReadOnly && !isOperator"
            @click="save"
          >
            Sauvegarder
          </v-btn>
          <v-btn
            v-if="!is - operator"
            :class="editedItem.sentInvoiceIt ? 'orangeBtn' : 'blueBtn'"
            color="black darken-1"
            text
            :loading="isLoadingSave"
            :disabled="schema.length === 0 || isReadOnly"
            @click="exportToInvoiceIt"
          >
            {{
              editedItem.sentInvoiceIt
                ? "Re-Facturer"
                : "Sauvegarder / Facturereeee"
            }}
          </v-btn>
          <v-btn
            class="yellowBtn"
            color="black darken-1"
            text
            :loading="isLoadingSave"
            @click="print"
          >
            Imprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DatePicker from "../components/DatePicker";
import project from "../../repository/projectRepository.js";
import operator from "../../repository/operatorRepository.js";
import tags from "../../repository/tagsRepository.js";
import moment from "moment";
import praticien from "../../repository/praticienRepository.js";
import aws from "../../repository/awsRepository";
import laboRepo from "../../repository/laboRepository";
import materialRepository from "../../repository/materialRepository.js";
import ImageUploader from "vue-image-upload-resize";
import articleRepository from "../../repository/articleRepository.js";
import adminRepository from "../../repository/adminRepository";
import "vue-select/dist/vue-select.css";
import Keypress from "vue-keypress";
import print from "vue-print-nb";
import axios from "axios";

export default {
  directives: {
    print,
  },
  components: {
    DatePicker,
    ImageUploader,
    Keypress,
  },
  props: {
    task: Object,
    isReadOnly: Boolean,
    isOperator: Boolean,
    isArchive: Boolean
  },
  data() {
    return {
      isPrinting: false,
      isNextClickGrouped: false,
      activeClass: "active",
      colors: [
        "#1572A1",
        "#9AD0EC",
        "#EFDAD7",
        "#E3BEC6",
        "#F0ECE3",
        "#DFD3C3",
        "#C7B198",
        "#e74c3c39",
      ],
      dialog2: false,
      tooths: [],
      schema: [],
      values: {},
      optionsCode: [],
      optionsArticles: [],
      casTest: [],
      isEndDate: null,
      msg: "Vue Image Upload and Resize Demo",
      hasImage: false,
      image: null,
      endDate: new Date().toISOString().substr(0, 10),
      enDate: "",
      dialog: false,
      tags: [],
      labo: [],
      fileArray: [],
      dialog3: false,
      isLoadingSave: false,
      desserts: [],
      isLoadingDownload: [true],
      images: [],
      dialogDelete: false,
      changeFile: false,
      editedItem: {
        customer: "",
        operator: "",
        tag: "",
        endDate: "",
        alert: "",
        material: "",
        comment: "",
        files: [],
        startDate: "",
        praticien: "",
        article: "",
        articleNumber: "",
        teinte: "",
        cas: "",
        sentInvoiceIt: false,
      },
      editedTask: {
        customer: "",
        operator: "",
        tag: "",
        endDate: "",
        alert: "",
        material: "",
        comment: "",
        files: [],
        startDate: "",
        praticien: "",
        article: "",
        articleNumber: "",
        teinte: "",
      },
      defaultTask: {
        customer: "",
        operator: "",
        tag: "",
        endDate: "",
        alert: "",
        material: "",
        comment: "",
        files: [],
        startDate: "",
        praticien: "",
        article: "",
        articleNumber: "",
        teinte: "",
      },
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 7500,
        visible: false,
      },
      changeTag: false,
      tagLabel: "Tags",
      opeLabel: "Opérateur",
      praticienLabel: "Praticien",
      matLabel: "Matériaux",
      tagPicker: [],
      allArticles: [],
      praticienPicker: [],
      articlePicker: [],
      matPicker: [],
      opePicker: [],
      opePickerTri: [],
    };
  },
  computed: {
    formTitle() {
      if (this.isArchive) {
        return "Visualiser le cas archivé  " + this.task.cas;
      }
      if(this.isReadOnly) {
        return "Visualiser le cas " + this.task.cas;
      }
      if (this.task) {
        return "Modifier cas " + this.task.cas;
      }
      return "Modifier cas";
    },
    icon() {
      return this.tasks === -1 ? " mdi-account-plus " : " mdi-pencil-outline ";
    },
    deleteFileModal() {
      return this.tasks === -1 ? "display: none" : "";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  async mounted() {
    this.isPrinting = false;
    this.isNextClickGrouped = false;
    this.dialog2 = false;
    this.tooths = [];
    this.schema = [];
    this.values = {};
    this.optionsCode = [];
    this.optionsArticles = [];
    this.casTest = [];
    this.editedItem = [];
    //
    const operators = await this.getAllOperators();
    this.operators = operators.data;
    this.operators.forEach((operator) => {
      var name = operator.firstName + " " + operator.lastName;
      this.opePicker.push(name);
    });
    const praticiens = await this.getPraticien();
    this.praticiens = praticiens.data;
    this.praticiens.forEach((praticien) => {
      const name = praticien.firstName + " " + praticien.lastName;
      this.praticienPicker.push(name);
    });
    const tags = await this.getCurrentTag();
    this.tags = tags.data[0];
    this.tags.tag.forEach((tag) => {
      this.tagPicker.push(tag.name);
    });
    const materials = await this.getCurrentMaterial();
    this.materials = materials.data[0];
    this.materials.materials.forEach((material) => {
      if (material.visible == true) {
        this.matPicker.push(material.name);
      }
    });
    this.operators.forEach((op) => {
      this.opePickerTri.push(op.firstName + " " + op.lastName);
    });
    const article = await this.getCurrentArticle();
    this.article = article.data[0];
    this.article.articles.forEach((article) => {
      if (article.visible == true) {
        this.articlePicker.push(article.name);
      }
    });

    if (article.data.length > 0) {
      const optionsCode_2 = [];
      const optionsArticle_2 = [];
      const combinedArray = [];
      article.data.forEach((element) => {
        element.articles.forEach((artcl) => {
          if (
            artcl.visible === true &&
            Object.prototype.hasOwnProperty.call(artcl, "code")
          ) {
            combinedArray.push(artcl);
          }
        });
      });
      //
      const uniques = this.lodash.uniq(combinedArray);
      const uniques2 = this.lodash.uniq(this.lodash.map(uniques, "code"));
      //
      uniques2.forEach((element) => {
        const lab = uniques.find((x) => x.code === element).name;
        optionsCode_2.push({
          value: element,
          label: element,
        });
        optionsArticle_2.push({
          value: element,
          label: lab,
        });
      });
      this.optionsCode = optionsCode_2;
      this.optionsArticles = optionsArticle_2;
    }
  },
  methods: {
    async routineOpenModal() {
      const article = await this.getCurrentArticle();
      this.article = article.data[0];
      this.article.articles.forEach((article) => {
        if (article.visible == true) {
          this.articlePicker.push(article.name);
        }
      });

          if (article.data.length > 0) {
      const optionsCode_2 = [];
      const optionsArticle_2 = [];
      const combinedArray = [];
      article.data.forEach((element) => {
        element.articles.forEach((artcl) => {
          if (
            artcl.visible === true &&
            Object.prototype.hasOwnProperty.call(artcl, "code")
          ) {
            combinedArray.push(artcl);
          }
        });
      });
      //
      const uniques = this.lodash.uniq(combinedArray);
      const uniques2 = this.lodash.uniq(this.lodash.map(uniques, "code"));
      //
      this.allArticles = uniques;
      //
      uniques2.forEach((element) => {
        const lab = uniques.find((x) => x.code === element).name;
        optionsCode_2.push({
          value: element,
          label: element,
        });
        optionsArticle_2.push({
          value: element,
          label: lab,
        });
      });
      this.optionsCode = optionsCode_2;
      this.optionsArticles = optionsArticle_2;
    }

      if (article.data.length > 0) {
        const optionsCode_2 = [];
        const optionsArticle_2 = [];
        const combinedArray = [];
        article.data.forEach((element) => {
          element.articles.forEach((artcl) => {
            if (
              artcl.visible === true &&
              Object.prototype.hasOwnProperty.call(artcl, "code")
            ) {
              combinedArray.push(artcl);
            }
          });
        });
        //
        const uniques = this.lodash.uniq(combinedArray);
        const uniques2 = this.lodash.uniq(this.lodash.map(uniques, "code"));
        //
        uniques2.forEach((element) => {
          const lab = uniques.find((x) => x.code === element).name;
          optionsCode_2.push({
            value: element,
            label: element,
          });
          optionsArticle_2.push({
            value: element,
            label: lab,
          });
        });
        this.optionsCode = optionsCode_2;
        this.optionsArticles = optionsArticle_2;
      }
      this.dialog = true;
    },
    labelItem(item) {
      if (!this.lodash.isEmpty(this.optionsArticles)) {
        const findThis = this.optionsArticles.find((x) => x.value === item);
        if (findThis) {
          if (this.lodash.has(findThis, "label")) {
            return findThis.label;
          } else {
            return "";
          }
        } else {
          return "";
        }
      }
    },
    async print() {
      // Pass the element id here
      await this.$htmlToPaper("printMe");
    },
    getDisabled() {
      if (this.editedIndex === -1) {
        return false;
      }
      return true;
    },
    async exportToInvoiceIt() {
      const copyOptionsArticles = this.lodash.cloneDeep(this.optionsArticles);
      const { data } = await laboRepo.getLaboById();
      const copyValues = this.lodash.cloneDeep(this.values);
      this.editedItem.sentInvoiceIt = true;
      const savedProject = await this.save({ isExportInvoiceIt: true });
      const teethsJSON = [];
      //
      if (copyValues) {
        Object.entries(copyValues).forEach(([key, value]) => {
          if (key.includes(",")) {
            key.split(",").map((element) => {
              teethsJSON.push({
                Number: parseInt(element),
                ItemName: copyOptionsArticles.find((x) => x.value === value)
                  .label,
                Item: value,
              });
            });
          } else {
            teethsJSON.push({
              Number: parseInt(key),
              ItemName:
                copyOptionsArticles.find((x) => x.value === value).label || "",
              Item: value,
            });
          }
        });
      } else {
        savedProject.teeths.forEach((element) => {
          if (element.id.length <= 1) {
            element.id
              .join()
              .toString()
              .split(",")
              .map((x) => {
                teethsJSON.push({
                  Number: parseInt(x),
                  ItemName: element.article,
                  Item: element.code,
                });
              });
          } else {
            element.id.map((x) => {
              teethsJSON.push({
                Number: parseInt(x),
                ItemName: element.article,
                Item: element.code,
              });
            });
          }
        });
      }
      //
      let projectJSON = {
        Project: {
          DateTime: new Date().toLocaleString("en-US"),
          DueDate: new Date().toLocaleString("en-US"),
          CaseId: savedProject.cas,
          Teeth: teethsJSON,
          Patient: {
            PatientId: savedProject.cas,
            PatientFirstName: savedProject.customer,
            PatientName: savedProject.customer,
          },
          Practice: {
            PracticeId: savedProject.laboId,
            PracticeName: savedProject.praticien,
          },
        },
      };
      //
      const url = "https://invoicit.app/api/project";
      //
      const params = new URLSearchParams();
      params.append("email", data.invoiceItEmail);
      params.append("password", data.invoiceItPassword);
      params.append("project", JSON.stringify(projectJSON));
      //
      const config = {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      };
      //
      await axios
        .post(url, params, config)
        .then(function () {
          this.close();
          //
        })
        .catch(function () {
          this.SnackbarShow(
            "Une erreur est survenue lors du transfert vers InvoiceIt",
            500
          );
        });
    },
    consoleKeyPress: function () {
      this.isNextClickGrouped = true;
    },
    randomColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    },
    async submitHandler(data) {
      alert(`ORDER, ${JSON.stringify(data)}`);
    },
    handleRemovalGrouped: function (prop) {
      let final = [];
      let idxOfGrouped;
      this.schema.forEach((o, index) => {
        if (this.lodash.has(o, "label")) {
          o.label.forEach((l) => {
            if (l === prop) {
              idxOfGrouped = index;
            }
          });
        }
      });
      //
      const aLabels = [];
      const aTeeths = [];
      this.schema[idxOfGrouped].label.forEach((l) => {
        if (l !== prop) {
          aLabels.push(l);
          aTeeths.push({ id: l, name: l });
        }
      });
      delete this.values[this.schema[idxOfGrouped].label.join()];

      const pulled = this.lodash.remove(this.schema, function (n, i) {
        return i !== idxOfGrouped;
      });
      const pulledTooth = this.lodash.remove(this.tooths, function (n) {
        return n.id !== prop;
      });
      if (!this.lodash.isEmpty(pulled)) {
        final = [...pulled];
        if (!this.lodash.isEmpty(aTeeths)) {
          final.push({ grouped: true, label: aLabels, teeths: aTeeths });
        }
      } else {
        if (!this.lodash.isEmpty(aTeeths)) {
          final.push({ grouped: true, label: aLabels, teeths: aTeeths });
        }
      }

      return { schema: final, tooths: pulledTooth };
    },
    handleCheckIfGrouped: function (prop) {
      let check = false;
      this.schema.forEach((o) => {
        if (this.lodash.has(o, "label")) {
          o.label.forEach((l) => {
            if (l === prop) {
              check = true;
            }
          });
        }
      });

      return check;
    },
    handleRemovalOne: function (prop) {
      delete this.values[prop];

      let idxOne;
      this.schema.forEach((o, index) => {
        if (this.lodash.has(o, "id")) {
          if (o.id === prop) {
            idxOne = index;
          }
        }
      });
      const pulled = this.lodash.remove(this.schema, function (n, i) {
        return i !== idxOne;
      });
      const pulledTooth = this.lodash.remove(this.tooths, function (n) {
        return n.id !== prop;
      });

      return { schema: pulled, tooths: pulledTooth };
    },
    clickTooth: async function (prop) {
      if (!this.isReadOnly) {
        if (this.tooths.some((el) => el.id === prop)) {
          if (this.handleCheckIfGrouped(prop)) {
            const { schema, tooths } = this.handleRemovalGrouped(prop);
            this.schema = schema;
            this.tooths = tooths;
          } else {
            const { schema, tooths } = this.handleRemovalOne(prop);
            this.schema = schema;
            this.tooths = tooths;
          }
        } else {
          this.tooths.push({ id: prop, color: this.randomColor() });
          if (this.isNextClickGrouped === true) {
            const lastItemAdded = this.lodash.last(this.schema);

            const removedFromLastItem = this.schema.filter(
              (item) => item.id !== lastItemAdded.id
            );
            //

            if (this.lodash.has(lastItemAdded, "grouped")) {
              removedFromLastItem.push({
                grouped: true,
                label: [prop, ...lastItemAdded.label],
                teeths: [{ id: prop, name: prop }, ...lastItemAdded.teeths],
              });
            } else {
              removedFromLastItem.push({
                grouped: true,
                label: [lastItemAdded.id, prop],
                teeths: [lastItemAdded, { id: prop, name: prop }],
              });
            }
            this.schema = removedFromLastItem;
          } else {
            this.schema.push({ id: prop, name: prop });
          }
        }

        this.isNextClickGrouped = false;
      }
    },
    setImage: function (output) {
      this.changeFile = true;
      this.hasImage = true;
      this.image = output;
      if (this.image.dataUrl) {
        this.fileArray.push({
          name: this.image.info.name,
          content: this.image.info,
        });
        this.editedTask.files.push({
          name: this.image.info.name,
          content: {
            lastModified: this.image.info.modifiedTimestamp,
            lastModifiedDate: this.image.info.modifiedDate,
            name: this.image.info.name,
            size: 178,
            type: this.image.info.type,
            webkitRelativePath: "",
          },
        });
      } else {
        this.fileArray.push({ name: this.image.name, content: this.image });
        this.editedTask.files.push({
          name: this.image.name,
          content: this.image,
        });
      }
    },
    SnackbarShow(text, status, isOk) {
      if (status === isOk) {
        this.snackbar = {
          color: "success",
          icon: "mdi-check-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: text,
          visible: true,
        };
      } else {
        this.snackbar = {
          color: "error",
          icon: "mdi-alert-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: "Une erreur est survenue",
          visible: true,
        };
      }
    },
    format(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getTagColor(tagName) {
      if (tagName && tagName !== "" && !this.lodash.isEmpty(this.tags.tag)) {
        const color = this.tags.tag.filter((x) => x.name == tagName);
        if (color && color.length > 0) return color[0].color;
      }
    },

    getTagTextColor(tagName) {
      if (tagName && tagName !== "" && !this.lodash.isEmpty(this.tags.tag)) {
        const color = this.tags.tag.filter((tag) => tag.name == tagName);
        if (color.length > 0) return color[0].colorText;
      }
    },
    getEndDate(date) {
      this.enDate = date;
    },
    getUserStatus() {
      if (this.$store.state.user.status === "admin") {
        return false;
      }
      return true;
    },
    close() {
      this.$refs.form.resetValidation();
      this.dialog = false;
      this.fileArray = [];
      this.$nextTick(() => {
        this.editedTask = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.isEndDate = this.formatDate(new Date().toISOString().substr(0, 10));
      this.enDate = "";
      this.isPrinting = false;
      this.isNextClickGrouped = false;
      this.dialog2 = false;
      this.tooths = [];
      this.schema = [];
      this.values = {};
      this.optionsCode = [];
      this.optionsArticles = [];
      this.casTest = [];
    },
    async getAllProjects() {
      this.projects = await project.getProjects();
      this.desserts = this.projects.data;

      return this.desserts;
    },
    getEndDatePicker() {
      if (this.editedItem.endDate) {
        this.isEndDate = this.editedItem.endDate;
      } else {
        this.isEndDate = null;
      }
      return this.isEndDate;
    },
    async editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = item;
      this.editedItem.files = Object.assign([]);
      this.editedItem.teeths = item.teeths || Object.assign([]);
      this.editedItem.oldFiles = Object.assign([]);
      this.fileArray = Object.assign([]);
      await aws.findAwsFiles("CAS" + this.editedItem.cas).then((response) => {
        let files = response.data;
        files.forEach((a) => {
          this.editedItem.files.push({ name: a, content: "" });
          this.editedItem.oldFiles.push({ name: a });
          this.fileArray.push({ name: a, content: "" });
        });
      });
      this.dialog = true;
      this.getEndDatePicker();
      //
      const restoreSchema = [];
      const restoreTooth = [];
      const restoreValues = {};
      //
      if (item.teeths) {
        item.teeths.forEach((element) => {
          element.id.forEach((ids) => {
            const t = [];
            if (ids.length > 1) {
              ids.map((itm) => {
                t.push({ id: itm, name: itm });
                restoreTooth.push({ id: itm, color: this.randomColor() });
              });
              restoreValues[ids.toString().split(",").map(Number)] =
                element.code;
              restoreSchema.push({
                grouped: true,
                label: ids.toString().split(",").map(Number),
                teeths: t,
              });
            } else {
              t.push({ id: ids, name: ids });
              restoreTooth.push({ id: ids, color: this.randomColor() });
              restoreValues[ids] = element.code;
              restoreSchema.push({
                id: ids,
                name: ids,
              });
            }
          });
          this.schema = restoreSchema;
          this.tooths = restoreTooth;
          this.values = restoreValues;
          this.routineOpenModal();
        });
      }
    },
    async getlabo() {
      const laboId = this.$store.state.user.laboId;
      this.labo = await laboRepo.getLaboById(laboId);
    },
    getCASNumber() {
      if (this.desserts.length == 0) {
        this.editedItem.cas = "001";
      } else {
        const lastCAS = this.desserts.length - 1;
        const CASNumber = parseInt(this.desserts[lastCAS].cas) + 1;
        if (CASNumber < 10) {
          this.editedItem.cas = "00" + CASNumber;
        } else if (CASNumber < 100) {
          this.editedItem.cas = "0" + CASNumber;
        } else {
          this.editedItem.cas = CASNumber;
        }
      }
    },
    async save(isSaveAndExport) {
      const teethOrder = [];
      const tasks = [];
      let postProject;
      let copyEditedItem;
      //
      const isValuesEmpty = this.lodash.isEmpty(this.values);
      //
      if (isValuesEmpty === false) {
        this.lodash.each(this.values, (val, key) => {
          let label;
          //
          if (key.includes(",")) {
            label = key.split(",").map(Number);
          } else {
            label = parseInt(key);
          }
          teethOrder.push({
            label: "tooth-" + label,
            id: [label],
            code: val,
            article:
              this.optionsArticles.find((element) => element.value === val)
                .label || "Not found",
          });
          //
          const findCorrespondingArticle = this.allArticles.find(
            (o) => o.code === val
          );
          tasks.push({ teethNbr: label, tasks: findCorrespondingArticle });
        });
      } else {
        this.lodash.each(this.editedItem.teeths, (val) => {
          let label;
          //
          if (val.id.includes(",")) {
            label = val.id.split(",").map(Number);
          } else {
            label = parseInt(val.id);
          }
          teethOrder.push({
            label: "tooth-" + label,
            id: [label],
            code: val.code,
            article:
              this.optionsArticles.find((element) => element.value === val.code)
                .label || "Not found",
          });
          //
          const findCorrespondingArticle = this.allArticles.find(
            (o) => o.code === val.code
          );
          tasks.push({ teethNbr: label, tasks: findCorrespondingArticle });
        });
      }
      copyEditedItem = this.lodash.cloneDeep(this.editedItem);
      this.editedItem.teeths = teethOrder;

      if (isSaveAndExport === true) this.editedItem.sentInvoiceIt = true;
      if (isSaveAndExport === true) copyEditedItem.sentInvoiceIt = true;

      if (this.$refs.form.validate() && this.editedItem.endDate != "") {
          if (this.changeTag) {
            this.editedItem.operator = "";
          }

          this.isLoadingSave = true;
          this.editedItem.files.forEach(async (file, index, array) => {
            const cas = "CAS" + this.editedItem.cas;
            const name = file.name;
            const content = file.content;
            const request = await aws.postAwsFile(cas, name, content);
            if (index === array.length - 1) {
              if (request.status === 200) {
                this.isLoadingSave = false;
                this.fileArray = [];
                this.changeFile = false;
              } else {
                this.snackbar = {
                  color: "error",
                  icon: "mdi-alert-circle-outline",
                  mode: "multi-line",
                  position: "top",
                  timeout: 5000,
                  text: "Une erreur est survenue",
                  visible: true,
                };
              }
            }
          });

          this.editedItem.teeths = teethOrder;
          this.editedItem.tasks = tasks;
          try {
            postProject = await project.putProject(this.editedItem);
          } catch (error) {
            this.snackbar = {
              color: "error",
              icon: "mdi-alert-circle-outline",
              mode: "multi-line",
              position: "top",
              timeout: 5000,
              text: "Une erreur est survenue",
              visible: true,
            };
          }
          //
          this.changeTag = false;

        this.close();
        this.$refs.form.resetValidation();
        this.editedItem.endDate == "";
        this.isEndDate = this.formatDate(
          new Date().toISOString().substr(0, 10)
        );
        this.enDate = "";
        this.getAllProjects();
        if (postProject && Object.prototype.hasOwnProperty.call(postProject, "status") && postProject.status === 204) {
          return copyEditedItem;
        } else {
          return postProject.data;
        }
      }
    },
    deleteCAS() {
      this.dialogDelete = true;
    },
        formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    isChangeTag(event) {
      if (event) {
        this.changeTag = true;
      }
    },
    async getFiles(task) {
      await aws.findAwsFiles("CAS" + task.cas).then((response) => {
        let files = response.data;
        files.forEach((a) => {
          this.fileArray.push({ name: a.Name, content: "" });
        });
      });
    },
    async deleteItemConfirm(task) {
      await project.deleteProject(task);
      this.closeDelete();
      this.dialog = false;
      this.getAllProjects();
    },
    async downloadFile(file) {
      const cas = "CAS" + this.editedTask.cas;
      const fileToDownload = await aws.findAwsUniqueFiles(cas, file.name);
      var a = document.createElement("a");
      a.href = "data:image/png;base64," + fileToDownload.data;
      a.download = file.name;
      a.click();
    },
    async removeImage(index, name) {
      this.editedTask.files.splice(index, 1);
      this.fileArray.splice(index, 1);
      await aws.deleteAwsFile("CAS" + this.editedTask.cas, name);
      this.getAllProjects();
    },
    async getAllOperators() {
      const adminOperateur = await adminRepository.getAdmins();
      const operatorData = await operator.getOperators();
      adminOperateur.data.forEach((admin) => {
        operatorData.data.push(admin);
      });
      return operatorData;
    },
    async getCurrentTag() {
      return await tags.getTags();
    },
    async getCurrentMaterial() {
      return await materialRepository.getMaterials();
    },
    async getCurrentArticle() {
      return await articleRepository.getArticles();
    },
    async getPraticien() {
      return await praticien.getPraticiens();
    },
    async getAdmins() {
      return await adminRepository.getAdmins();
    },
  },
};
</script>

<style>
.vs__clear {
  display: none;
}
</style>

<style scoped>
#_1
  #_2
  #_3
  #_4
  #_5
  #_6
  #_7
  #_8
  #_9
  #_10
  #_11
  #_12
  #_13
  #_14
  #_15
  #_16
  #_17
  #_18
  #_19
  #_20
  #_21
  #_22
  #printMe {
  display: none;
  visibility: hidden;
}

.print {
  display: block;
  visibility: visible;
}

.v-application p {
  margin-bottom: 0px !important;
}

.vs__dropdown-option--highlight {
  background: #003b5b !important;
  color: #fff;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__dropdown-option--highlight {
  background: #003b5b !important;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}

.vs__clear {
  display: none !important;
}
.v-select .dropdown-toggle .clear {
  display: none;
}

.vs__search::-webkit-search-cancel-button {
  display: none !important;
}

.custom-file-upload:active {
  border: 1px solid #1f6ed5;
}
.custom-file-upload:hover {
  border: 1px solid #000000;
}

.v-application ol,
.v-application ul {
  padding-left: 0px !important;
}
</style>

<style scoped>
.uploadFiles {
  display: flex;
}

.v-application p {
  margin-bottom: 0px !important;
}

.v-application ol,
.v-application ul {
  padding-left: 0px !important;
}

.header-tooth {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: medium;
}

.vs__selected-options {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-wrap: nowrap !important;
  padding: 0 2px;
  position: relative;
}

.img1 {
  display: block;
}
.img1:hover {
  filter: invert(35%) sepia(0%) saturate(1630%) hue-rotate(155deg)
    brightness(105%) contrast(120%);
}
.img1:active {
  filter: invert(35%) sepia(0%) saturate(1630%) hue-rotate(155deg)
    brightness(105%) contrast(120%);
}
.active {
  fill: blue;
}

.double-wide {
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid lightgray;
  padding-top: 10px;
}

.percent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.v-data-footer__select {
  display: none !important;
}
.v-data-footer__select {
  display: none !important;
}
.v-application--is-ltr .v-data-footer__pagination {
  display: none !important;
}
header {
  background: #fafafa !important;
  height: 80px !important;
}
input[type="file"] {
  display: none;
}
.alert {
  width: 300px;
  float: right;
}
.v-data-table > .v-data-table__wrapper > table {
  box-shadow: 0px 0px 10px 0px rgb(35 94 231 / 20%) !important;
}
.custom-file-upload {
  border: 1px solid #9e9e9e !important;
  display: inline-block !important;
  padding: 6px 12px !important;
  cursor: pointer !important;
  width: 100% !important;
  height: 60px !important;
  border-radius: 5px !important;
}

.svg-container {
  width: auto;
  height: 33rem;
}

.form-title {
  margin-top: 0;
}
.formulate-input[data-classification="button"] button {
  background: #003b5b !important;
  border-color: #003b5b !important;
}
.vs__dropdown-option--highlight {
  background: #003b5b !important;
  color: #fff;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__dropdown-option--highlight {
  background: #003b5b !important;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}

.custom-file-upload:active {
  border: 1px solid #1f6ed5;
}
.custom-file-upload:hover {
  border: 1px solid #000000;
}
.addFile {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ope {
  display: flex;
  align-items: center !important;
  justify-content: center;
  flex-direction: row;
}
.elevation-1 {
  box-shadow: none !important;
}
.search {
  width: 198px;
  height: 36px;
}
.modalIcon {
  color: #1f6ed5;
  background: #3498db40 0% 0% no-repeat padding-box;
  padding: 5px;
  border-radius: 50px;
}
.user-avatar {
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}
.barre {
  background: #7474741d 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  width: 80px;
  height: 4px;
  margin-right: 10px;
}
.fileCard {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 0px 10px 0px rgb(35 94 231 / 20%);
  margin-left: 30px;
  height: 60px;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  margin: 20px 0px 20px 30px;
  align-items: center;
}
.test {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  max-width: 300px;
}
.modal {
  display: flex;
  justify-content: space-between;
}

.tags {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.v-text-field {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.modal2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v-select__slot {
  margin-top: 7px !important;
}
.modalTitle {
  display: flex;
  justify-content: space-between;
}
.orangeDate {
  color: #f39c12;
}
.greenDate {
  color: #27ae60;
}
.redDate {
  color: red;
}
.modalForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.modalFormInputTag {
  width: 33.33%;
  padding: 0px !important;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modalFormInput {
  width: 33.33%;
  padding: 10px;
  margin-bottom: 20px;
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  border-color: #1f6ed5 !important;
}
.v-application--is-ltr .v-text-field--outlined fieldset {
  border-color: #1f6ed5 !important;
}
.v-text-field--outlined fieldset {
  border-color: #1f6ed5 !important;
}
.v-label .v-label--active .theme--light {
  color: #1f6ed5;
}
.saveBtnCard {
  display: flex;
  justify-content: center;
  align-items: center;
}
.saveBtn {
  background: aliceblue;
  margin: 20px;
}
.deleteBtn {
  background: #e74c3c39;
  margin: 20px;
}
.deleteIcon {
  background: #e74c3c39;
  padding: 10px;
  border-radius: 50px;
}
.downloadIcon {
  background: #1abc9c40;
  padding: 10px;
  margin-right: 20px;
  border-radius: 50px;
}
.greenBtn {
  margin: 20px;
  background: #1abc9c40;
}

.yellowBtn {
  margin: 20px;
  color: black;
  background: #f39c1240;
}

.blueBtn {
  margin: 20px;
  color: black;
  background: #5341f840;
}
.tagInput {
  background: #f39c1240;
  border-radius: 50px;
  padding: 10px 15px 10px 15px;
  height: 60px;
  width: 300px;
  margin: 10px;
  display: flex;
  align-items: center;
}
.matInput {
  background: #1abc9c40;
  border-radius: 50px;
  padding: 10px 15px 10px 15px;
  height: 60px;
  width: 300px;
  margin: 10px;
  display: flex;
  align-items: center;
}
.alertInput {
  background: #e74c3c39;
  border-radius: 50px;
  padding: 10px 15px 10px 15px;
  height: 60px;
  width: 300px;
  margin: 10px;
  display: flex;
  align-items: center;
}
.tooth-label {
  font-weight: bold;
  text-align: left;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 10px;
}
.login-form {
  padding: 0em;
  display: flex;
  flex-direction: column;
  max-width: 900px;
  box-sizing: border-box;
  width: calc(100%);
}

.formulate-input[data-classification="select"] select:focus {
  outline: 0;
  border: 1px solid #003b5b !important;
}
.login-form::v-deep .formulate-input .formulate-input-element {
  max-width: none;
}
@media (min-width: 420px) {
  .double-wide {
    display: flex;
  }
  .double-wide .formulate-input {
    flex-grow: 1;
    width: calc(50% - 0.5em);
  }
  .double-wide .formulate-input:first-child {
    margin-right: 0.5em;
  }
  .double-wide .formulate-input:last-child {
    margin-left: 0.5em;
  }
}
.addCasBtn {
  margin-bottom: 0px !important;
  margin-top: 4px !important;
  height: 40px !important;
}
@media screen and (max-width: 768px) {
  .fileCard {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 10px 0px rgb(35 94 231 / 20%);
    margin-left: 30px;
    height: 200px;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    margin: 20px 0px 20px 30px;
    align-items: center;
  }

  .uploadFileTitle {
    width: 200px;
    overflow: hidden;
  }
  .search {
    display: none !important;
  }
  .v-toolbar__title {
    display: none !important;
  }
  .modalForm {
    flex-direction: column;
  }
  .modalFormInput {
    width: 100%;
  }
  .modalFormInputTag {
    width: 100%;
    align-items: center;
  }
  .saveBtnCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse !important;
  }
}

.uploadFiles {
  display: flex;
}
.percent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.v-data-footer__select {
  display: none !important;
}
.v-data-footer__select {
  display: none !important;
}
.v-application--is-ltr .v-data-footer__pagination {
  display: none !important;
}
header {
  background: #fafafa !important;
  height: 80px !important;
}
input[type="file"] {
  display: none;
}
.alert {
  width: 300px;
  float: right;
}
.v-data-table > .v-data-table__wrapper > table {
  box-shadow: 0px 0px 10px 0px rgb(35 94 231 / 20%) !important;
}
.custom-file-upload {
  border: 1px solid #9e9e9e !important;
  display: inline-block !important;
  padding: 6px 12px !important;
  cursor: pointer !important;
  width: 100% !important;
  height: 60px !important;
  border-radius: 5px !important;
}
.custom-file-upload:active {
  border: 1px solid #1f6ed5;
}
.custom-file-upload:hover {
  border: 1px solid #000000;
}
.addFile {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ope {
  display: flex;
  align-items: center !important;
  justify-content: center;
  flex-direction: row;
}
.elevation-1 {
  box-shadow: none !important;
}
.search {
  width: 198px;
  height: 36px;
}
.modalIcon {
  color: #1f6ed5;
  background: #3498db40 0% 0% no-repeat padding-box;
  padding: 5px;
  border-radius: 50px;
}
.user-avatar {
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}
.barre {
  background: #7474741d 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  width: 80px;
  height: 4px;
  margin-right: 10px;
}
.fileCard {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 0px 10px 0px rgb(35 94 231 / 20%);
  margin-left: 30px;
  height: 60px;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  margin: 20px 0px 20px 30px;
  align-items: center;
}
.test {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  max-width: 300px;
}
.modal {
  display: flex;
  justify-content: space-between;
}

.modal2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v-select__slot {
  margin-top: 7px !important;
}
.modalTitle {
  display: flex;
  justify-content: space-between;
}
.orangeDate {
  color: #f39c12;
}
.greenDate {
  color: #27ae60;
}
.redDate {
  color: red;
}
.modalForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.modalFormInputTag {
  width: 40%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.modalFormInput {
  width: 40%;
  padding: 30px;
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  border-color: #1f6ed5 !important;
}
.v-application--is-ltr .v-text-field--outlined fieldset {
  border-color: #1f6ed5 !important;
}
.v-text-field--outlined fieldset {
  border-color: #1f6ed5 !important;
}
.v-label .v-label--active .theme--light {
  color: #1f6ed5;
}
.saveBtnCard {
  display: flex;
  justify-content: center;
  align-items: center;
}
.saveBtn {
  background: aliceblue;
  margin: 20px;
}
.deleteBtn {
  background: #e74c3c39;
  margin: 20px;
}
.deleteIcon {
  background: #e74c3c39;
  padding: 10px;
  border-radius: 50px;
}
.downloadIcon {
  background: #1abc9c40;
  padding: 10px;
  margin-right: 20px;
  border-radius: 50px;
}
.greenBtn {
  margin: 20px;
  background: #1abc9c40;
}
.tagInput {
  background: #f39c1240;
  border-radius: 50px;
  padding: 10px 15px 10px 15px;
  height: 60px;
  width: 300px;
  margin: 10px;
  display: flex;
  align-items: center;
}
.matInput {
  background: #1abc9c40;
  border-radius: 50px;
  padding: 10px 15px 10px 15px;
  height: 60px;
  width: 300px;
  margin: 10px;
  display: flex;
  align-items: center;
}
.alertInput {
  background: #e74c3c39;
  border-radius: 50px;
  padding: 10px 15px 10px 15px;
  height: 60px;
  width: 300px;
  margin: 10px;
  display: flex;
  align-items: center;
}
.addCasBtn {
  margin-bottom: 0px !important;
  margin-top: 4px !important;
  height: 40px !important;
}
@media screen and (max-width: 768px) {
  .fileCard {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 10px 0px rgb(35 94 231 / 20%);
    margin-left: 30px;
    height: 200px;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    margin: 20px 0px 20px 30px;
    align-items: center;
  }
  .uploadFileTitle {
    width: 200px;
    overflow: hidden;
  }
  .search {
    display: none !important;
  }
  .v-toolbar__title {
    display: none !important;
  }
  .modalForm {
    flex-direction: column;
  }
  .modalFormInput {
    width: 100%;
  }
  .modalFormInputTag {
    width: 100%;
    align-items: center;
  }
  .saveBtnCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse !important;
  }
}
</style>

<style>
.uploadFiles {
  display: flex;
}

.orangeBtn {
  margin: 20px;
  color: black;
  background: rgb(231, 153, 8);
}
.tagUsinage {
  color: #3498db;
  background: #3498db40 0% 0% no-repeat padding-box;
}
.tagScannage {
  color: #f39c12;
  background: #f39c1240 0% 0% no-repeat padding-box;
}
.customer {
  color: #747474;
}
.modal {
  display: flex;
  justify-content: space-between;
}

.modal2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalTitle {
  display: flex;
  justify-content: space-between;
  background-color: white;
}
.modalIcon {
  color: #1f6ed5 !important;
  background: #3498db40 0% 0% no-repeat padding-box;
  padding: 5px;
  border-radius: 50px;
}
.custom-file-upload {
  border: 1px solid #9e9e9e !important;
  display: inline-block !important;
  padding: 6px 12px !important;
  cursor: pointer !important;
  width: 100% !important;
  height: 60px !important;
  border-radius: 5px !important;
}
.custom-file-upload:active {
  border: 1px solid #1f6ed5;
}
.custom-file-upload:hover {
  border: 1px solid #000000;
}
.addFile {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ope {
  display: flex;
  align-items: center !important;
  justify-content: center;
  flex-direction: row;
}
.percent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
header {
  background: #fafafa !important;
  height: 80px !important;
}
input[type="file"] {
  display: none;
}
.alert {
  width: 300px;
  float: right;
}
.v-data-table > .v-data-table__wrapper > table {
  box-shadow: 0px 0px 10px 0px rgb(35 94 231 / 20%) !important;
}
.elevation-1 {
  box-shadow: none !important;
}
.barre {
  background: #7474741d 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  width: 80px;
  height: 4px;
  margin-right: 10px;
}
.fileCard {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 0px 10px 0px rgb(35 94 231 / 20%);
  margin-left: 30px;
  height: 60px;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  margin: 20px 0px 20px 30px;
  align-items: center;
}
.modalForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.modalFormInputTag {
  width: 40%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.modalFormInput {
  width: 40%;
  padding: 30px;
}
.saveBtnCard {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.saveBtn {
  background: aliceblue;
  margin: 20px;
}
.deleteBtn {
  background: #e74c3c39;
  margin: 20px;
}
.deleteIcon {
  background: #e74c3c39;
  padding: 10px;
  border-radius: 50px;
}
.downloadIcon {
  background: #1abc9c40;
  padding: 10px;
  margin-right: 20px;
  border-radius: 50px;
}
.greenBtn {
  margin: 20px;
  background: #1abc9c40;
}
.tagInput {
  background: #f39c1240;
  border-radius: 50px;
  padding: 15px 15px 10px 15px;
  height: 40px;
  width: 300px;
  margin: 10px;
}
.matInput {
  background: #1abc9c40;
  border-radius: 50px;
  padding: 15px 15px 10px 15px;
  height: 40px;
  width: 300px;
  margin: 10px;
}
.alertInput {
  background: #e74c3c39;
  border-radius: 50px;
  padding: 15px 15px 10px 15px;
  height: 40px;
  width: 300px;
  margin: 10px;
}
@media screen and (max-width: 768px) {
  .fileCard {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 10px 0px rgb(35 94 231 / 20%);
    margin-left: 30px;
    height: 200px;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    margin: 20px 0px 20px 30px;
    align-items: center;
  }
  .uploadFileTitle {
    width: 200px;
    overflow: hidden;
  }
  .tagInput {
    background: #f39c1240;
    border-radius: 50px;
    padding: 10px 15px 10px 15px;
    height: 60px;
    width: 300px;
    margin: 10px;
  }
  .matInput {
    background: #1abc9c40;
    border-radius: 50px;
    padding: 10px 15px 10px 15px;
    height: 60px;
    width: 300px;
    margin: 10px;
  }
  .alertInput {
    background: #e74c3c39;
    border-radius: 50px;
    padding: 10px 15px 10px 15px;
    height: 60px;
    width: 300px;
    margin: 10px;
  }
  .search {
    display: none !important;
  }
  .v-toolbar__title {
    display: none !important;
  }
  .modalForm {
    flex-direction: column;
  }
  .modalFormInput {
    width: 100%;
  }
  .modalFormInputTag {
    width: 100%;
    align-items: center;
  }
  .saveBtnCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse !important;
  }
}
</style>
