<template>
  <div>
    <mainLayout />
    <checkAcces pageAccess="admin" />
    <div class="mainContent">
      <leftMenu class="leftMenu" />
      <userManagement userStatus="praticien" />
    </div>
  </div>
</template>

<script>
import mainLayout from "../layout/mainLayout.vue";
import leftMenu from "../layout/leftMenu.vue";
import checkAcces from "../components/checkAcces.vue";
import userManagement from "../components/user-management";

export default {
  components: {
    mainLayout,
    leftMenu,
    checkAcces,
    userManagement,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.container {
  margin-top: 100px;
}
.rounded {
  border-radius: 15px !important;
}
.mainContent {
  display: flex;
  flex-direction: row;
}
.leftMenu {
  width: 5%;
}
@media screen and (max-width: 900px) {
  .leftMenu {
    display: none;
  }
  .casTable {
    width: 100%;
  }
}
</style>
