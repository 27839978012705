<template>
  <div>
    <div>
      <v-app id="inspire">
        <router-view />
      </v-app>
    </div>
  </div>
</template>

<script>
export default {};
</script>
