<template>
  <div>
    <v-snackbar
      v-model="snackbar.visible"
      auto-height
      :color="snackbar.color"
      :multi-line="snackbar.mode === 'multi-line'"
      :timeout="snackbar.timeout"
      :top="snackbar.position === 'top'"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
        <v-layout column>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
    <v-form>
      <div class="center">
        <v-checkbox
          class="checkboxDestroy"
          v-model="item[0].destroyNow"
          @click="checkAfter()"
          label="Auto-destruction immédiate quand passage avec le tag Départ"
        ></v-checkbox>
        <br />
        <br />
        <v-checkbox
          class="checkboxDestroy"
          v-model="item[0].destroyAfter"
          label="Auto-destruction 24h après passage avec le tag Départ"
          @click="checkNow()"
        ></v-checkbox>
      </div>
    </v-form>
    <div class="center">
      <v-btn
        class="greenBtn"
        @click="postDestroyTime"
        color="green darken-1"
        text
      >
        Sauvegarder
      </v-btn>
    </div>
  </div>
</template>

<script>
import laboRepo from "../../repository/laboRepository.js";

export default {
  data() {
    return {
      item: "",
      labo: [],
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 7500,
        visible: false,
      },
    };
  },
  mounted() {
    this.getlabo();
  },
  methods: {
    checkNow() {
      this.item[0].destroyNow = false;
    },
    checkAfter() {
      this.item[0].destroyAfter = false;
    },
    async getlabo() {
      const laboId = this.$store.state.user.laboId;
      this.labo = await laboRepo.getLaboById(laboId);
      if (this.labo.data.destroyCas == undefined) {
        this.item = [
          {
            destroyNow: false,
            destroyAfter: false,
          },
        ];
      } else {
        this.item = this.labo.data.destroyCas;
      }
    },
    async postDestroyTime() {
      const putLabo = await laboRepo.putLabo({
        id: this.labo.data.id,
        name: this.labo.data.name,
        active: this.labo.data.active,
        subscription: this.labo.data.subscription,
        firstName: this.labo.data.firstName,
        lastName: this.labo.data.lastName,
        email: this.labo.data.email,
        sendEmail: this.labo.data.sendEmail,
        destroyCas: this.item,
      });
      if (putLabo.status === 204) {
        this.snackbar = {
          color: "success",
          icon: "mdi-check-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: "La modification a bien été prise en compte ",
          visible: true,
        };
      } else {
        this.snackbar = {
          color: "error",
          icon: "mdi-alert-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: "Une erreur est survenue",
          visible: true,
        };
      }
    },
  },
};
</script>

<style>
.checkboxDestroy {
  width: 40%;
  margin-top: 80px;
}
</style>
