import { API_BASE_URL, API_ROUTES } from "../api-constant.js";
import axios from "axios";
import store from "../store";
export default {
  async getOperators() {
    const request = await axios.get(
      API_BASE_URL + API_ROUTES.getOperatorsByLaboId + store.state.user.laboId,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async getOperatorsById(id) {
    const request = await axios.get(API_BASE_URL + API_ROUTES.operators + id, {
      headers: {
        Authorization: `Bearer ${store.state.token}`,
      },
    });
    return request;
  },
  async getOperatorsByemail(id) {
    const request = await axios.get(
      API_BASE_URL + API_ROUTES.getOperatorByemail + id,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async postOperator(operator) {
    const request = await axios.post(
      API_BASE_URL + API_ROUTES.operators,
      {
        email: operator.email,
        firstName: operator.firstName,
        lastName: operator.lastName,
        userId: operator.userId,
        laboId: store.state.user.laboId,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async putOperator(operator) {
    const request = await axios.put(
      API_BASE_URL + API_ROUTES.operators + operator.id,
      {
        email: operator.email,
        firstName: operator.firstName,
        lastName: operator.lastName,
        userId: operator.userId,
        laboId: store.state.user.laboId,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async deleteOperator(id) {
    const request = await axios.delete(
      API_BASE_URL + API_ROUTES.operators + id,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },
};
