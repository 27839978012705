<template>
  <div
    v-if="itemProp"
    class="bro"
  >
    <v-card
      class="mx-auto"
      max-width="isOperatorCard ? '650px' : '95%'"
      outlined
      style="
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-color: isOperatorCard ? '#f5f5f5' : '#013b5b';
        border-width: 2.75px;
        max-width: isOperatorCard ? '650px' : '95%';
      "
    >
      <v-card-title
        style="padding-bottom: 0.5rem;"
        class="text-h6"
      >
        <span>Cas {{ originalItem.cas }}</span>

        <template v-if="isOperatorCard">
          <span style="margin-left: 0.5rem; margin-top: -0.5rem;">
            <task-card
              :key="originalItem.cas"
              :task="originalItem"
              :is-operator="true"
              :is-read-only="true"
            />
          </span>
        </template>

        <template v-if="originalItem.tag && originalItem.tag !== ''">
          <v-chip
            :color="getTagColor(originalItem.tag)"
            style="right: 1rem; position: absolute;"
            class="text text-sm text-700 px-2 pt-1 pb-1 rounded-lg"
          >
            <span :class="getTagTextColor(originalItem.tag)">{{
              originalItem.tag
            }}</span>
          </v-chip>
        </template>
      </v-card-title>

      <v-card-subtitle style="margin-bottom: -15px; margin-top: 0.15rem;">
        <template v-if="isOperatorCard">
          <v-select
            v-model="selectedToothOperator"
            :items="itemsTeeths"
            item-text="label"
            item-value="id"
            placeholder="Choisir une dent"
            filled
            style="
              margin-bottom: -15px;
              margin-top: 5px;
              width: 50%;
              display: inline-flex;
              align-content: center;
              align-items: center;
              margin-left: 0rem;
            "
            dense
            solo
            single-line
            @change="changeToothFromOperatorView"
          />
        </template>
      </v-card-subtitle>

      <div class="topbar">
        <v-subheader>
          <div
            style="
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-content: center;
              flex-direction: row;
              align-items: center;
              font-weight: bold;
            "
          >
            <div>Début</div>
            <div>Fin</div>
          </div>
        </v-subheader>
        <v-progress-linear
          v-model="currentProgress"
          color="#003b5b"
          style="color: white;"
          height="15"
        >
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </div>
      <v-data-table
        :headers="headers"
        :items="itemProp.tasks"
        item-key="id"
        hide-default-footer
        class="elevation-1"
      >
        <span>{{ itemProp.tasks }}</span>
        <template v-slot:item.maxDate="{ item }">
          <v-menu
            v-if="item.id !== 'Depart'"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(item.maxDate)"
                readonly
                v-bind="attrs"
                filled
                locale="fr-FR"
                style="margin-bottom: -15px; margin-top: 5px;"
                dense
                v-on="on"
              />
            </template>

            <v-date-picker
              v-model="item.maxDate"
              locale="fr-FR"
              :disabled="isReadOnly"
              @change="changeTaskThrottled('maxDate', item)"
            />
          </v-menu>

          <v-menu
            v-if="item.id === 'Depart'"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(originalItem.endDate)"
                readonly
                v-bind="attrs"
                filled
                locale="fr-FR"
                style="margin-bottom: -15px; margin-top: 5px;"
                dense
                :disabled="item.id === 'Depart'"
                v-on="on"
              />
            </template>

            <v-date-picker
              v-model="item.maxDate"
              disabled
              locale="fr-FR"
              @change="changeTaskThrottled('maxDate', item)"
            />
          </v-menu>
        </template>

        <template v-slot:item.operator="{ item }">
          <v-select
            v-model="item.operator"
            :items="opePickerTri"
            :label="opeLabel"
            item-text="label"
            item-value="id"
            filled
            :disabled="
              (item.operator && isOperatorCard) || isReadOnly ? true : false
            "
            style="margin-bottom: -15px; margin-top: 5px;max-width: 190px;"
            dense
            solo
            @change="changeTaskThrottled('operator', item)"
          />
        </template>

        <template v-slot:item.isDone="{ item }">
          <v-simple-checkbox
            v-model="item.isDone"
            :disabled="isReadOnly || !item.operator"
            @click="changeTaskThrottled('isDone', item)"
          />
        </template>
      </v-data-table>

      <!-- <v-card-actions
        style="
          display: flex;
          justify-content: flex-end;
          align-content: center;
          flex-direction: row;
          align-items: center;
        "
      >
        <span>
          <v-btn
            text
            class="text-right"
          >
            Total : <b>{{ total }}</b>
          </v-btn></span>
      </v-card-actions> -->
    </v-card>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      max-width="100%"
      outlined
    >
      <v-card-title
        style="padding-bottom: 0.5rem;"
        class="text-h6"
      >
        <v-chip>Aucune dent selectionné où pas de taches à réaliser</v-chip>
      </v-card-title>
      <div class="topbar">
        <v-subheader>
          Cliquez sur une dent sur l'arcade pour voir les taches à réaliser
        </v-subheader>
      </div>
    </v-card>
  </div>
</template>

<script>
import project from "../../repository/projectRepository.js";
import taskCard from "../components/TaskCard";
import moment from "moment";
import _ from "lodash";

export default {
  name: "TaskList",
  components: {
    taskCard,
  },
  props: {
    item: Object,
    tooths: Array,
    articles: Array,
    originalItem: Object,
    currentTooth: Number,
    opePickerTri: Array,
    opeLabel: String,
    isOperatorCard: Boolean,
    isReadOnly: Boolean,
    isArchive: Boolean,
    isAdmin: Boolean
  },
  data: () => ({
    headers: [
      {
        text: "Tache",
        align: "center",
        sortable: false,
        value: "id",
      },
      {
        text: "Date Limite",
        value: "maxDate",
        sortable: false,
        align: "center",
      },
      {
        text: "Operateur",
        value: "operator",
        sortable: false,
        align: "center",
      },
      { text: "Validé", value: "isDone", sortable: false, align: "center" },
    ],
    itemOperator: null,
    itemProp: {
      tasks: [],
      currentTooth: "",
    },
    currentProgress: 0,
    selectedToothOperator: null,
  }),
  computed: {
    total() {
      let total = 0;
      if (this.isOperatorCard) {
        if (
          this.itemOperator &&
          Object.prototype.hasOwnProperty.call(this.itemOperator, "tasks") &&
          Object.prototype.hasOwnProperty.call(this.itemOperator.tasks, "tasks")
        ) {
          this.itemOperator.tasks.tasks.forEach((element) => {
            if (element.value) {
              total = total + parseInt(element.value);
            }
          });
        } else if (this.lodash.isArray(this.itemOperator)) {
          this.itemOperator.forEach((element) => {
            if (element.value) {
              total = total + parseInt(element.value);
            }
          });
        }
      } else {
        if (
          this.item.tasks &&
          Object.prototype.hasOwnProperty.call(this.item.tasks, "tasks")
        ) {
          this.item.tasks.tasks.forEach((element) => {
            if (element.value) {
              total = total + parseInt(element.value);
            }
          });
        } else if (this.lodash.isArray(this.item)) {
          this.item.forEach((element) => {
            if (element.value) {
              total = total + parseInt(element.value);
            }
          });
        }
      }

      return total + ":00";
    },
    itemsTeeths() {
      let items = [];
      if (
        this.originalItem &&
        Object.prototype.hasOwnProperty.call(this.originalItem, "teeths")
      ) {
        this.originalItem.teeths.forEach((element) => {
          items.push({
            id: element.id[0],
            value: element.id[0],
            label: "Dent " + element.id[0] + " - " + element.article,
          });
        });
      }
      return items;
    },
  },
  watch: {
    item: function (newVal) {
      if (this.itemsTeeths.length >= 1) {
        this.changeToothFromOperatorView(this.itemsTeeths[0].id);
      }
      let sources;
      if (!this.isOperatorCard) {
        if (newVal.tasks) {
          sources = newVal.tasks.tasks
            ? newVal.tasks.tasks
            : newVal.tasks
                .filter(function (x) {
                  if (x.value === "" || x.value === null) {
                    return false; // skip
                  }
                  return true;
                })
                .map(function (b) {
                  return b;
                });
          this.itemProp = {
            tasks: this.createAndFetchMaxDate(sources),
            currentTooth: this.currentTooth,
          };
        } else {
          this.itemProp = {
            tasks: [],
            currentTooth: "",
          };
        }
        //
        this.changeLocale();
        this.progress(this.itemProp);
        this.$forceUpdate();
      }
    },
    itemOperator: function (newVal) {
      let sources;
      if (!this.isOperatorCard) {
        if (newVal.tasks) {
          sources = newVal.tasks.tasks
            ? newVal.tasks.tasks
            : newVal.tasks
                .filter(function (x) {
                  if (x.value === "" || x.value === null) {
                    return false; // skip
                  }
                  return true;
                })
                .map(function (b) {
                  return b;
                });
          this.itemProp = {
            tasks: this.createAndFetchMaxDate(sources),
            currentTooth: this.currentTooth,
          };
        } else {
          this.itemProp = {
            tasks: [],
            currentTooth: "",
          };
        }
        //
        this.changeLocale();
        this.progress(this.itemProp);
        this.$forceUpdate();
      }
    },
  },
  async mounted() {
    this.cardProcess();
  },

  methods: {
    formatDate(date) {
      var dateUS = moment(date, "YYYY-MM-DD");
      var dateFR = moment(date, "DD/MM/YYYY");
      let finalDate = "";
      if (dateUS.isValid() && date.toString().includes("-")) {
        finalDate = dateUS.format("DD/MM/YYYY");
      } else if (dateFR.isValid()) {
        finalDate = dateFR.format("DD/MM/YYYY");
      }
      return finalDate;
    },
    changeToothFromOperatorView(tooth) {
      this.cardProcess(tooth);
    },
    getTagColor(tagName) {
      if (tagName.length > 0 && this.tags) {
        const color = this.tags.tag.filter((tag) => tag.name == tagName);
        if (color.length > 0) return color[0].color;
      }
    },
    getTagTextColor(tagName) {
      if (tagName.length > 0 && this.tags) {
        const color = this.tags.tag.filter((tag) => tag.name == tagName);
        if (color.length > 0) return color[0].colorText;
      }
    },
    findOriginalArticle(teethNbr) {
      if (teethNbr && this.originalItem) {
        const article = this.originalItem.teeths.find((o) =>
          o.id.includes(teethNbr)
        );
        if (article) return article.article;
        else return "";
      } else {
        return "";
      }
    },
    cardProcess(toothNbr) {
      let sources;
      let currentTooth = toothNbr || this.currentTooth;
      this.selectedToothOperator = currentTooth;
      if (this.item.tasks.tasks && !this.item.cas) {
        sources = this.item.tasks.tasks
          .filter(function (x) {
            if (x.value === "" || x.value === null) {
              return false; // skip
            }
            return true;
          })
          .map(function (b) {
            return b;
          });
        this.itemProp = {
          tasks: this.createAndFetchMaxDate(sources),
          currentTooth: currentTooth,
        };
      } else if (this.item.tasks && this.item.cas) {
        //
        const findCorrespondingArticle = this.item.tasks.find(
          (o) => o.teethNbr === currentTooth
        );
        //
        this.itemOperator = findCorrespondingArticle.tasks.tasks;
        sources = findCorrespondingArticle.tasks.tasks
          .filter(function (x) {
            if (x.value === "" || x.value === null) {
              return false; // skip
            }
            return true;
          })
          .map(function (b) {
            return b;
          });
        this.itemProp = {
          tasks: this.createAndFetchMaxDate(sources),
          currentTooth: currentTooth,
        };
      } else {
        this.itemProp = {
          tasks: [],
          currentTooth: "",
        };
      }
      //
      this.changeLocale();
      this.progress(this.itemProp);
      this.$forceUpdate();
    },
    returnParsedDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    changeLocale() {
      this.$vuetify.lang.current = "fr";
    },
    progress(prop) {
      //
      function percentage(partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
      }
      //
      let totalDone = 0;
      if (prop.tasks.length > 0) {
        prop.tasks.forEach((element) => {
          if (element.isDone) {
            totalDone = totalDone + 1;
          }
        });
        //
      }
      const percent = percentage(totalDone, prop.tasks.length);
      this.currentProgress = percent;
    },
    createAndFetchMaxDate(tasks) {
      //
      let arr = [...tasks];
      arr.forEach((task, index) => {
        if (index === 0) {
          task.maxDate = this.originalItem.startDate;
        }
        if (index === arr.length) {
          task.maxDate = this.originalItem.endDate;
        }
      });

      return arr;
    },
    handleAllDone(inputArr) {
      let workingArr = [...inputArr];
      //
      let checker = (arr) => arr.every((v) => v.isDone === true);
      //
      workingArr.forEach((element) => {
        if (element.tasks) {
          const check = checker(element.tasks.tasks);
          element.isDone = check;
        }
      });

      return { array: workingArr, isAllDone: checker(workingArr) };
    },
    async changeTask() {
      let changedArr = [...this.originalItem.tasks];
      let changedItem = { ...this.originalItem };
      //
      const index = this.originalItem.tasks.findIndex(
        (o) => o.teethNbr === this.itemProp.currentTooth
      );
      //
      changedArr.splice(index, 1);
      changedArr.push({
        teethNbr: this.itemProp.currentTooth,
        tasks: { tasks: this.itemProp.tasks },
      });
      //
      const { array, isAllDone } = this.handleAllDone(changedArr);
      //
      changedItem.tasks = array;
      changedItem.isArchived = isAllDone;
      await project.putProject(changedItem);
      //
      this.progress(this.itemProp);
      this.$forceUpdate();
    },
    async changeTaskThrottled() {
      const that = this;
      const exec = _.throttle(function () {
        that.changeTask();
      }, 1000);
      exec();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-application .primary--text {
  color: #013b5b !important;
  caret-color: #013b5b !important;
}
.v-list-item__content > * {
  line-height: 1.1;
  flex: none;
  display: flex;
  width: 20%;
}
.img1 {
  display: block;
}
.img1:hover {
  filter: invert(35%) sepia(0%) saturate(1630%) hue-rotate(155deg)
    brightness(105%) contrast(120%);
}
.img1:active {
  filter: invert(35%) sepia(0%) saturate(1630%) hue-rotate(155deg)
    brightness(105%) contrast(120%);
}
.active {
  -webkit-filter: invert(35%) sepia(0%) saturate(1630%) hue-rotate(155deg)
    brightness(105%) contrast(120%);
  filter: invert(35%) sepia(0%) saturate(1630%) hue-rotate(155deg)
    brightness(105%) contrast(120%);
}
</style>
