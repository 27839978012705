import router from "./src/router";
var fps = 100;
var speedFactor = 0.001;
var minDelta = 0.5;
var autoScrollSpeed = 10;
var autoScrollTimer, restartTimer;
var isScrolling = false;
var prevPos = 0,
  currentPos = 0;
var currentTime, prevTime, timeDiff;
var currentRoute = router;

window.addEventListener("scroll", function () {
  // window.pageYOffset is the fallback value for IE
  currentPos = window.scrollY || window.pageYOffset;
});

window.addEventListener("wheel", handleManualScroll);
window.addEventListener("touchmove", handleManualScroll);

function handleManualScroll() {
  // window.pageYOffset is the fallback value for IE
  currentPos = window.scrollY || window.pageYOffset;
  clearInterval(autoScrollTimer);
  if (restartTimer) {
    clearTimeout(restartTimer);
  }
  restartTimer = setTimeout(() => {
    prevTime = null;
    setAutoScroll();
  }, 50);
}

function setAutoScroll(newValue) {
  if (currentRoute.history.current.name !== "Global") {
    return;
  }
  if (newValue) {
    autoScrollSpeed = speedFactor * newValue;
  }
  if (autoScrollTimer) {
    clearInterval(autoScrollTimer);
  }
  autoScrollTimer = setInterval(function () {
    currentTime = Date.now();
    if (prevTime) {
      if (!isScrolling) {
        timeDiff = currentTime - prevTime;
        currentPos += autoScrollSpeed * timeDiff;
        if (Math.abs(currentPos - prevPos) >= minDelta) {
          isScrolling = true;
          window.scrollTo(0, currentPos);
          isScrolling = false;
          prevPos = currentPos;
          prevTime = currentTime;
        }
        if (currentPos >= document.body.offsetHeight - window.innerHeight) {
          handleManualScroll();
          scrollTo(0, 0);
        }
      }
    } else {
      prevTime = currentTime;
    }
  }, 1000 / fps);
}

setAutoScroll(20);
