<template>
  <div>
    <mainLayout />
    <div class="contentAdmin mainContent">
      <checkAcces pageAccess="praticien" />
      <CasPraticien class="casPraticien" v-bind:global="false" />
    </div>
  </div>
</template>

<script>
import CasPraticien from "../components/casPraticien.vue";
import checkAcces from "../components/checkAcces.vue";
import mainLayout from "../layout/mainLayout.vue";

export default {
  components: {
    CasPraticien,
    mainLayout,
    checkAcces,
  },
};
</script>

<style scoped>
.contentAdmin {
  display: flex;
  flex-direction: row;
}
.mainContent {
  display: flex;
  flex-direction: row;
}
.casPraticien {
  width: 100%;
  padding: 100px 20px 0px 20px;
  background: #fafafa;
}
@media screen and (max-width: 900px) {
  .casPraticien {
    width: 100%;
  }
}
</style>
