import { API_BASE_URL, API_ROUTES } from "../api-constant.js";

import axios from "axios";
import store from "../store";

export default {
  async getProjects() {
    let request;
    try {
      request = await axios.get(
        API_BASE_URL + API_ROUTES.getProjectsByLaboId + store.state.user.laboId,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        }
      );
      return request;
    } catch (error) {
      if (process.browser) {
        localStorage.removeItem("vuex");
        window.location.href = "/login";
      }
    }
  },

  async getProjectsByLaboId() {
    try {
      const request = await axios.get(
        API_BASE_URL + API_ROUTES.getProjectsByLaboId + store.state.user.laboId
      );
      return request;
    } catch (error) {
      if (process.browser) {
        localStorage.removeItem("vuex");
        window.location.href = "/login";
      }
    }
  },

  async postProject(project) {
    const request = await axios.post(
      API_BASE_URL + API_ROUTES.projects,
      {
        alert: project.alert,
        cas: project.cas.toString(),
        comment: project.comment,
        customer: project.customer,
        endDate: project.endDate,
        startDate: project.startDate,
        material: project.material,
        operator: project.operator,
        tag: project.tag,
        sentInvoiceIt: project.sentInvoiceIt || false,
        teeths: project.teeths || [],
        tasks: project.tasks || [],
        files: project.files,
        age: project.age,
        sexe: project.sexe,
        praticien: project.praticien,
        startEmailSend: project.startEmailSend,
        newProjectEmailSend: project.newProjectEmailSend,
        laboId: store.state.user.laboId,
        article: project.article,
        articleNumber: project.articleNumber,
        teinte: project.teinte,
        isDelete: false,
        isArchived: project.isArchived || false,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );

    return request;
  },
  async putProject(project) {
    const request = await axios.put(
      API_BASE_URL + API_ROUTES.projects + project.id,
      {
        alert: project.alert,
        cas: project.cas,
        comment: project.comment,
        customer: project.customer,
        endDate: project.endDate,
        startDate: project.startDate,
        files: project.files,
        sentInvoiceIt: project.sentInvoiceIt || false,
        material: project.material,
        teeths: project.teeths || [],
        tasks: project.tasks || [],
        operator: project.operator,
        tag: project.tag,
        age: project.age,
        sexe: project.sexe,
        praticien: project.praticien,
        startEmailSend: project.startEmailSend,
        newProjectEmailSend: project.newProjectEmailSend,
        laboId: store.state.user.laboId,
        article: project.article,
        articleNumber: project.articleNumber,
        teinte: project.teinte,
        isDelete: project.isDelete,
        isArchived: project.isArchived || false,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },
  async deleteProject(project) {
    const request = await axios.delete(
      API_BASE_URL + API_ROUTES.projects + project.id,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },
};
