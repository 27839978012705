<template>
  <div v-if="items.length > 0">
    <v-card
      class="mx-auto"
      max-width="100%"
      style="margin-top: 1rem; margin-bottom: 1rem"
      outlined
    >
      <v-card-title class="text-h6"> Imagerie </v-card-title>

      <v-subheader style="margin-top: -1.5rem">
        Images STL et autres du cas.
      </v-subheader>

      <template>
        <v-row style="padding: 1rem">
          <v-col
            v-for="file in items"
            :key="file.url"
            class="d-flex child-flex"
            cols="6"
          >
            <ModelStl
              :width="150"
              :height="150"
              style="
                display: flex;
                margin: 0px;
                border: 1.5px solid rgb(1, 59, 91);
                padding: 0px;
                border-radius: 5px;
                align-items: center;
                justify-content: center;
                align-content: center;
                flex-direction: row;
              "
              :src="file.url"
            />
          </v-col>
        </v-row>
      </template>
    </v-card>
  </div>
</template>

<script>
// import moment from "moment";
import { ModelStl } from "vue-3d-model";
export default {
  name: "ImagerieViewer",
  components: {
    ModelStl,
  },
  props: {
    items: Array,
    originalItem: Object,
  },
  data: () => ({}),
  computed: {},
  watch: {
    items: function () {
      // watch it
      this.$forceUpdate();
    },
  },

  methods: {
    findArticle() {
      // const find = this.articles.find((article) => article.code === code);
    },
    handleClick() {},
    removeTodo() {},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-application .primary--text {
  color: #013b5b !important;
  caret-color: #013b5b !important;
}
.v-list-item__content > * {
  line-height: 1.1;
  flex: none;
  display: flex;
  width: 20%;
}
.img1 {
  display: block;
}
.img1:hover {
  filter: invert(35%) sepia(0%) saturate(1630%) hue-rotate(155deg)
    brightness(105%) contrast(120%);
}
.img1:active {
  filter: invert(35%) sepia(0%) saturate(1630%) hue-rotate(155deg)
    brightness(105%) contrast(120%);
}
.active {
  -webkit-filter: invert(35%) sepia(0%) saturate(1630%) hue-rotate(155deg)
    brightness(105%) contrast(120%);
  filter: invert(35%) sepia(0%) saturate(1630%) hue-rotate(155deg)
    brightness(105%) contrast(120%);
}
</style>
