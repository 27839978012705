import { API_BASE_URL, API_ROUTES } from "../api-constant.js";

import axios from "axios";
import store from "../store";

export default {
  async getLaboById() {
    const request = await axios.get(
      API_BASE_URL + API_ROUTES.labo + store.state.user.laboId,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },
  async deleteLaboById(laboId) {
    const request = await axios.delete(
      API_BASE_URL + API_ROUTES.labo + laboId,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },
  async getAllLabo() {
    const request = await axios.get(API_BASE_URL + API_ROUTES.labo, {
      headers: {
        Authorization: `Bearer ${store.state.token}`,
      },
    });
    return request;
  },
  async postLabo(labo) {
    const request = await axios.post(
      API_BASE_URL + API_ROUTES.labo,
      {
        name: labo.name,
        active: labo.active,
        subscription: labo.subscription,
        firstName: labo.firstName,
        lastName: labo.lastName,
        email: labo.email,
        sendEmail: labo.sendEmail,
        destroyCas: [
          {
            destroyNow: true,
            destroyAfter: false,
          },
        ],
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },
  async putLabo(labo) {
    const request = await axios.put(
      API_BASE_URL + API_ROUTES.labo + labo.id,
      {
        name: labo.name,
        active: labo.active,
        subscription: labo.subscription,
        firstName: labo.firstName,
        lastName: labo.lastName,
        email: labo.email,
        sendEmail: labo.sendEmail,
        destroyCas: labo.destroyCas,
        invoiceItEmail: labo.invoiceItEmail || "",
        invoiceItPassword: labo.invoiceItPassword || "",
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },
};
