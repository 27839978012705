<template>
  <div v-if="item && !isYear && !openedMonth" class="isFlex k1">
    <template v-if="!isOpenMonth">
      <v-card
        class="mx-auto archiveCard"
        max-width="250px"
        height="200px"
        outlined
        :style="{ background: randomBackgroundColor }"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              Nombre de CAS : {{ item.tasks.length }}
            </div>
            <v-list-item-title class="text-h5 mb-1">
              {{ item.name }} {{ new Date().getFullYear() }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions>
          <v-btn
            style="bottom: 10px"
            outlined
            rounded
            text
            @click="method(item)"
          >
            Ouvrir
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
    <template v-else>
      <div class="parent">
        <v-card
          v-for="iterator in item.tasks"
          :key="iterator.id"
          class="mx-auto archiveCard"
          max-width="300px"
          min-width="250px"
          height="200px"
          outlined
          :style="{ background: randomBackgroundColor }"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-1">CAS {{ iterator.cas }}</div>
              <v-list-item-title class="text-h7 mb-1">
                <b>Article(s) :</b>
                <template>
                  <span
                    v-for="article in iterator.teeths"
                    :key="article.article"
                    style="font-size: 0.75rem; font-weight: bold"
                  >
                    {{ article.article }}
                  </span>
                </template>
              </v-list-item-title>
              <v-list-item-title class="text-h7 mb-1">
                <b>Patient :</b> {{ iterator.customer }}
              </v-list-item-title>
              <v-list-item-title class="text-h7 mb-1">
                <b>Praticien :</b> {{ iterator.praticien }}
              </v-list-item-title>
              <v-list-item-title class="text-h7 mb-1">
                <b>Départ :</b> {{ iterator.endDate }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn style="bottom: 10px" outlined rounded text>
              Voir le CAS
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </template>
  </div>
  <div v-else class="isFlex parent">
    <template v-if="openedMonth && isOpen">
      <v-card
        v-for="iterator in openedMonth.tasks"
        :key="iterator.id"
        class="mx-auto archiveCard"
        max-width="300px"
        min-width="250px"
        height="200px"
        outlined
        :style="{ background: randomBackgroundColor }"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-1">CAS {{ iterator.cas }}</div>
            <v-list-item-title class="text-h7 mb-1">
              <b>Article(s) :</b>
              <template>
                <span
                  v-for="article in iterator.teeths"
                  :key="article.article"
                  style="font-size: 0.75rem; font-weight: bold"
                >
                  {{ article.article }}
                </span>
              </template>
            </v-list-item-title>
            <v-list-item-title class="text-h7 mb-1">
              <b>Patient :</b> {{ iterator.customer }}
            </v-list-item-title>
            <v-list-item-title class="text-h7 mb-1">
              <b>Praticien :</b> {{ iterator.praticien }}
            </v-list-item-title>
            <v-list-item-title class="text-h7 mb-1">
              <b>Départ :</b> {{ iterator.endDate }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions>
          <v-btn
            style="bottom: 10px"
            outlined
            rounded
            text
            @click="openCas(iterator)"
          >
            Voir le CAS
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
    <template v-if="!isOpen && !openedMonth">
      <v-card
        class="mx-auto archiveCard"
        max-width="250px"
        height="200px"
        outlined
        :style="{ background: randomBackgroundColor }"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              Activité sur <b>{{ values.length }}</b> mois
            </div>
            <v-list-item-title class="text-h5 mb-1">
              {{ new Date().getFullYear() }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions>
          <v-btn
            style="bottom: 10px"
            outlined
            rounded
            text
            @click="openSubCard"
          >
            Ouvrir
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
    <template v-else>
      <div v-if="!openedMonth" class="parent">
        <ArchiveCard
          v-for="iterator in values"
          :key="iterator.name"
          :item="iterator"
          :is-year="false"
          :method="openMonth"
          :casmethod="openCas"
        />
      </div>
    </template>
    <!-- <template v-if="!isOpen && !openedMonth && values && values.length === 0">
      <p>Aucun dossier/CAS archivé</p>
    </template> -->
  </div>
</template>

<script>
import ArchiveCard from "../components/ArchiveCard.vue";

export default {
  name: "ArchiveCard",
  components: {
    ArchiveCard,
  },
  props: {
    item: Object,
    isYear: Boolean,
    method: Function,
    casmethod: Function,
    vizu: Function,
  },
  data: () => ({
    bgColors: [
      "aliceblue",
      "antiquewhite",
      "bisque",
      "gainsboro",
      "lavender",
      "lightblue",
    ],
    values: [],
    isOpen: false,
    isOpenMonth: false,
    openedMonth: null,
    openedCas: null,
    itemOperator: null,
    itemProp: {
      tasks: [],
      currentTooth: "",
    },
    currentProgress: 0,
    selectedToothOperator: null,
  }),
  computed: {
    randomBackgroundColor: function () {
      return this.bgColors[Math.floor(Math.random() * this.bgColors.length)];
    },
  },
  async mounted() {
    if (this.isYear) {
      for (const key in this.item) {
        if (this.lodash.isObject(this.item[key])) {
          if (this.item[key].tasks && this.item[key].tasks.length > 0) {
            this.values.push(this.item[key]);
          }
        }
      }
    }
  },

  methods: {
    openSubCard: function () {
      this.isOpen = !this.isOpen;
    },
    openCas: function (z) {
      this.openedCas = z;
      this.vizu(z);
    },
    openMonth: function (x) {
      this.isOpenMonth = !this.isOpenMonth;
      if (this.isOpenMonth === true && x) {
        this.openedMonth = x;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-application .primary--text {
  color: #013b5b !important;
  caret-color: #013b5b !important;
}

.isFlex {
  display: flex;
}

.parent {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  gap: 0.25rem;
  justify-content: flex-start;
  align-items: center;
  max-height: 40vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow-y: auto;
  flex-wrap: wrap;
}

.archiveCard {
  margin: 1rem;
}
</style>
