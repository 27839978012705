<template>
  <div>
    <mainLayout />
    <checkAcces page-access="operateur" />
    <div class="mainContent">
      <leftMenu
        v-show="this.$store.state.user.status === 'admin'"
        class="leftMenu"
      />
      <div class="operateurContent">
        <div
          v-show="this.$store.state.user.status === 'admin'"
          class="idCardAdmin"
        >
          <v-card class="rounded-lg">
            <v-card-title class="subheading-profile font-weight-bold">
              <div class="header">
                <div
                  class="header-name"
                  style="width: max-content;"
                >
                  {{ this.$store.state.user.firstName }}
                  {{ this.$store.state.user.lastName }}
                </div>
                <v-btn
                  fab
                  @click="openModal"
                >
                  <v-avatar
                    v-if="this.profile.avatar"
                    size="50"
                    class="avatar"
                  >
                    <img
                      :src="this.profile.avatar"
                      alt="Avatar"
                      style="object-fit: cover;"
                    >
                    <p
                      class="label"
                      style="font-size: 30px;"
                    >
                      +
                    </p>
                  </v-avatar>
                  <v-avatar
                    v-else
                    color="#1f6ed5"
                    size="45"
                    class="avatar"
                  >
                    <div>
                      <p class="initial">
                        {{
                          getName(
                            this.$store.state.user.firstName,
                            this.$store.state.user.lastName
                          )
                        }}
                      </p>
                    </div>
                    <p
                      class="label"
                      style="font-size: 30px;"
                    >
                      +
                    </p>
                  </v-avatar>
                </v-btn>
              </div>
            </v-card-title>
            <v-list-item
              v-for="(tag, i) in this.profile.tags"
              :key="i"
              class="test"
            >
              <v-list-item-content>
                <v-chip
                  :color="getTagColor(tag)"
                  class="text text-sm text-700 px-2 pt-1 pb-1 rounded-lg"
                >
                  <span :class="getTagTextColor(tag)">{{ tag }}</span>
                </v-chip>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>

        <div
          v-show="this.$store.state.user.status !== 'admin'"
          class="idCard"
        >
          <v-card class="rounded">
            <v-card-title class="subheading-profile font-weight-bold">
              <div class="header">
                <div
                  class="header-name"
                  style="width: max-content;"
                >
                  {{ this.$store.state.user.firstName }}
                  {{ this.$store.state.user.lastName }}
                </div>
                <v-btn
                  fab
                  @click="openModal"
                >
                  <v-avatar
                    v-if="this.profile.avatar"
                    size="50"
                    class="avatar"
                  >
                    <img
                      :src="this.profile.avatar"
                      alt="Avatar"
                      style="object-fit: cover;"
                    >
                    <p
                      class="label"
                      style="font-size: 30px;"
                    >
                      +
                    </p>
                  </v-avatar>
                  <v-avatar
                    v-else
                    color="#1f6ed5"
                    size="45"
                    class="avatar"
                  >
                    <div>
                      <p class="initial">
                        {{
                          getName(
                            this.$store.state.user.firstName,
                            this.$store.state.user.lastName
                          )
                        }}
                      </p>
                    </div>
                    <p
                      class="label"
                      style="font-size: 30px;"
                    >
                      +
                    </p>
                  </v-avatar>
                </v-btn>
              </div>
            </v-card-title>
            <v-list-item
              v-for="(tag, i) in this.$store.state.user.tags"
              :key="i"
              class="test"
            >
              <v-list-item-content>
                <v-chip
                  :color="getTagColor(tag)"
                  class="text text-sm text-700 px-2 pt-1 pb-1 rounded-lg"
                >
                  <span :class="getTagTextColor(tag)">{{ tag }}</span>
                </v-chip>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
        <div class="kanban">
          <div
            v-for="column in columns"
            :key="column.title"
            style="height: 100%;"
            class="zone bg-gray-100 rounded-xl column-width rounded mr-4 ml-3 operator-column"
          >
            <div class="cardHome rounded-xl v-card v-sheet theme--light">
              <p
                class="text-gray-700 font-semibold font-sans tracking-wide text-lg"
              >
                <v-badge
                  v-show="
                    column.icon === 'mdi-clock-outline' &&
                      column.tasks.length > 0
                  "
                  color="red"
                  :content="column.tasks.length"
                />
                <v-icon
                  v-if="column.icon === 'mdi-backup-restore'"
                  large
                  class="iconDuring"
                  color="blue"
                >
                  {{ column.icon }}
                </v-icon>
                <v-icon
                  v-else
                  large
                  class="iconAttente"
                  color="#F39C12"
                >
                  {{ column.icon }}
                </v-icon>
                {{ column.title }}
              </p>
            </div>
            <div class="dragcontent">
              <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
              <draggable
                :id="column.ref"
                :list="column.tasks"
                :animation="200"
                :ghost-class="column.ref"
                group="tasks"
                :move="checkMove"
                @end="drop"
              >
                <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
                <template v-for="iterator in column.tasks">
                  <TaskList
                    v-if="iterator.tasks"
                    :key="iterator.cas"
                    :current-tooth="iterator.tasks[0].teethNbr"
                    :original-item="iterator"
                    :item="iterator"
                    class="cursor-move"
                    style="max-width: 650px;"
                    :ope-picker-tri="opePickerTri"
                    :ope-label="opeLabel"
                    :is-operator-card="true"
                    :is-archive="false"
                    :tags="tags"
                  />
                </template>
              </draggable>
            </div>
          </div>
        </div>
        <v-dialog
          v-model="modalProfile"
          persistent
          max-width="600px"
        >
          <v-card-title class="modalTitle">
            <span class="headline"> Modifier l'image de profil </span>
            <v-avatar
              v-if="this.profile.avatar"
              size="80"
              style="margin-left: 10px;"
            >
              <img
                :src="this.profile.avatar"
                alt="Avatar"
                style="object-fit: cover;"
              >
            </v-avatar>
            <v-avatar
              v-else
              color="#1f6ed5"
              style="margin-left: 10px;"
              size="50"
            >
              <p
                class="initial"
                style="font-size: 20px;"
              >
                {{
                  getName(
                    this.$store.state.user.firstName,
                    this.$store.state.user.lastName
                  )
                }}
              </p>
            </v-avatar>
          </v-card-title>
          <v-card-text class="modalTitle">
            <v-file-input
              v-model="file"
              :rules="rules"
              prepend-icon="mdi-camera"
              chips
              outlined
              dense
              accept="image/*"
              label="Selectionnez votre image"
            />
          </v-card-text>
          <v-card-actions class="saveBtnCard">
            <v-btn
              class="deleteBtn"
              color="red error"
              text
              @click="closeModal"
            >
              Annuler
            </v-btn>
            <v-btn
              :loading="isLoading"
              class="greenBtn"
              color="green darken-1"
              text
              @click="savePicture"
            >
              Sauvegarder
            </v-btn>
          </v-card-actions>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import mainLayout from "../layout/mainLayout.vue";
// import taskCard from "../components/TaskCard";
import checkAcces from "../components/checkAcces.vue";
import project from "../../repository/projectRepository.js";
import leftMenu from "../layout/leftMenu.vue";
import tags from "../../repository/tagsRepository.js";
import users from "../../repository/userRepository.js";
import operator from "../../repository/operatorRepository.js";
import admin from "../../repository/adminRepository.js";
import TaskList from "../components/TaskList";

export default {
  components: {
    mainLayout,
    // taskCard,
    draggable,
    checkAcces,
    leftMenu,
    TaskList,
  },
  data() {
    return {
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      profile: {},
      isLoading: false,
      modalProfile: false,
      files: [],
      file: [],
      test: [],
      tags: [],
      clickedTooth: null,
      baseTasks: {
        tasks: [],
      },
      allArticles: [],
      singleExpand: false,
      opePicker: [],
      opePickerTri: [],
      opeLabel: "Opérateur",
      columns: [
        {
          title: "Projets en cours",
          ref: "progress",
          icon: "mdi-backup-restore",
          drag: false,
          tasks: [],
        },
      ],
    };
  },
  computed: {},

  created() {
    this.initialize();
  },
  async mounted() {
    this.getAllProjects();
    /*  this.interval = setInterval(
      function () {
        this.getAllProjects();
      }.bind(this),
      36000
    ); */
    const tags = await this.getCurrentTag();
    this.tags = tags.data[0];
    const user = await users.getUser(
      this.$store.state.user.email,
      this.$store.state.token
    );
    this.profile = user.data[0];
    this.operators = await this.getAllOperators();
    this.operators = this.operators.data;
    this.operators.forEach((op) => {
      this.opePickerTri.push(op.firstName + " " + op.lastName);
    });
  },
  methods: {
    initialize() {
      this.tasks;
    },
    returnCorrespondingArticle: function (iterator) {
      if (iterator.tasks) {
        const findCorrespondingArticle = iterator.tasks[0];
        //

        return findCorrespondingArticle;
      } else {
        return null;
      }
    },
    returnClickedTooth: function (iterator) {
      if (iterator.tasks) {
        return iterator.tasks[0].teethNbr;
        // return iterator.tasks.tasks[0].teethNbr;
      } else {
        return null;
      }
    },
    getName(firstName, lastName) {
      if (firstName.substr(0, 2) == "Dr") {
        const first = firstName.substr(3, 1);
        const second = lastName.substr(0, 1);
        return first.toUpperCase() + second.toUpperCase();
      } else {
        const first = firstName.substr(0, 1);
        const second = lastName.substr(0, 1);
        return first.toUpperCase() + second.toUpperCase();
      }
    },
    openModal() {
      this.modalProfile = true;
    },
    async closeModal() {
      this.modalProfile = false;
      this.file = [];
      const user = await users.getUser(
        this.$store.state.user.email,
        this.$store.state.token
      );
      this.profile = user.data[0];
    },
    async savePicture() {
      //this.files = this.fileToBase64(this.file)
      const base64 = await this.fileToBase64(this.file);
      Object.assign(this.profile, { file: base64 });
      this.isLoading = true;
      await users.putUser(this.profile);
      this.modalProfile = false;
      const user = await users.getUser(
        this.$store.state.user.email,
        this.$store.state.token
      );
      this.isLoading = false;
      this.profile = user.data[0];
    },
    fileToBase64(file) {
      return new Promise((resolve) => {
        let fileReader = new FileReader();
        let base64;
        fileReader.onload = (fileLoadedEvent) => {
          base64 = fileLoadedEvent.target.result;
          resolve(base64);
        };
        fileReader.readAsDataURL(file);
      });
    },
    async getCurrentTag() {
      return await tags.getTags();
    },
    clickToothTask: async function (nbr) {
      if (this.expanded && this.expanded[0].tasks) {
        const findCorrespondingArticle = this.expanded[0].tasks.find(
          (o) => o.teethNbr === nbr
        );
        //
        this.baseTasks = findCorrespondingArticle;
        this.clickedTooth = nbr;
      }
    },

    getTagColor(tagName) {
      if (tagName.length > 0) {
        const color = this.tags.tag.filter((tag) => tag.name == tagName);
        if (color.length > 0) return color[0].color;
      }
    },
    getTagTextColor(tagName) {
      if (tagName.length > 0) {
        const color = this.tags.tag.filter((tag) => tag.name == tagName);
        if (color.length > 0) return color[0].colorText;
      }
    },
    checkMove(e) {
      if (e.from.id === "progress" && e.to.id === "backlog") {
        return false;
      }
    },
    drop(event) {
      if (event.from.id === "progress" && event.to.id === "backlog") {
        this.tasks.forEach((task) => {
          if (task.cas === event.item.attributes.id.value) {
            task.operator = "";
            project.putProject(task);
          }
        });
      }
      if (event.from.id === "backlog" && event.to.id === "progress") {
        this.tasks.forEach((task) => {
          if (task.cas === event.item.attributes.id.value) {
            task.operator =
              this.$store.state.user.firstName +
              " " +
              this.$store.state.user.lastName;
            project.putProject(task);
          }
        });
      }
    },
    async getAllOperators() {
      const adminOperateur = await admin.getAdmins();
      const operatorData = await operator.getOperators();
      adminOperateur.data.forEach((admin) => {
        operatorData.data.push(admin);
      });
      return operatorData;
    },
    async getAllProjects() {
      this.columns[0].tasks = [];
      this.projects = await project.getProjects();
      this.tasks = this.projects.data;
      //
      var sources = this.projects.data
        .filter(function (x) {
          if (x.isArchived === true) {
            return false; // skip
          }
          return true;
        })
        .map(function (x) {
          return x;
        });

      this.tasks = sources;
      sources.forEach((task) => {
        this.columns[0].tasks.push(task);
        // this.operatorName =
        //   this.$store.state.user.firstName +
        //   " " +
        //   this.$store.state.user.lastName;
        // if (this.operatorName === task.operator) {
        //   this.columns[0].tasks.push(task);
        // }
      });
    },
  },
};
</script>

<style>
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.idCard {
  min-width: 20% !important;
  margin-left: 1rem;
  min-height: 50% !important;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
}

.subheading-profile {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text {
  justify-content: center;
}
.mainContent {
  display: flex;
  flex-direction: row;
}
.leftMenu {
  width: 5%;
}
.rounded {
  border-radius: 15px !important;
}
.operateurContent {
  margin-top: 100px;
  margin-left: 0px;
  margin-right: 15px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 99vh;
  flex-direction: row;
}
.operator-column {
  min-width: 33%;
  max-width: 70%;
}
.initial {
  color: white;
  font-size: 15px;
  margin-top: 15px;
}
.kanban {
  display: flex;
  flex-direction: row;
  min-width: 50vw;
  height: 100%;
  justify-content: center;
}
.test {
  display: flex;
  flex-direction: row;
}
/* .avatar:hover {
  opacity: 0.7;
} */
.avatar {
  z-index: 1;
}
.label {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  bottom: -50px;
  color: #fff;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  text-align: center;
  transition: 0.1s all;
  z-index: 100;
}

.avatar:hover .label {
  bottom: -20px;
}
.inital:hover .label {
  bottom: -20px;
}
.zone {
  height: 800px;
}
.cardHome {
  display: flex;
  border-radius: 25px !important;
  width: 350px;
  max-height: 71px;
  width: 100%;
}
.dragcontent {
  display: flex;
  justify-content: center;
  min-height: 45%;
  overflow: scroll;
  max-height: 100%;
  height: 90%;
  overflow-x: hidden;
}
.card {
  width: 250px;
  height: 31%;
  margin: 10px;
}
.modalTitle {
  display: flex;
  justify-content: space-between;
}
.iconDuring {
  width: 51px;
  height: 47px;
  background: #3498db40 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin: 10px;
}
#backlog {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
#progress {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
.bg-white
  shadow
  .rounded-xl
  .px-3
  .pt-2
  .pb-2
  .border
  .border-white
  .card
  .mt-5
  .mb-2
  .cursor-move {
  height: 100% !important;
}
.iconAttente {
  width: 51px;
  height: 47px;
  background: #f39c1240 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin: 10px;
}
.idCardAdmin {
  min-width: 15%;
  max-width: 25%;
  margin-left: 8.33%;
}
@media screen and (min-width: 1500px) {
  .card {
    width: 300px;
  }
}
@media screen and (max-width: 900px) {
  .leftMenu {
    display: none;
  }
  .casTable {
    width: 100%;
  }
  .kanban {
    display: flex;
    min-width: 50vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .operateurContent {
    overflow-x: scroll;
    width: 100%;
    display: flex;
    height: 99%;
    flex-direction: column;
    margin-top: 80px !important;
    align-items: center;
    margin: 0px;
  }
  .dragcontent {
    display: flex;
    justify-content: center;
    overflow: scroll;
    max-height: 83%;
  }
  .zone {
    width: 300px;
    height: 550px;
    margin-top: 10px;
  }
  .card {
    width: 250px;
  }
  .idCardAdmin {
    min-width: 15%;
    max-width: 60%;
  }
}
@media screen and (max-width: 800px) {
  .operateurContent {
    overflow-x: scroll;
    width: 100%;
    display: flex;
    height: 99%;
    flex-direction: row;
    margin-top: 80px !important;
    margin: 0px;
    flex-direction: column;
    align-items: center;
  }
  .operator-column {
    width: 100% !important;
  }
  .card {
    height: 50%;
  }
}
</style>
