import { API_BASE_URL, API_ROUTES } from "../api-constant.js";

import axios from "axios";
import store from "../store";
export default {
  async getPraticiens() {
    const request = await axios.get(
      API_BASE_URL + API_ROUTES.getPraticiensByLaboId + store.state.user.laboId,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async getPraticiensById(id) {
    const request = await axios.get(API_BASE_URL + API_ROUTES.praticien + id, {
      headers: {
        Authorization: `Bearer ${store.state.token}`,
      },
    });
    return request;
  },
  async getPraticiensByemail(id) {
    const request = await axios.get(
      API_BASE_URL + API_ROUTES.getPraticienByemail + id,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async postPraticien(praticien) {
    const request = await axios.post(
      API_BASE_URL + API_ROUTES.praticien,
      {
        email: praticien.email,
        firstName: praticien.firstName,
        lastName: praticien.lastName,
        userId: praticien.userId,
        sendEmail: praticien.sendEmail,
        laboId: store.state.user.laboId,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async putPraticien(praticien) {
    const request = await axios.put(
      API_BASE_URL + API_ROUTES.praticien + praticien.id,
      {
        email: praticien.email,
        firstName: praticien.firstName,
        lastName: praticien.lastName,
        userId: praticien.userId,
        sendEmail: praticien.sendEmail,
        laboId: store.state.user.laboId,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async deletePraticien(id) {
    const request = await axios.delete(
      API_BASE_URL + API_ROUTES.praticien + id,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },
};
