import "@mdi/font/css/materialdesignicons.css";
import "./assets/css/tailwind.css";
import "tailwindcss/tailwind.css";
import "./overrides.scss";
// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import App from "./App.vue";
import FormulateVSelectPlugin from "@cone2875/vue-formulate-select";
import ImageUploader from "vue-image-upload-resize";
import Vue from "vue";
import { VueCsvImportPlugin } from "vue-csv-import";
import VueFormulate from "@braid/vue-formulate";
// import fr from "vuetify/lib/locale/fr";
import VueHtmlToPaper from "vue-html-to-paper";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import moment from "moment";
import router from "./router";
import store from "../store";
import vuetify from "@/plugins/vuetify";
// import VueNativeNotification from "vue-native-notification";
import VueChartJs from "vue-chartjs";
// import socketio from "socket.io";
import NativeNotificationOfVue from "native-notification-of-vue";
import VueSocketIO from "vue-socket.io";

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=no", "scrollbars=no"],
  styles: [
    "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
    "public/landscape.css",
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};
Vue.use(VueChartJs);
Vue.use(VueHtmlToPaper, options);
Vue.config.productionTip = false;
Vue.use(VueLodash, { name: "custom", lodash: lodash });
Vue.use(ImageUploader);
Vue.use(VueCsvImportPlugin);
Vue.use(BootstrapVue);
Vue.use(
  new VueSocketIO({
    debug: true,
    connection: "https://socket.dentalinc.midnightco.io:3000",
    options: { path: "/dentalinc/" }, //Optional options
  })
);
Vue.use(NativeNotificationOfVue, {
  requestOnLoad: true, // Whether to ask the user for notification right after the site loads, or ask again when you use it
});
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueFormulate, {
  plugins: [FormulateVSelectPlugin],
});

// Vue.notification.requestPermission();

new Vue({
  vuetify,
  router,
  moment,
  store,
  render: (h) => h(App),
}).$mount("#app");
