<template>
  <div>
    <mainLayout />
    <div class="mainContent">
      <leftMenu class="leftMenu" />
      <v-col
        class="homeContent"
        style="
          padding-top: 100px;
          display: flex;
          margin-left: 1%;
          width: calc(100% - 1%);
          flex-wrap: wrap;
          justify-content: flex-start;
        "
      >
        <div class="title-archived">
          <h3>Dossiers terminé(s)</h3>
          <p style="margin-left: 1rem; margin-right: 1rem;">
            Archive
          </p>
          <v-divider />
        </div>
        <div class="archived-cards">
          <ArchiveCard
            v-for="iterator in parsedYear"
            :key="iterator.id"
            :item="iterator"
            :is-year="true"
            :vizu="visualizeFromChild"
          />
        </div>
        <div
          v-if="visualizer"
          style="height: 5px; display: block; width: 100%;"
        >
          <v-divider />
        </div>
        <div
          v-if="visualizer"
          class="vizu"
        >
          <div
            class="row sp-details"
            style="
              background: #e5e7eb8c;
              border-radius: 5px;
              margin-top: 1rem;
              margin-right: 0.23rem;
              margin-left: 0.23rem;
            "
          >
            <div class="col-6 text-left">
              <TaskList
                v-if="visualizer.tasks"
                :key="visualizer.cas"
                :current-tooth="visualizer.tasks[0].teethNbr"
                :original-item="visualizer"
                :item="visualizer"
                class="cursor-move"
                style="max-width: 650px;"
                :ope-picker-tri="opePickerTri"
                :ope-label="opeLabel"
                :is-operator-card="true"
                :is-read-only="true"
                :is-archive="true"
                :tags="tags"
              />
            </div>
            <div class="col-6 text-right">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  align-content: center;
                  height: 100%;
                "
              >
                <div
                  style="
                    transition: background 0.8s ease 0s;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    align-content: center;
                    color: #000;
                  "
                >
                  <div
                    id="teinte"
                    style="
                      display: flex;
                      position: absolute;
                      z-index: 1;
                      width: 105px;
                      height: 105px;
                      padding: 10px;
                      background: rgba(223, 223, 223, 0.9);
                      border-radius: 10px;
                      flex-flow: row nowrap;
                      place-content: center;
                      align-items: center;
                    "
                  >
                    <v-text-field
                      v-model="visualizer.teinte"
                      label="Teinte"
                      disabled
                    />
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="392.289"
                    height="600"
                    viewBox="0 0 392.289 644"
                  >
                    <defs>
                      <filter
                        id="shadow"
                        x="0"
                        y="0"
                        width="392.289"
                        height="644"
                        filterUnits="userSpaceOnUse"
                      >
                        <feOffset input="SourceAlpha" />
                        <feGaussianBlur
                          stdDeviation="10"
                          result="blur"
                        />
                        <feFlood flood-opacity="0.18" />
                        <feComposite
                          operator="in"
                          in2="blur"
                        />
                        <feComposite in="SourceGraphic" />
                      </filter>
                    </defs>
                    <g
                      id="dentures"
                      style="cursor: pointer;"
                      transform="translate(-728.846 -173.501)"
                    >
                      <g
                        id="_48"
                        style="cursor: pointer;"
                        data-name="48"
                        transform="translate(764.576 502.128)"
                        @click="clickToothTask(48, visualizer)"
                      >
                        <path
                          id="_48-2"
                          data-name="48"
                          d="M66.96,548.119c-2.39,6.871-9.38,10.754-16.251,11.591-8.544,1.016-22.285,1.016-28.678-5.616-6.691-6.93-3.824-21.269-1.792-29.574.836-3.166,1.912-6.273,4.481-8.543,5.8-5.138,13.084-5.556,20.313-6.512a26.1,26.1,0,0,1,4.361-.179c4.481.179,9.141,1.494,12.307,4.541,3.943,4.063,5.258,10.635,5.8,16.071S68.812,542.862,66.96,548.119Z"
                          transform="translate(-17.853 -509.262)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(48))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(13.425 12.872)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26"
                          data-name="26"
                          transform="translate(14.925 30.372)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >48</tspan>
                        </text>
                      </g>
                      <g
                        id="_47"
                        style="cursor: pointer;"
                        data-name="47"
                        transform="translate(766.695 553.39)"
                        @click="clickToothTask(47, visualizer)"
                      >
                        <path
                          id="_47-2"
                          data-name="47"
                          d="M75.5,635.472c-2.689,5.8-9.5,9.858-15.713,10.694-9.977,1.314-25.631,2.868-33.756-4.481-2.39-2.151-3.644-5.5-4.242-8.6-.836-4.421-.119-9.021.3-13.443.6-5.974.956-11.77,5.736-16.071,6.512-5.855,16.012-7.229,24.2-8.3a18.114,18.114,0,0,1,7.169.358c13.5,3.465,17.326,15.892,17.326,28.5C76.513,627.825,77.111,632.007,75.5,635.472Z"
                          transform="translate(-21.4 -595.062)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(47))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-2"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(15.305 15.61)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-2"
                          data-name="26"
                          transform="translate(17.805 33.11)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >47</tspan>
                        </text>
                      </g>
                      <g
                        id="_46"
                        style="cursor: pointer;"
                        data-name="46"
                        transform="translate(774.48 606.106)"
                        @click="clickToothTask(46, visualizer)"
                      >
                        <path
                          id="_46-2"
                          data-name="46"
                          d="M91.139,721.357c-2.509,10.157-11.113,14.458-19.955,18.282-2.987,1.255-5.676,3.286-8.962,3.7-6.811.836-16.37-1.912-20.313-7.707-2.509-3.7-2.928-7.827-4.3-11.949-1.494-4.541-3.585-9.44-3.107-14.339.358-3.7,2.39-5.915,3.644-9.26,1.016-2.808,1.075-5.915,2.33-8.663,2.689-6.034,8.364-7.169,14.219-7.946,1.255-.119,2.569-.179,3.824-.179a76.407,76.407,0,0,1,11.471,1.494c4.063.657,8.006,1.075,11.352,3.824,4.839,4,6.811,10.4,8.066,16.31C90.542,710.364,92.514,715.741,91.139,721.357Z"
                          transform="translate(-34.432 -683.3)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(46))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-3"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(15.519 17.893)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-3"
                          data-name="26"
                          transform="translate(18.019 35.393)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >46</tspan>
                        </text>
                      </g>
                      <g
                        id="_45"
                        style="cursor: pointer;"
                        data-name="45"
                        transform="translate(792.83 661.956)"
                        @click="clickToothTask(45, visualizer)"
                      >
                        <path
                          id="_45-2"
                          data-name="45"
                          d="M111.448,798.838c-2.569,9.5-12.965,17.625-22.7,18.521-8.783.777-22.524-5.019-23.54-14.936-.6-5.974,3.166-12.546,6.811-17.027,4.9-5.974,13.921-7.886,21.209-8.543A17.224,17.224,0,0,1,111.448,798.838Z"
                          transform="translate(-65.143 -776.78)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(45))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-4"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(11.17 8.044)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-4"
                          data-name="26"
                          transform="translate(13.67 25.544)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >45</tspan>
                        </text>
                      </g>
                      <g
                        id="_44"
                        style="cursor: pointer;"
                        data-name="44"
                        transform="translate(808.939 698.343)"
                        @click="clickToothTask(44, visualizer)"
                      >
                        <path
                          id="_44-2"
                          data-name="44"
                          d="M132.7,856.931c-2.868,8.663-8.3,17.983-18.1,19.357-10.754,1.494-24.017-8.065-22.345-19.656,1.494-10.157,11.172-17.326,20.851-18.7a20.438,20.438,0,0,1,2.748-.239c6.751-.179,15.354,2.091,17.864,8.484C135.148,849.7,133.834,853.526,132.7,856.931Z"
                          transform="translate(-92.107 -837.684)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(44))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-5"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(9.061 6.657)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-5"
                          data-name="26"
                          transform="translate(10.561 24.157)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >44</tspan>
                        </text>
                      </g>
                      <g
                        id="_43"
                        style="cursor: pointer;"
                        data-name="43"
                        transform="translate(833.707 728.288)"
                        @click="clickToothTask(43, visualizer)"
                      >
                        <path
                          id="_43-2"
                          data-name="43"
                          d="M169.819,901.783c-1.434,10.037-5.556,23.838-17.684,24.615-12.188.836-22.345-14.7-17.207-25.511,4.66-9.858,14.219-12.188,24.077-13.024C167.788,887.085,170.894,894.494,169.819,901.783Z"
                          transform="translate(-133.562 -887.806)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(43))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-6"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(6.293 6.712)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-6"
                          data-name="26"
                          transform="translate(8.793 24.212)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >43</tspan>
                        </text>
                      </g>
                      <g
                        id="_42"
                        style="cursor: pointer;"
                        data-name="42"
                        transform="translate(861.418 742.682)"
                        @click="clickToothTask(42, visualizer)"
                      >
                        <path
                          id="_42-2"
                          data-name="42"
                          d="M210.776,940.875c-1.016,4.421-2.808,8.065-7.647,8.842-6.691,1.016-12.726-3.883-17.923-7.349-2.39-1.613-5.2-3.346-5.258-6.572-.119-7.647,9.44-13.741,14.219-18.521,2.39-2.45,4.9-4.66,8.364-5.258a6.582,6.582,0,0,1,1.075-.119c5.138-.12,6.632,5.317,7.229,9.559C211.791,928.269,212.329,934.064,210.776,940.875Z"
                          transform="translate(-179.946 -911.897)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(42))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-7"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(6.581 8.318)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-7"
                          data-name="26"
                          transform="translate(9.081 25.818)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >42</tspan>
                        </text>
                      </g>
                      <g
                        id="_31"
                        style="cursor: pointer;"
                        data-name="31"
                        transform="translate(925.156 748.43)"
                        @click="clickToothTask(31, visualizer)"
                      >
                        <path
                          id="_31-2"
                          data-name="31"
                          d="M320.025,952.814c-1.255,2.39-4.541,3.405-6.93,4.182a49.185,49.185,0,0,1-20.612,2.33c-3.764-.478-5.915-2.509-5.855-6.512.119-3.465,1.494-6.811,2.27-10.157,1.255-5.377,2.091-10.276,4.66-15.235,1.733-3.226,4.122-6.333,8.185-5.855,5.915.717,8.066,5.915,10.515,10.635,1.912,3.7,4,7.289,5.735,11.053C319.308,946.122,321.7,949.587,320.025,952.814Z"
                          transform="translate(-286.626 -921.518)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(31))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-8"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(3.845 10.57)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-8"
                          data-name="26"
                          transform="translate(9.345 28.07)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >31</tspan>
                        </text>
                      </g>
                      <g
                        id="_32"
                        style="cursor: pointer;"
                        data-name="32"
                        transform="translate(957.275 742.204)"
                        @click="clickToothTask(32, visualizer)"
                      >
                        <path
                          id="_32-2"
                          data-name="32"
                          d="M372.2,935c-.06,3.226-2.868,4.959-5.258,6.572-5.2,3.465-11.232,8.364-17.923,7.349-4.839-.777-6.632-4.421-7.647-8.842-1.553-6.811-1.016-12.606-.06-19.417.6-4.242,2.091-9.679,7.229-9.559a6.583,6.583,0,0,1,1.075.12c3.465.6,5.974,2.808,8.364,5.258C362.757,921.255,372.316,927.349,372.2,935Z"
                          transform="translate(-340.389 -911.097)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(32))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-9"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(1.724 8.796)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-9"
                          data-name="26"
                          transform="translate(5.224 26.296)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >32</tspan>
                        </text>
                      </g>
                      <g
                        id="_33"
                        style="cursor: pointer;"
                        data-name="33"
                        transform="translate(980.336 727.811)"
                        @click="clickToothTask(33, visualizer)"
                      >
                        <path
                          id="_33-2"
                          data-name="33"
                          d="M396.875,925.6c-12.128-.777-16.251-14.578-17.685-24.615-1.075-7.289,2.031-14.7,10.814-13.921,9.858.836,19.417,3.167,24.077,13.024C419.219,910.9,409.063,926.434,396.875,925.6Z"
                          transform="translate(-378.986 -887.006)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(33))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-10"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(5.664 7.189)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-10"
                          data-name="26"
                          transform="translate(9.164 24.689)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >33</tspan>
                        </text>
                      </g>
                      <g
                        id="_34"
                        style="cursor: pointer;"
                        data-name="34"
                        transform="translate(999.328 697.865)"
                        @click="clickToothTask(34, visualizer)"
                      >
                        <path
                          id="_34-2"
                          data-name="34"
                          d="M430.523,875.489c-9.8-1.374-15.235-10.694-18.1-19.357-1.135-3.405-2.45-7.229-1.016-10.754,2.509-6.393,11.113-8.663,17.864-8.484a20.429,20.429,0,0,1,2.748.239c9.679,1.374,19.357,8.543,20.851,18.7C454.54,867.423,441.277,876.982,430.523,875.489Z"
                          transform="translate(-410.776 -836.884)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(34))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-11"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(9.671 7.135)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-11"
                          data-name="26"
                          transform="translate(12.171 24.635)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >34</tspan>
                        </text>
                      </g>
                      <g
                        id="_35"
                        style="cursor: pointer;"
                        data-name="35"
                        transform="translate(1010.695 661.479)"
                        @click="clickToothTask(35, visualizer)"
                      >
                        <path
                          id="_35-2"
                          data-name="35"
                          d="M476.718,801.623c-1.016,9.918-14.757,15.713-23.54,14.936-9.738-.9-20.134-9.022-22.7-18.521A17.224,17.224,0,0,1,448.7,776.052c7.289.657,16.31,2.569,21.209,8.544C473.552,789.076,477.315,795.648,476.718,801.623Z"
                          transform="translate(-429.804 -775.98)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(35))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-12"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(11.304 8.522)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-12"
                          data-name="26"
                          transform="translate(14.804 26.022)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >35</tspan>
                        </text>
                      </g>
                      <g
                        id="_36"
                        style="cursor: pointer;"
                        data-name="36"
                        transform="translate(1018.846 605.629)"
                        @click="clickToothTask(36, visualizer)"
                      >
                        <path
                          id="_36-2"
                          data-name="36"
                          d="M497.443,722.888c-1.374,4.122-1.792,8.245-4.3,11.949-3.943,5.8-13.5,8.543-20.313,7.707-3.286-.418-5.974-2.45-8.962-3.7-8.842-3.824-17.445-8.125-19.955-18.282-1.374-5.616.6-10.993,1.733-16.43,1.255-5.915,3.226-12.307,8.066-16.31,3.346-2.748,7.289-3.167,11.352-3.824a76.383,76.383,0,0,1,11.471-1.494c1.255,0,2.569.06,3.824.179,5.855.777,11.531,1.912,14.219,7.946,1.255,2.748,1.314,5.855,2.33,8.663,1.255,3.346,3.286,5.556,3.644,9.26C501.028,713.448,498.937,718.347,497.443,722.888Z"
                          transform="translate(-443.443 -682.5)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(36))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-13"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(17.155 18.371)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-13"
                          data-name="26"
                          transform="translate(20.655 35.871)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >36</tspan>
                        </text>
                      </g>
                      <g
                        id="_37"
                        style="cursor: pointer;"
                        data-name="37"
                        transform="translate(1028.584 552.911)"
                        @click="clickToothTask(37, visualizer)"
                      >
                        <path
                          id="_37-2"
                          data-name="37"
                          d="M514.581,632.283c-.6,3.107-1.852,6.452-4.242,8.6-8.125,7.349-23.778,5.8-33.756,4.481-6.213-.836-13.024-4.9-15.713-10.694-1.613-3.465-1.016-7.647-1.016-11.352,0-12.606,3.824-25.033,17.326-28.5a18.114,18.114,0,0,1,7.169-.359c8.185,1.075,17.685,2.45,24.2,8.3,4.78,4.3,5.138,10.1,5.736,16.071C514.7,623.261,515.418,627.861,514.581,632.283Z"
                          transform="translate(-459.747 -594.262)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(37))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-14"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(15.415 16.089)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-14"
                          data-name="26"
                          transform="translate(18.915 33.589)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >37</tspan>
                        </text>
                      </g>
                      <g
                        id="_38"
                        style="cursor: pointer;"
                        data-name="38"
                        transform="translate(1035.652 501.65)"
                        @click="clickToothTask(38, visualizer)"
                      >
                        <path
                          id="_38-2"
                          data-name="38"
                          d="M517.67,553.294c-6.393,6.632-20.134,6.632-28.678,5.616-6.871-.836-13.861-4.72-16.251-11.591-1.852-5.258-1.075-12.785-.538-18.222s1.852-12.009,5.8-16.071c3.166-3.047,7.827-4.361,12.307-4.541a26.1,26.1,0,0,1,4.361.179c7.229.956,14.518,1.374,20.313,6.512,2.569,2.27,3.644,5.377,4.481,8.544C521.493,532.024,524.361,546.363,517.67,553.294Z"
                          transform="translate(-471.574 -508.462)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(38))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-15"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(13.348 13.35)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-15"
                          data-name="26"
                          transform="translate(16.848 30.85)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >38</tspan>
                        </text>
                      </g>
                      <g
                        id="_18"
                        style="cursor: pointer;"
                        data-name="18"
                        transform="translate(761.709 442.317)"
                        @click="clickToothTask(18, visualizer)"
                      >
                        <path
                          id="_18-2"
                          data-name="18"
                          d="M56.425,451.122c-4.481,2.868-11.172,4.242-16.43,4.9-10.933,1.314-16.848-9.619-21.807-17.266-2.808-4.242-5.915-8.663-4.959-14.04,1.135-6.154,9.38-12.845,15.115-14.518,2.031-.6,4.182-.3,6.273-.6,11.352-1.494,26.527.358,32.262,11.83C71.719,431.108,64.729,445.745,56.425,451.122Z"
                          transform="translate(-13.052 -409.153)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(18))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-16"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(16.291 9.682)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-16"
                          data-name="26"
                          transform="translate(20.791 27.182)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >18</tspan>
                        </text>
                      </g>
                      <g
                        id="_17"
                        style="cursor: pointer;"
                        data-name="17"
                        transform="translate(759.105 391.019)"
                        @click="clickToothTask(17, visualizer)"
                      >
                        <path
                          id="_17-2"
                          data-name="17"
                          d="M66.56,352.631c-3.465,10.455-8.125,18.64-19.9,20.851-9.38,1.792-21.09.836-28.08-6.213-3.226-3.286-4.481-8.006-5.915-12.248-1.494-4.421-4.3-9.2-3.943-14.04.538-6.751,6.213-9.918,11.352-13.144,2.808-1.733,5.676-3.7,8.962-4.3a10.29,10.29,0,0,1,2.33-.239,53.324,53.324,0,0,1,6.93.6c7.707.836,19.417,1.434,24.914,7.827,2.33,2.748,5.2,6.811,5.736,10.455C69.488,345.641,67.635,349.4,66.56,352.631Z"
                          transform="translate(-8.696 -323.29)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(17))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-17"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(17.894 12.981)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-17"
                          data-name="26"
                          transform="translate(22.394 30.481)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >17</tspan>
                        </text>
                      </g>
                      <g
                        id="_16"
                        style="cursor: pointer;"
                        data-name="16"
                        transform="translate(764.34 337.688)"
                        @click="clickToothTask(16, visualizer)"
                      >
                        <path
                          id="_16-2"
                          data-name="16"
                          d="M75.524,265.137c-.657,2.868-.119,5.8-1.016,8.663-2.151,7.11-9.559,13.682-16.908,15.056-3.7.657-6.811,0-10.455-.717-9.738-1.912-20.911-7.289-25.272-16.729-3.166-6.871-6.333-18.461-2.987-25.69,1.792-3.883,5.2-6.333,8.842-8.424a19.237,19.237,0,0,1,6.572-2.748c10.575-1.912,20.194,1.733,29.574,5.855,3.405,1.434,7.886,1.673,10.157,5.019C78.451,251.874,77.017,258.147,75.524,265.137Z"
                          transform="translate(-17.457 -234.025)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(16))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-18"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(17.661 15.312)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-18"
                          data-name="26"
                          transform="translate(22.16 32.812)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >16</tspan>
                        </text>
                      </g>
                      <g
                        id="_15"
                        style="cursor: pointer;"
                        data-name="15"
                        transform="translate(777.354 302.822)"
                        @click="clickToothTask(15, visualizer)"
                      >
                        <path
                          id="_15-2"
                          data-name="15"
                          d="M69.715,211.893c-6.99.538-18.7-1.255-23.778-6.572-1.852-1.852-1.494-3.824-2.509-5.915-1.374-2.868-3.764-4.66-4.122-8.066-1.016-9.738,10.276-15.115,18.461-15.653,3.525-.239,6.811,1.912,9.858,3.286,2.151.956,4.481,1.255,6.572,2.27A25.237,25.237,0,0,1,85.727,193.97C89.491,203.469,78.079,211.3,69.715,211.893Z"
                          transform="translate(-39.241 -175.669)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(15))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-19"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(11.646 6.177)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-19"
                          data-name="26"
                          transform="translate(17.146 23.677)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >15</tspan>
                        </text>
                      </g>
                      <g
                        id="_14"
                        style="cursor: pointer;"
                        data-name="14"
                        transform="translate(792.035 267.585)"
                        @click="clickToothTask(14, visualizer)"
                      >
                        <path
                          id="_14-2"
                          data-name="14"
                          d="M105.3,149.794a11.791,11.791,0,0,1-4.541,4.361c-2.868,1.494-6.452,2.031-9.559,2.689-6.393,1.314-11.053-1.075-16.729-3.644-2.45-1.135-5.5-2.031-7.11-4.3-2.211-3.047-1.494-5.915-2.33-9.26-1.075-4.182-2.091-7.767,0-11.949,2.211-4.421,5.736-4.481,9.44-6.811,2.27-1.434,3.764-3.465,6.572-4.063,5.258-1.135,11.113,5.676,14.7,8.723,1.852,1.673,4.122,2.868,5.915,4.6C107.029,135.515,109.3,142.923,105.3,149.794Z"
                          transform="translate(-63.812 -116.689)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(14))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-20"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(8.966 9.415)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-20"
                          data-name="26"
                          transform="translate(13.466 26.915)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >14</tspan>
                        </text>
                      </g>
                      <g
                        id="_13"
                        style="cursor: pointer;"
                        data-name="13"
                        transform="translate(812.984 234.313)"
                        @click="clickToothTask(13, visualizer)"
                      >
                        <path
                          id="_13-2"
                          data-name="13"
                          d="M133.213,107.721c-2.868.418-6.154-.478-8.962-.956-10.157-1.673-20.97-5.975-24.794-16.549-1.553-4.3.358-7.707,1.673-11.65,1.075-3.107,1.314-7.11,3.226-9.858,2.569-3.7,7.767-4.481,11.77-5.8A32.871,32.871,0,0,1,122.4,61.06a6.493,6.493,0,0,1,1.075-.06c5.2,0,8.663,4.3,10.993,8.544a66.059,66.059,0,0,1,7.767,24.5C143.071,100.85,140.561,106.7,133.213,107.721Z"
                          transform="translate(-98.876 -61)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(13))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-21"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(9.016 11.687)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-21"
                          data-name="26"
                          transform="translate(14.516 29.187)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >13</tspan>
                        </text>
                      </g>
                      <g
                        id="_12"
                        style="cursor: pointer;"
                        data-name="12"
                        transform="translate(845.875 216.477)"
                        @click="clickToothTask(12, visualizer)"
                      >
                        <path
                          id="_12-2"
                          data-name="12"
                          d="M186.621,64.873c-.956,3.824-2.151,8.245-6.632,9.081-6.154,1.195-10.157-2.748-14.339-6.632-5.377-4.959-13.024-11.889-11.531-20.313.418-2.33,2.569-4.063,4.063-5.676A34.188,34.188,0,0,1,177.062,31.3c5.258-.956,8.125,2.689,9.38,7.289A54.359,54.359,0,0,1,186.621,64.873Z"
                          transform="translate(-153.93 -31.143)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(12))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-22"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(7.124 8.524)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-22"
                          data-name="26"
                          transform="translate(12.624 26.024)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >12</tspan>
                        </text>
                      </g>
                      <g
                        id="_11"
                        style="cursor: pointer;"
                        data-name="11"
                        transform="translate(878.869 203.48)"
                        @click="clickToothTask(11, visualizer)"
                      >
                        <path
                          id="_11-2"
                          data-name="11"
                          d="M253.9,25.527c-.9,3.644-2.031,7.169-3.167,10.754-2.629,8.424-4,22.046-14.936,23.48-6.93.956-10.933-8.066-14.936-12.128-8.544-8.663-17.684-21.628-6.572-32.262a16.544,16.544,0,0,1,5.975-3.405,45.1,45.1,0,0,1,10.455-2.45c1.733-.179,3.585.179,5.377.179s4.122-.239,6.452-.3c3.943-.06,8.006.3,10.1,2.987C255.453,16.028,254.915,21.345,253.9,25.527Z"
                          transform="translate(-209.155 -9.39)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(11))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-23"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(11.13 8.52)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-23"
                          data-name="26"
                          transform="translate(17.63 26.02)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >11</tspan>
                        </text>
                      </g>
                      <g
                        id="_21"
                        style="cursor: pointer;"
                        data-name="21"
                        transform="translate(926.068 203.001)"
                        @click="clickToothTask(21, visualizer)"
                      >
                        <path
                          id="_21-2"
                          data-name="21"
                          d="M322.015,46.833c-4,4.063-8.006,13.084-14.936,12.128-10.933-1.434-12.307-15.056-14.936-23.48-1.135-3.585-2.27-7.11-3.166-10.754-1.016-4.182-1.553-9.5,1.255-13.144,2.091-2.689,6.154-3.047,10.1-2.987,2.33.06,4.66.3,6.452.3s3.644-.358,5.377-.179a45.1,45.1,0,0,1,10.455,2.45,16.544,16.544,0,0,1,5.975,3.405C339.7,25.205,330.558,38.17,322.015,46.833Z"
                          transform="translate(-288.156 -8.59)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(21))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-24"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(9.932 8.999)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-24"
                          data-name="26"
                          transform="translate(14.432 26.499)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >21</tspan>
                        </text>
                      </g>
                      <g
                        id="_22"
                        style="cursor: pointer;"
                        data-name="22"
                        transform="translate(970.41 215.998)"
                        @click="clickToothTask(22, visualizer)"
                      >
                        <path
                          id="_22-2"
                          data-name="22"
                          d="M384.871,66.523c-4.182,3.883-8.185,7.827-14.339,6.632-4.481-.836-5.676-5.258-6.632-9.081a54.359,54.359,0,0,1,.179-26.288c1.255-4.6,4.122-8.245,9.38-7.289a34.188,34.188,0,0,1,18.879,10.037c1.494,1.613,3.644,3.346,4.063,5.676C397.9,54.634,390.248,61.564,384.871,66.523Z"
                          transform="translate(-362.376 -30.343)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(22))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-25"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(3.589 9.002)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-25"
                          data-name="26"
                          transform="translate(7.089 26.502)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >22</tspan>
                        </text>
                      </g>
                      <g
                        id="_23"
                        style="cursor: pointer;"
                        data-name="23"
                        transform="translate(994.008 233.836)"
                        @click="clickToothTask(23, visualizer)"
                      >
                        <path
                          id="_23-2"
                          data-name="23"
                          d="M444.8,89.415c-3.824,10.575-14.638,14.876-24.794,16.549-2.808.478-6.094,1.374-8.962.956-7.349-1.016-9.858-6.871-9.021-13.682a66.058,66.058,0,0,1,7.767-24.5c2.33-4.242,5.8-8.544,10.993-8.544a6.493,6.493,0,0,1,1.075.06,32.87,32.87,0,0,1,6.273,1.852c4,1.314,9.2,2.091,11.77,5.8,1.912,2.748,2.151,6.751,3.226,9.858C444.443,81.708,446.355,85.114,444.8,89.415Z"
                          transform="translate(-401.87 -60.2)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(23))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-26"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(8.993 12.164)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_23-3"
                          data-name="23"
                          transform="translate(12.493 29.664)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >23</tspan>
                        </text>
                      </g>
                      <g
                        id="_24"
                        style="cursor: pointer;"
                        data-name="24"
                        transform="translate(1014.949 267.107)"
                        @click="clickToothTask(24, visualizer)"
                      >
                        <path
                          id="_24-2"
                          data-name="24"
                          d="M479.226,138.837c-.836,3.346-.119,6.213-2.33,9.26-1.613,2.27-4.66,3.166-7.11,4.3-5.676,2.569-10.336,4.959-16.729,3.644-3.107-.657-6.691-1.195-9.559-2.689a11.791,11.791,0,0,1-4.541-4.361c-4-6.871-1.733-14.279,3.644-19.656,1.792-1.733,4.063-2.927,5.915-4.6,3.585-3.047,9.44-9.858,14.7-8.723,2.808.6,4.3,2.629,6.572,4.063,3.7,2.33,7.229,2.39,9.44,6.811C481.317,131.071,480.3,134.655,479.226,138.837Z"
                          transform="translate(-436.924 -115.889)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(24))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-27"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(10.05 9.893)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_24-3"
                          data-name="24"
                          transform="translate(13.55 27.393)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >24</tspan>
                        </text>
                      </g>
                      <g
                        id="_25"
                        style="cursor: pointer;"
                        data-name="25"
                        transform="translate(1025.924 302.345)"
                        @click="clickToothTask(25, visualizer)"
                      >
                        <path
                          id="_25-2"
                          data-name="25"
                          d="M502.452,190.541c-.359,3.405-2.748,5.2-4.122,8.066-1.016,2.091-.657,4.063-2.509,5.915-5.078,5.317-16.788,7.11-23.778,6.572-8.364-.6-19.776-8.424-16.012-17.923a25.237,25.237,0,0,1,11.531-12.726c2.091-1.016,4.421-1.314,6.572-2.27,3.047-1.374,6.333-3.525,9.858-3.286C492.175,175.425,503.467,180.8,502.452,190.541Z"
                          transform="translate(-455.29 -174.869)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(25))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-28"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(12.077 6.655)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-26"
                          data-name="26"
                          transform="translate(15.577 24.155)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >25</tspan>
                        </text>
                      </g>
                      <g
                        id="_26-27"
                        style="cursor: pointer;"
                        data-name="26"
                        transform="translate(1026.625 337.209)"
                        @click="clickToothTask(26, visualizer)"
                      >
                        <path
                          id="_26-28"
                          data-name="26"
                          d="M511.588,270.61c-4.361,9.44-15.534,14.817-25.272,16.729-3.644.717-6.751,1.374-10.455.717-7.349-1.374-14.757-7.946-16.908-15.056-.9-2.868-.358-5.8-1.016-8.663-1.494-6.99-2.927-13.263,1.494-19.716,2.27-3.346,6.751-3.585,10.157-5.019,9.38-4.122,19-7.767,29.574-5.855a19.235,19.235,0,0,1,6.572,2.748c3.644,2.091,7.05,4.541,8.842,8.424C517.921,252.149,514.754,263.74,511.588,270.61Z"
                          transform="translate(-456.465 -233.225)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(26))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-29"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(18.374 15.791)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-29"
                          data-name="26"
                          transform="translate(21.874 33.291)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >26</tspan>
                        </text>
                      </g>
                      <g
                        id="_27"
                        style="cursor: pointer;"
                        data-name="27"
                        transform="translate(1031.047 390.541)"
                        @click="clickToothTask(27, visualizer)"
                      >
                        <path
                          id="_27-2"
                          data-name="27"
                          d="M520.243,354.221c-1.434,4.242-2.688,8.962-5.915,12.248-6.99,7.05-18.7,8.006-28.08,6.214-11.77-2.211-16.43-10.4-19.9-20.851-1.075-3.226-2.928-6.99-2.39-10.455.538-3.644,3.405-7.707,5.736-10.455,5.5-6.393,17.207-6.99,24.914-7.827a53.339,53.339,0,0,1,6.93-.6,10.29,10.29,0,0,1,2.33.239c3.286.6,6.154,2.569,8.962,4.3,5.138,3.226,10.814,6.393,11.352,13.144C524.545,345.02,521.737,349.8,520.243,354.221Z"
                          transform="translate(-463.868 -322.49)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(27))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-30"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(17.953 13.459)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-30"
                          data-name="26"
                          transform="translate(21.453 30.959)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >27</tspan>
                        </text>
                      </g>
                      <g
                        id="_28"
                        style="cursor: pointer;"
                        data-name="28"
                        transform="translate(1033.379 441.84)"
                        @click="clickToothTask(28, visualizer)"
                      >
                        <path
                          id="_28-2"
                          data-name="28"
                          d="M518.049,437.955c-4.959,7.647-10.874,18.581-21.807,17.266-5.258-.657-11.949-2.031-16.43-4.9-8.3-5.377-15.295-20.015-10.455-29.693,5.736-11.471,20.911-13.323,32.262-11.829,2.091.3,4.242,0,6.273.6,5.736,1.673,13.98,8.364,15.115,14.518C523.964,429.292,520.857,433.713,518.049,437.955Z"
                          transform="translate(-467.767 -408.353)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(28))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-31"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(15.622 10.16)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-31"
                          data-name="26"
                          transform="translate(19.122 27.66)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >28</tspan>
                        </text>
                      </g>
                      <g
                        id="_41"
                        style="cursor: pointer;"
                        data-name="41"
                        transform="translate(891.377 748.908)"
                        @click="clickToothTask(41, visualizer)"
                      >
                        <path
                          id="_41-2"
                          data-name="41"
                          d="M264.059,953.614c.06,4-2.091,6.034-5.855,6.512a49.184,49.184,0,0,1-20.612-2.33c-2.39-.777-5.676-1.792-6.93-4.182-1.673-3.226.717-6.691,2.031-9.559,1.733-3.764,3.824-7.349,5.736-11.053,2.45-4.72,4.6-9.918,10.515-10.635,4.063-.478,6.452,2.629,8.185,5.855,2.569,4.959,3.405,9.858,4.66,15.235C262.565,946.8,263.939,950.148,264.059,953.614Z"
                          transform="translate(-230.088 -922.318)"
                          :fill="[
                            visualizer.hasOwnProperty('teeths') &&
                              visualizer.teeths.some((el) => el.id.includes(41))
                              ? '#a08ec2'
                              : '#FFF',
                          ]"
                          stroke="#7f7f7f"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                        <circle
                          id="Ellipse_9-32"
                          data-name="Ellipse 9"
                          cx="12"
                          cy="12"
                          r="12"
                          transform="translate(6.623 10.092)"
                          fill="none"
                          stroke="#a08ec2"
                          stroke-width="2"
                          opacity="0"
                        />
                        <text
                          id="_26-32"
                          data-name="26"
                          transform="translate(10.123 27.592)"
                          fill="#502e8a"
                          font-size="15"
                          font-family="Montserrat-Medium, Montserrat"
                          font-weight="500"
                        >
                          <tspan
                            x="0"
                            y="0"
                          >41</tspan>
                        </text>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import mainLayout from "../layout/mainLayout.vue";
import leftMenu from "../layout/leftMenu.vue";
import project from "../../repository/projectRepository.js";
import tags from "../../repository/tagsRepository.js";
import users from "../../repository/userRepository.js";
import operator from "../../repository/operatorRepository.js";
import admin from "../../repository/adminRepository.js";
import TaskList from "../components/TaskList";
import ArchiveCard from "../components/ArchiveCard.vue";
import moment from "moment";

export default {
  components: {
    mainLayout,
    leftMenu,
    TaskList,
    ArchiveCard,
  },
  data() {
    return {
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      profile: {},
      isLoading: false,
      modalProfile: false,
      files: [],
      file: [],
      test: [],
      tags: [],
      tasks: [],
      visualizer: null,
      parsedYear: [],
      clickedTooth: null,
      baseTasks: {
        tasks: [],
      },
      allArticles: [],
      singleExpand: false,
      opePicker: [],
      opePickerTri: [],
      opeLabel: "Opérateur",
      columns: [
        {
          title: "Projets en cours",
          ref: "progress",
          icon: "mdi-backup-restore",
          drag: false,
          tasks: [],
        },
      ],
    };
  },
  computed: {},

  created() {
    this.initialize();
  },
  async mounted() {
    this.getAllProjects();
    const tags = await this.getCurrentTag();
    this.tags = tags.data[0];
    const user = await users.getUser(
      this.$store.state.user.email,
      this.$store.state.token
    );
    this.profile = user.data[0];
    this.operators = await this.getAllOperators();
    this.operators = this.operators.data;
    this.operators.forEach((op) => {
      this.opePickerTri.push(op.firstName + " " + op.lastName);
    });
  },
  methods: {
    initialize() {
      this.tasks;
    },
    visualizeFromChild: function (k) {
      this.visualizer = k;
      this.$forceUpdate();
    },
    returnCorrespondingArticle: function (iterator) {
      if (iterator.tasks) {
        const findCorrespondingArticle = iterator.tasks[0];
        //
        return findCorrespondingArticle;
      } else {
        return null;
      }
    },
    returnClickedTooth: function (iterator) {
      if (iterator.tasks) {
        return iterator.tasks[0].teethNbr;
        // return iterator.tasks.tasks[0].teethNbr;
      } else {
        return null;
      }
    },
    getName(firstName, lastName) {
      if (firstName.substr(0, 2) == "Dr") {
        const first = firstName.substr(3, 1);
        const second = lastName.substr(0, 1);
        return first.toUpperCase() + second.toUpperCase();
      } else {
        const first = firstName.substr(0, 1);
        const second = lastName.substr(0, 1);
        return first.toUpperCase() + second.toUpperCase();
      }
    },
    openModal() {
      this.modalProfile = true;
    },
    async closeModal() {
      this.modalProfile = false;
      this.file = [];
      const user = await users.getUser(
        this.$store.state.user.email,
        this.$store.state.token
      );
      this.profile = user.data[0];
    },
    async savePicture() {
      //this.files = this.fileToBase64(this.file)
      const base64 = await this.fileToBase64(this.file);
      Object.assign(this.profile, { file: base64 });
      this.isLoading = true;
      await users.putUser(this.profile);
      this.modalProfile = false;
      const user = await users.getUser(
        this.$store.state.user.email,
        this.$store.state.token
      );
      this.isLoading = false;
      this.profile = user.data[0];
    },
    fileToBase64(file) {
      return new Promise((resolve) => {
        let fileReader = new FileReader();
        let base64;
        fileReader.onload = (fileLoadedEvent) => {
          base64 = fileLoadedEvent.target.result;
          resolve(base64);
        };
        fileReader.readAsDataURL(file);
      });
    },
    async getCurrentTag() {
      return await tags.getTags();
    },
    clickToothTask: async function (nbr) {
      if (this.expanded && this.expanded[0].tasks) {
        const findCorrespondingArticle = this.expanded[0].tasks.find(
          (o) => o.teethNbr === nbr
        );
        //
        this.baseTasks = findCorrespondingArticle;
        this.clickedTooth = nbr;
      }
    },

    getTagColor(tagName) {
      if (tagName.length > 0) {
        const color = this.tags.tag.filter((tag) => tag.name == tagName);
        if (color.length > 0) return color[0].color;
      }
    },
    getTagTextColor(tagName) {
      if (tagName.length > 0) {
        const color = this.tags.tag.filter((tag) => tag.name == tagName);
        if (color.length > 0) return color[0].colorText;
      }
    },
    checkMove(e) {
      if (e.from.id === "progress" && e.to.id === "backlog") {
        return false;
      }
    },
    drop(event) {
      if (event.from.id === "progress" && event.to.id === "backlog") {
        this.tasks.forEach((task) => {
          if (task.cas === event.item.attributes.id.value) {
            task.operator = "";
            project.putProject(task);
          }
        });
      }
      if (event.from.id === "backlog" && event.to.id === "progress") {
        this.tasks.forEach((task) => {
          if (task.cas === event.item.attributes.id.value) {
            task.operator =
              this.$store.state.user.firstName +
              " " +
              this.$store.state.user.lastName;
            project.putProject(task);
          }
        });
      }
    },
    async getAllOperators() {
      const adminOperateur = await admin.getAdmins();
      const operatorData = await operator.getOperators();
      adminOperateur.data.forEach((admin) => {
        operatorData.data.push(admin);
      });
      return operatorData;
    },
    async getAllProjects() {
      this.columns[0].tasks = [];
      this.projects = await project.getProjects();
      this.tasks = this.projects.data;
      //
      var sources = this.projects.data
        .filter(function (x) {
          if (!x.isArchived || x.isArchived === false) {
            return false; // skip
          }
          return true;
        })
        .map(function (x) {
          return x;
        });
      //
      this.createDateCards(sources);

      this.tasks = sources;
    },
    createDateCards(src) {
      const whatYear = new Date().getFullYear();
      const currentYear = [
        {
          id: whatYear,
          "01": {
            name: "Janvier",
            tasks: [],
          },
          "02": {
            name: "Février",
            tasks: [],
          },
          "03": {
            name: "Mars",
            tasks: [],
          },
          "04": {
            name: "Avril",
            tasks: [],
          },
          "05": {
            name: "Mai",
            tasks: [],
          },
          "06": {
            name: "Juin",
            tasks: [],
          },
          "07": {
            name: "Juillet",
            tasks: [],
          },
          "08": {
            name: "Août",
            tasks: [],
          },
          "09": {
            name: "Septembre",
            tasks: [],
          },
          10: {
            name: "Octobre",
            tasks: [],
          },
          11: {
            name: "Novembre",
            tasks: [],
          },
          12: {
            name: "Décembre",
            tasks: [],
          },
        },
      ];
      src.forEach((element) => {
        if (element.endDate) {
          const parsed = moment(element.endDate, "DD/MM/YYYY");
          //

          const position = parsed.format("MM");
          currentYear[0][position.toString()].tasks.push(element);
        }
      });
      //
      this.parsedYear = currentYear;
    },
  },
};
</script>

<style>
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vizu {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-top: 1.33rem;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 50vh;
  width: 100%;
  height: fit-content;
}

.title-archived {
  display: flex;
  align-content: center;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.archived-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0rem 2rem;
  padding-top: 1rem;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.homeContent {
  padding-top: 100px;
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.idCard {
  min-width: 15%;
  margin-left: 1rem;
}

.subheading-profile {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text {
  justify-content: center;
}
.mainContent {
  display: flex;
  flex-direction: row;
}
.leftMenu {
  width: 5%;
}
.rounded {
  border-radius: 15px !important;
}
.operateurContent {
  margin-top: 100px;
  margin-left: 0px;
  margin-right: 15px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 99%;
  flex-direction: row;
}
.operator-column {
  min-width: 33%;
  max-width: 70%;
}
.initial {
  color: white;
  font-size: 15px;
  margin-top: 15px;
}
.kanban {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.test {
  display: flex;
  flex-direction: row;
}
/* .avatar:hover {
  opacity: 0.7;
} */
.avatar {
  z-index: 1;
}
.label {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  bottom: -50px;
  color: #fff;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  text-align: center;
  transition: 0.1s all;
  z-index: 100;
}

.avatar:hover .label {
  bottom: -20px;
}
.inital:hover .label {
  bottom: -20px;
}
.zone {
  height: 800px;
}
.cardHome {
  display: flex;
  border-radius: 25px !important;
  width: 350px;
  max-height: 71px;
  width: 100%;
}
.dragcontent {
  display: flex;
  justify-content: center;
  min-height: 45%;
  overflow: scroll;
  max-height: 100%;
  height: 90%;
  overflow-x: hidden;
}
.card {
  width: 250px;
  height: 31%;
  margin: 10px;
}
.modalTitle {
  display: flex;
  justify-content: space-between;
}
.iconDuring {
  width: 51px;
  height: 47px;
  background: #3498db40 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin: 10px;
}
#backlog {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
#progress {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
.bg-white
  shadow
  .rounded-xl
  .px-3
  .pt-2
  .pb-2
  .border
  .border-white
  .card
  .mt-5
  .mb-2
  .cursor-move {
  height: 100% !important;
}
.iconAttente {
  width: 51px;
  height: 47px;
  background: #f39c1240 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin: 10px;
}
.idCardAdmin {
  min-width: 16%;
  max-width: 25%;
  margin-left: 8.33%;
}
@media screen and (min-width: 1500px) {
  .card {
    width: 300px;
  }
}
@media screen and (max-width: 900px) {
  .leftMenu {
    display: none;
  }
  .casTable {
    width: 100%;
  }
  .kanban {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .operateurContent {
    overflow-x: scroll;
    width: 100%;
    display: flex;
    height: 99%;
    flex-direction: column;
    margin-top: 80px !important;
    align-items: center;
    margin: 0px;
  }
  .dragcontent {
    display: flex;
    justify-content: center;
    overflow: scroll;
    max-height: 83%;
  }
  .zone {
    width: 300px;
    height: 550px;
    margin-top: 10px;
  }
  .card {
    width: 250px;
  }
  .idCardAdmin {
    min-width: 15%;
    max-width: 60%;
  }
}
@media screen and (max-width: 800px) {
  .operateurContent {
    overflow-x: scroll;
    width: 100%;
    display: flex;
    height: 99%;
    flex-direction: row;
    margin-top: 80px !important;
    margin: 0px;
    flex-direction: column;
    align-items: center;
  }
  .operator-column {
    width: 100% !important;
  }
  .card {
    height: 50%;
  }
}
</style>
