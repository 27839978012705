<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateFormatted"
          label="Date de fin *"
          persistent-hint
          prepend-icon="mdi-calendar"
          required
          v-bind="attrs"
          :rules="[(v) => !!v || 'Champ requis']"
          :disabled="getUserStatus()"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="date"
        no-title
        scrollable
        locale="fr-FR"
        :first-day-of-week="1"
        @change="onChange(date, $refs.menu)"
      >
        <v-spacer />
        <v-btn text color="#003b5b" @click="menu = false"> Annuler </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
//import moment from 'moment'
export default {
  props: {
    method: { type: Function },
    getEndDate: String,
  },
  data: (vm) => ({
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    menu: false,
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    getEndDate(newDate) {
      if (newDate) {
        this.dateFormatted = newDate;
      } else {
        this.dateFormatted = this.formatDate(
          new Date().toISOString().substr(0, 10)
        );
      }
    },
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  mounted() {
    if (this.getEndDate) {
      this.dateFormatted = this.getEndDate;
    } else {
      this.dateFormatted = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    }
  },
  methods: {
    onChange(date, menu) {
      const formatDate = this.formatDate(date);
      menu.save(formatDate);
      this.method(formatDate);
    },
    getUserStatus() {
      if (
        this.$store.state.user.status === "admin" ||
        this.$store.state.user.status === "praticien"
      ) {
        return false;
      }
      return true;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>
