<template>
  <div>
    <mainLayout />
    <checkAcces pageAccess="admin" />
    <div class="contentAdmin mainContent">
      <leftMenu class="leftMenu" />
      <CasTable class="casTable" v-bind:global="false" />
    </div>
  </div>
</template>

<script>
import CasTable from "../components/casTable.vue";
import checkAcces from "../components/checkAcces.vue";
import mainLayout from "../layout/mainLayout.vue";
import leftMenu from "../layout/leftMenu.vue";

export default {
  components: {
    CasTable,
    mainLayout,
    leftMenu,
    checkAcces,
  },
};
</script>

<style scoped>
.contentAdmin {
  display: flex;
  flex-direction: row;
}
.mainContent {
  display: flex;
  flex-direction: row;
}
.leftMenu {
  width: 5%;
}
.casTable {
  width: 95%;
  padding: 100px 20px 0px 20px;
  background: #fafafa;
}
@media screen and (max-width: 900px) {
  .leftMenu {
    display: none;
  }
  .casTable {
    width: 100%;
  }
}
</style>
