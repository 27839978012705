<template>
  <v-container class="UserManagmentContent">
    <v-snackbar
      v-model="snackbar.visible"
      auto-height
      :color="snackbar.color"
      :multi-line="snackbar.mode === 'multi-line'"
      :timeout="snackbar.timeout"
      :top="snackbar.position === 'top'"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>
          {{ snackbar.icon }}
        </v-icon>
        <v-layout column>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
    <v-data-iterator
      :items="operators"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar light color="white" class="mb-1 customToolbar">
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Rechercher un utilisateur..."
          />
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer />
            <v-spacer />
            <v-btn
              color="#003b5b"
              outlined
              dark
              class="addCasBtn"
              @click="openModal"
            >
              {{ addUserName }}
              <v-icon class="ml-3"> mdi-plus-circle-outline </v-icon>
            </v-btn>
          </template>
        </v-toolbar>
      </template>

      <template>
        <!-- v-slot="props" -->
        <v-row class="test">
          <!-- //props.operators -->
          <v-col
            v-for="item in operators"
            :key="item.id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card class="rounded">
              <v-card-title
                v-if="userStatus !== 'labo'"
                class="subheading font-weight-bold"
              >
                {{ item.firstName }} {{ item.lastName }}
              </v-card-title>
              <v-card-title
                v-if="userStatus === 'labo'"
                class="subheading font-weight-bold"
              >
                {{ item.laboName }}
              </v-card-title>
              <div
                v-if="
                  item.firstName !== undefined && item.lastName !== undefined
                "
                class="user-avatar"
              >
                <p class="initial">
                  {{ getName(item.firstName, item.lastName) }}
                </p>
              </div>
              <v-list-item>
                <v-list-item-content>
                  {{ item.status }}
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <v-btn outlined rounded text @click="editItem(item)">
                  Détails
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row class="mt-2" align="center" justify="center">
          <v-spacer />
        </v-row>
      </template>
    </v-data-iterator>
    <v-dialog v-model="modalAdd" max-width="1200px">
      <v-card>
        <v-card-title class="modalTitle">
          <span class="headline">
            {{ formTitle }}
            <v-icon class="modalIcon" medium> {{ icon }} </v-icon>
          </span>
          <v-icon large @click="closeModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <div class="modalForm">
              <div class="modalFormInput">
                <v-text-field
                  v-if="this.userStatus === 'labo'"
                  v-model="editedItem.laboName"
                  :rules="[rules.required]"
                  label="Nom du laboratoire"
                  required
                >
                  <v-icon slot="prepend" color="#1f6ed5"> mdi-account </v-icon>
                </v-text-field>
                <v-text-field
                  v-if="this.userStatus !== 'labo'"
                  v-model="editedItem.lastName"
                  :rules="[rules.required]"
                  label="Nom"
                  required
                >
                  <v-icon slot="prepend" color="#1f6ed5"> mdi-account </v-icon>
                </v-text-field>
                <v-text-field
                  v-if="this.userStatus !== 'labo'"
                  v-model="editedItem.firstName"
                  :rules="[rules.required]"
                  label="Prénom"
                >
                  <v-icon slot="prepend" color="#1f6ed5"> mdi-account </v-icon>
                </v-text-field>
                <v-text-field
                  v-if="this.userStatus === 'labo'"
                  v-model="editedItem.firstName"
                  :rules="[rules.required]"
                  label="Prénom de l'administrateur"
                  :disabled="isEditModal()"
                >
                  <v-icon slot="prepend" color="#1f6ed5"> mdi-account </v-icon>
                </v-text-field>
                <v-text-field
                  v-if="this.userStatus === 'labo'"
                  v-model="editedItem.lastName"
                  :rules="[rules.required]"
                  label="Nom de l'administrateur"
                  :disabled="isEditModal()"
                >
                  <v-icon slot="prepend" color="#1f6ed5"> mdi-account </v-icon>
                </v-text-field>
                <v-text-field
                  v-model="editedItem.email"
                  :rules="[rules.email, rules.required]"
                  label="Email"
                  :disabled="isEditModal()"
                >
                  <v-icon slot="prepend" color="#1f6ed5"> mdi-at </v-icon>
                </v-text-field>
              </div>
              <div class="modalFormInputStatus">
                <v-select
                  v-if="this.userStatus !== 'labo'"
                  v-model="editedItem.status"
                  return-object
                  :items="statusPicker"
                  label="Status"
                  item-text="label"
                  item-value="value"
                  v-show="showStatusInput"
                  clearable
                  :disabled="isEditModal()"
                  @change="showStatus"
                >
                  <v-icon slot="prepend" color="#1f6ed5">
                    mdi-tag-outline
                  </v-icon>
                </v-select>
                <v-select
                  v-if="this.userStatus === 'labo'"
                  v-model="editedItem.tags"
                  return-object
                  :items="subscriptPicker"
                  label="Abonnement"
                  item-text="label"
                  item-value="value"
                  clearable
                  @change="showStatus"
                >
                  <v-icon slot="prepend" color="#1f6ed5">
                    mdi-tag-outline
                  </v-icon>
                </v-select>
                <v-select
                  v-show="show2"
                  v-model="editedItem.tags"
                  persistent-hint
                  return-object
                  single-line
                  multiple
                  :items="tagPicker"
                  label="Tag(s)"
                  clearable
                >
                  <v-icon slot="prepend" color="#1f6ed5">
                    mdi-tag-outline
                  </v-icon>
                </v-select>
                <v-checkbox
                  v-show="show3"
                  v-model="editedItem.send"
                  label="Cochez pour recevoir des emails de notifications"
                >
                  <v-icon slot="prepend" color="#1f6ed5"> mdi-email </v-icon>
                </v-checkbox>
                <v-checkbox
                  v-if="this.userStatus === 'labo'"
                  v-model="editedItem.send"
                  label="Compte actif ? "
                >
                  <v-icon slot="prepend" color="#1f6ed5"> mdi-lock </v-icon>
                </v-checkbox>
              </div>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions class="saveBtnCard">
          <div :style="addingModal">
            <v-btn
              class="greenBtn"
              color="green darken-1"
              text
              @click="checkSubscription"
            >
              Ajouter le collaborateur
            </v-btn>
          </div>
          <div :style="changeModal">
            <v-btn
              class="greenBtn"
              color="green darken-1"
              text
              @click="putUser"
            >
              Sauvegarder
            </v-btn>
            <v-btn
              class="deleteBtn"
              color="red error"
              text
              @click="deleteCAS()"
            >
              Supprimer le collaborateur
            </v-btn>
          </div>
        </v-card-actions>
        <v-dialog v-model="dialogDelete" max-width="600px" max-heigth="300px">
          <v-card>
            <v-card-title class="headline">
              Voulez-vous supprimer le collaborateur ?
            </v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn
                class="deleteBtn"
                color="red darken-1"
                text
                @click="closeDelete"
              >
                Non
              </v-btn>
              <v-btn
                class="greenBtn"
                color="green darken-1"
                text
                @click="deleteItemConfirm()"
              >
                Oui
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import operator from "../../repository/operatorRepository.js";
import praticien from "../../repository/praticienRepository.js";
import admins from "../../repository/adminRepository.js";
import user from "../../repository/userRepository.js";
import tags from "../../repository/tagsRepository.js";
import loginRepo from "../../repository/loginRepository.js";
import laboRepo from "../../repository/laboRepository.js";
//import project from "../../repository/projectRepository.js"
import { API_BASE_URL, API_ROUTES } from "../../api-constant";
import axios from "axios";
import store from "../../store";

export default {
  props: {
    userStatus: String,
  },
  data() {
    return {
      showStatusInput: true,
      itemsPerPageArray: [4, 8, 12],
      keys: [],
      search: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 8,
      sortBy: "name",
      operators: [],
      modalAdd: false,
      labo: [],
      show1: false,
      show2: false,
      show3: false,
      dialogDelete: false,
      editedIndex: -1,
      defaultItem: {
        lastName: "",
        firstName: "",
        email: "",
        password: "",
        status: "",
        tags: [],
        send: false,
      },
      editedItem: {
        lastName: "",
        firstName: "",
        email: "",
        password: "",
        status: "",
        tags: [],
        send: false,
      },
      statusPicker: [
        { label: "Praticien", value: "praticien" },
        { label: "Administrateur", value: "admin" },
        { label: "Opérateur", value: "operateur" },
        { label: "Ecran d'affichage", value: "ecran" },
      ],
      subscriptPicker: [
        { label: "Première class", value: "firstClass" },
        { label: "Business Class", value: "businessClass" },
        { label: "Class economique", value: "ecoClass" },
      ],
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 7500,
        visible: false,
      },
      timeout: 7500,
      tagPicker: [],
      rules: {
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Format d'email incorrect.";
        },
        required: (val) => val.length > 0 || "Ce champ est requis",
      },
    };
  },
  computed: {
    /*     numberOfPages() {
      return Math.ceil(this.operators.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    }, */
    changeModal() {
      return this.editedIndex === -1 ? "display: none" : "";
    },
    addingModal() {
      return this.editedIndex === 1 ? "display: none" : "";
    },
    formTitle() {
      if (this.editedIndex === -1) {
        if (this.userStatus === "admin") {
          return "Ajouter un collaborateur";
        } else if (this.userStatus === "ecran") {
          return "Ajouter un écran";
        } else if (this.userStatus === "labo") {
          return "Ajouter un laboratoire";
        } else {
          return "Ajouter un praticien";
        }
      } else {
        if (this.userStatus === "admin") {
          return (
            "Modifier le collaborateur " +
            this.editedItem.firstName +
            " " +
            this.editedItem.lastName
          );
        } else if (this.userStatus === "ecran") {
          return (
            "Modifier l'écran " +
            this.editedItem.firstName +
            " " +
            this.editedItem.lastName
          );
        } else if (this.userStatus === "labo") {
          return "Modifier un laboratoire";
        } else {
          return (
            "Modifier le praticien " +
            this.editedItem.firstName +
            " " +
            this.editedItem.lastName
          );
        }
      }
    },
    icon() {
      return this.editedIndex === -1
        ? " mdi-account-plus "
        : " mdi-pencil-outline ";
    },
    addUserName() {
      if (this.userStatus === "admin") {
        return "Ajouter un collaborateur";
      } else if (this.userStatus === "ecran") {
        return "Ajouter un écran";
      } else if (this.userStatus === "labo") {
        return "Ajouter un laboratoire";
      } else {
        return "Ajouter un praticien";
      }
    },
  },
  async mounted() {
    this.getAllUsers();
    if (this.userStatus === "labo") {
      this.getAllLabo();
    }
    this.statusPicker = this.statusPicker.filter((status) => {
      if (this.userStatus === "admin") {
        if (status.value === "admin" || status.value === "operateur") {
          return status;
        }
      } else {
        if (status.value === this.userStatus) {
          return status;
        }
      }
    });

    const tags = await this.getCurrentTag();
    this.tags = tags.data[0];
    this.tags.tag.forEach((tag) => {
      this.tagPicker.push(tag.name);
    });
    this.labo = await laboRepo.getLaboById();
  },
  methods: {
    isEditModal() {
      if (this.editedIndex === -1) {
        return false;
      } else {
        return true;
      }
    },
    SnackbarShow(text, status, isOk) {
      if (status === isOk) {
        this.snackbar = {
          color: "success",
          icon: "mdi-check-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: text,
          visible: true,
        };
      } else {
        this.snackbar = {
          color: "error",
          icon: "mdi-alert-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: "Une erreur est survenue",
          visible: true,
        };
      }
    },
    openModal() {
      this.showStatusInput = true;
      this.modalAdd = true;
      if (
        this.statusPicker[0].value === "praticien" ||
        this.statusPicker[0].value === "ecran"
      ) {
        this.showStatusInput = false;
        if (this.statusPicker[0].value === "praticien") {
          this.editedItem.status = { lablel: "Praticien", value: "praticien" };
          this.show3 = true;
        } else {
          this.editedItem.status = { lablel: "Ecran", value: "ecran" };
        }
      }
    },
    closeModal() {
      this.modalAdd = false;
      this.showStatusInput = true;
      this.show3 = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    editItem(item) {
      this.editedIndex = 1;
      if (item.send == true) {
        item.send = 1;
      } else if (item.send == false) {
        item.send = 0;
      }
      this.editedItem = Object.assign({}, item);
      const file = item.avatar;
      Object.assign(this.editedItem, { file: file });
      this.modalAdd = true;
      this.showStatus();
      this.showStatus2();
    },
    deleteCAS() {
      this.dialogDelete = true;
    },
    isEdit() {},

    async deleteTagByLaboId(laboId) {
      const tags = await axios.get(
        API_BASE_URL + API_ROUTES.getTagsByLaboId + laboId,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        }
      );
      tags.data.forEach(async (tag) => {
        await axios.delete(API_BASE_URL + API_ROUTES.tags + tag.id, {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        });
      });
    },

    async deleteProjectLaboId(laboId) {
      const projects = await axios.get(
        API_BASE_URL + API_ROUTES.getProjectsByLaboId + laboId,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        }
      );
      projects.data.forEach(async (project) => {
        await axios.delete(API_BASE_URL + API_ROUTES.projects + project.id, {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        });
      });
    },

    async getUserByLabo(laboId) {
      const request = await user.getUserByLaboId(laboId);
      request.data.forEach(async (user) => {
        this.deleteUserByStatus(user.status, user.email);
        await axios.delete(API_BASE_URL + API_ROUTES.users + user.id, {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        });
        const checkEmail = await loginRepo.checkEmailExist(
          user.email,
          store.state.token
        );
        if (checkEmail.data[0] !== undefined) {
          fetch(API_BASE_URL + "/user/" + checkEmail.data[0].id, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${store.state.token}`,
            },
          }).then((response) => {
            if (response.status !== 204) {
              this.snackbar = {
                color: "error",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 5000,
                text: "Une erreur est survenue",
                visible: true,
              };
            }
          });
        }
      });
    },

    async deleteLabo() {
      const response = await laboRepo.deleteLaboById(this.editedItem.id);
      this.SnackbarShow(
        "L'utilisateur a bien été supprimé",
        response.status,
        204
      );

      const currentUser = await user.getUser(
        this.editedItem.email,
        store.state.token
      );
      if (currentUser.data.length > 0) {
        await user.deleteUser(currentUser.data[0]);
        const checkEmail = await loginRepo.checkEmailExist(
          this.editedItem.email,
          store.state.token
        );
        if (checkEmail.data[0] !== undefined) {
          fetch(API_BASE_URL + "/user/" + checkEmail.data[0].id, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${store.state.token}`,
            },
          }).then((response) => {
            if (response.status !== 204) {
              this.snackbar = {
                color: "error",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 5000,
                text: "Une erreur est survenue",
                visible: true,
              };
            }
          });
        }
        const request = await admins.getAdminsByemail(this.editedItem.email);
        var adminId = request.data[0].id;
        await admins.deleteAdmins(adminId);
        this.getUserByLabo(request.data[0].laboId);
        this.deleteTagByLaboId(request.data[0].laboId);
        this.deleteProjectLaboId(request.data[0].laboId);
      }
      this.operators = [];
      this.getAllLabo();
      this.closeModal();
    },

    async deleteUserByStatus(status, email) {
      if (status === "operateur") {
        const request = await operator.getOperatorsByemail(email);
        var operatoremail = request.data[0].id;
        const response = await operator.deleteOperator(operatoremail);
        this.SnackbarShow(
          "L'utilisateur a bien été supprimé",
          response.status,
          204
        );
      } else if (status === "admin") {
        const request = await admins.getAdminsByemail(email);
        var adminEmail = request.data[0].id;
        const response = await admins.deleteAdmins(adminEmail);
        this.SnackbarShow(
          "L'utilisateur a bien été supprimé",
          response.status,
          204
        );
      } else if (status === "praticien") {
        const request = await praticien.getPraticiensByemail(email);
        var praticienemail = request.data[0].id;
        const response = await praticien.deletePraticien(praticienemail);
        this.SnackbarShow(
          "L'utilisateur a bien été supprimé",
          response.status,
          204
        );
      }
    },

    async deleteItemConfirm() {
      if (this.editedItem.status == "Labo") {
        this.deleteLabo();
      } else {
        await user.deleteUser(this.editedItem);
        const checkEmail = await loginRepo.checkEmailExist(
          this.editedItem.email,
          store.state.token
        );
        if (checkEmail.data[0] !== undefined) {
          fetch(API_BASE_URL + "/user/" + checkEmail.data[0].id, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${store.state.token}`,
            },
          }).then((response) => {
            if (response.status !== 204) {
              this.snackbar = {
                color: "error",
                icon: "mdi-alert-circle-outline",
                mode: "multi-line",
                position: "top",
                timeout: 5000,
                text: "Une erreur est survenue",
                visible: true,
              };
            }
          });
        }
        this.deleteUserByStatus(this.editedItem.status, this.editedItem.email);
        this.closeDelete();
        this.modalAdd = false;
        this.operators = [];
        await this.getAllUsers();
      }
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    showStatus() {
      if (this.editedItem.status !== null) {
        switch (this.editedItem.status.value) {
          case "operateur":
            this.show2 = true;
            this.show3 = false;
            this.editedItem.send = false;
            break;
          case "praticien":
            this.show3 = true;
            this.show2 = false;
            this.editedItem.tags = [];
            break;
          default:
            this.show2 = false;
            this.show3 = false;
            break;
        }
      } else {
        this.show2 = false;
        this.show3 = false;
        this.editedItem.send = false;
        this.editedItem.tags = [];
      }
    },
    showStatus2() {
      if (this.editedItem.status !== null) {
        switch (this.editedItem.status) {
          case "operateur":
            this.show2 = true;
            this.show3 = false;
            this.editedItem.send = false;
            break;
          case "admin":
            this.show2 = true;
            this.show3 = false;
            this.editedItem.send = false;
            break;
          case "praticien":
            this.show3 = true;
            this.show2 = false;
            this.editedItem.tags = [];
            break;
          default:
            this.show2 = false;
            this.show3 = false;
            break;
        }
      } else {
        this.show2 = false;
        this.show3 = false;
        this.editedItem.send = false;
        this.editedItem.tags = [];
      }
    },

    notSubscriberNotif() {
      this.snackbar = {
        color: "error",
        icon: "mdi-alert-circle-outline",
        mode: "multi-line",
        position: "top",
        timeout: 5000,
        text: "Votre abonnement ne vous permet pas d'ajouter plus d'utilisateurs.",
        visible: true,
      };
    },

    checkSubscription() {
      const subscription = this.labo.data.subscription;
      const userCount = this.operators.length;
      if (
        subscription === "firstClass" ||
        this.userStatus === "praticien" ||
        this.userStatus === "labo"
      ) {
        this.postUser();
      } else if (this.userStatus === "ecran") {
        if (subscription === "ecoClass") {
          if (userCount < 5) {
            this.postUser();
          } else {
            this.notSubscriberNotif();
          }
        } else if (subscription === "businessClass") {
          if (userCount < 10) {
            this.postUser();
          } else {
            this.notSubscriberNotif();
          }
        }
      } else if (this.userStatus === "admin") {
        const adminCount = this.operators.filter(
          (operator) => operator.status === "admin"
        ).length;
        const operateurCount = this.operators.filter(
          (operator) => operator.status === "operateur"
        ).length;
        if (subscription === "ecoClass") {
          if (
            this.editedItem.status.value === "operateur" &&
            operateurCount < 5
          ) {
            this.postUser();
          } else if (
            this.editedItem.status.value === "admin" &&
            adminCount < 5
          ) {
            this.postUser();
          } else {
            this.notSubscriberNotif();
          }
        } else if (subscription === "businessClass") {
          if (
            this.editedItem.status.value === "operateur" &&
            operateurCount < 10
          ) {
            this.postUser();
          } else if (
            this.editedItem.status.value === "admin" &&
            adminCount < 10
          ) {
            this.postUser();
          } else {
            this.notSubscriberNotif();
          }
        }
      }
    },
    async postLabo() {
      const checkEmail = await loginRepo.checkEmailExist(
        this.editedItem.email,
        store.state.token
      );

      if (checkEmail.data.length === 0) {
        const response = await laboRepo.postLabo({
          name: this.editedItem.laboName,
          active: this.editedItem.send,
          firstName: this.editedItem.firstName,
          lastName: this.editedItem.lastName,
          email: this.editedItem.email,
          subscription: this.editedItem.tags.value,
          sendEmail: this.editedItem.send ? true : false,
        });
        this.SnackbarShow("Le labo a bien été ajouté", response.status, 200);
        if (Boolean(this.editedItem.send) === true) {
          var laboId = JSON.parse(response.request.response);
          const request = await axios.post(
            API_BASE_URL + API_ROUTES.users,
            {
              email: this.editedItem.email,
              password: this.editedItem.password,
              firstName: this.editedItem.firstName,
              lastName: this.editedItem.lastName,
              status: "admin",
              tags: [],
              send: false,
              laboId: laboId.id,
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`,
              },
            }
          );
          var userId = JSON.parse(request.request.response);
          await axios.post(
            API_BASE_URL + API_ROUTES.admins,
            {
              firstName: this.editedItem.firstName,
              lastName: this.editedItem.lastName,
              email: this.editedItem.email,
              userId: userId.id.toString(),
              laboId: laboId.id,
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`,
              },
            }
          );
        }
        await axios.post(
          API_BASE_URL + API_ROUTES.tags,
          {
            tag: [
              {
                name: "Nouvelle commande",
                color: "#D0E5F7",
                colorText: "indigo--text",
                visible: true,
              },
              {
                name: "Départ",
                color: "#E74C3C39",
                colorText: "red--text",
                visible: true,
              },
              {
                name: "Nouveau projet",
                color: "#f0dbff",
                colorText: "purple--text",
                visible: true,
              },
            ],
            laboId: laboId.id,
          },
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`,
            },
          }
        );
        this.closeModal();
        this.operators = [];
        this.getAllLabo();
      } else {
        if (checkEmail.data.length > 0) {
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 5000,
            text: "Un compte enregistré avec cette adresse email existe déjà.",
            visible: true,
          };
        }
        await axios.post(API_BASE_URL + API_ROUTES.tags, {
          tag: [
            {
              name: "Nouvelle commande",
              color: "#D0E5F7",
              colorText: "indigo--text",
              visible: true,
            },
            {
              name: "Départ",
              color: "#E74C3C39",
              colorText: "red--text",
              visible: true,
            },
            {
              name: "Nouveau projet",
              color: "#f0dbff",
              colorText: "purple--text",
              visible: true,
            },
          ],
          laboId: laboId.id,
        });
        this.closeModal();
        this.operators = [];
        this.getAllLabo();
      }
    },

    async postUser() {
      const checkEmail = await loginRepo.checkEmailExist(
        this.editedItem.email,
        store.state.token
      );
      if (checkEmail.data.length > 0) {
        this.snackbar = {
          color: "error",
          icon: "mdi-alert-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: "Un compte enregistré avec votre adresse email existe déjà.",
          visible: true,
        };
      } else {
        if (this.userStatus === "labo") {
          this.postLabo();
        } else {
          const request = await user.postUser(this.editedItem);
          var userId = JSON.parse(request.request.response);
          if (this.editedItem.status.value === "operateur") {
            const response = await operator.postOperator({
              firstName: this.editedItem.firstName,
              lastName: this.editedItem.lastName,
              email: this.editedItem.email,
              userId: userId.id,
            });
            this.SnackbarShow(
              "L'utilisateur a bien été ajouté",
              response.status,
              200
            );
          } else if (this.editedItem.status.value === "admin") {
            const response = await admins.postAdmins({
              firstName: this.editedItem.firstName,
              lastName: this.editedItem.lastName,
              email: this.editedItem.email,
              userId: userId.id.toString(),
            });
            this.SnackbarShow(
              "L'utilisateur a bien été ajouté",
              response.status,
              200
            );
          } else if (this.editedItem.status.value === "praticien") {
            const response = await praticien.postPraticien({
              firstName: this.editedItem.firstName,
              lastName: this.editedItem.lastName,
              email: this.editedItem.email,
              sendEmail: Boolean(this.editedItem.send),
              userId: userId.id.toString(),
            });
            this.SnackbarShow(
              "L'utilisateur a bien été ajouté",
              response.status,
              200
            );
          }
          this.closeModal();
          this.operators = [];
          this.getAllUsers();
        }
      }
    },

    getName(firstName, lastName) {
      if (firstName.substr(0, 2) == "Dr") {
        const first = firstName.substr(3, 1);
        const second = lastName.substr(0, 1);
        return first.toUpperCase() + second.toUpperCase();
      } else {
        const first = firstName.substr(0, 1);
        const second = lastName.substr(0, 1);
        return first.toUpperCase() + second.toUpperCase();
      }
    },

    async editLabo() {
      const active = Boolean(this.editedItem.send);
      if (active === true && this.editedItem.sendEmail === false) {
        const request = await axios.post(
          API_BASE_URL + API_ROUTES.users,
          {
            email: this.editedItem.email,
            password: this.editedItem.password,
            firstName: this.editedItem.firstName,
            lastName: this.editedItem.lastName,
            status: "admin",
            tags: [],
            send: false,
            laboId: this.editedItem.id,
          },
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`,
            },
          }
        );
        var userId = JSON.parse(request.request.response);
        await admins.postAdmins({
          firstName: this.editedItem.firstName,
          lastName: this.editedItem.lastName,
          email: this.editedItem.email,
          userId: userId.id.toString(),
          laboId: this.editedItem.id,
        });
      }
      const response = await laboRepo.putLabo({
        name: this.editedItem.laboName,
        sendEmail: active ? true : false,
        active: Boolean(this.editedItem.send),
        subscription: this.editedItem.tags.value,
        firstName: this.editedItem.firstName,
        lastName: this.editedItem.lastName,
        email: this.editedItem.email,
        id: this.editedItem.id,
        destroyCas: this.editedItem.destroyCas,
      });
      this.SnackbarShow("Le labo a bien été modifié", response.status, 204);
      this.closeModal();
      this.operators = [];
      this.getAllLabo();
    },

    async putUser() {
      if (this.editedItem.status === "Labo") {
        this.editLabo();
      } else {
        await user.putUser(this.editedItem);
        if (this.editedItem.status === "operateur") {
          const request = await operator.getOperatorsByemail(
            this.editedItem.email
          );
          var operatoremail = request.data[0].id;
          const response = await operator.putOperator({
            firstName: this.editedItem.firstName,
            lastName: this.editedItem.lastName,
            email: this.editedItem.email,
            userId: this.editedItem.id,
            id: operatoremail,
          });
          this.SnackbarShow(
            "L'utilisateur a bien été modifié",
            response.status,
            204
          );
        } else if (this.editedItem.status === "admin") {
          const request = await admins.getAdminsByemail(this.editedItem.email);
          var adminemail = request.data[0].id;
          const response = await admins.putAdmins({
            firstName: this.editedItem.firstName,
            lastName: this.editedItem.lastName,
            email: this.editedItem.email,
            userId: this.editedItem.id,
            id: adminemail,
          });
          this.SnackbarShow(
            "L'utilisateur a bien été modifié",
            response.status,
            204
          );
        } else if (this.editedItem.status === "praticien") {
          const request = await praticien.getPraticiensByemail(
            this.editedItem.email
          );
          var praticienemail = request.data[0].id;
          const response = await praticien.putPraticien({
            firstName: this.editedItem.firstName,
            lastName: this.editedItem.lastName,
            email: this.editedItem.email,
            userId: this.editedItem.id,
            sendEmail: Boolean(this.editedItem.send),
            id: praticienemail,
          });
          this.SnackbarShow(
            "L'utilisateur a bien été modifié",
            response.status,
            204
          );
        }
        this.closeModal();
        this.operators = [];
        this.getAllUsers();
      }
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    async getCurrentTag() {
      return await tags.getTags();
    },
    async getAllOperators() {
      return await operator.getOperators();
    },
    async getAllUsers() {
      this.allOperators = await user.getAllUser();
      this.allOperators.data.forEach((item) => {
        if (this.userStatus === "admin") {
          if (item.status === "admin" || item.status === "operateur") {
            this.operators.push(item);
          }
        } else {
          if (item.status === this.userStatus) {
            this.operators.push(item);
          }
        }
      });
    },
    async getAllLabo() {
      this.allOperators = await laboRepo.getAllLabo();
      this.allOperators.data.forEach((item) => {
        this.operators.push({
          laboName: item.name,
          lastName: item.lastName,
          firstName: item.firstName,
          email: item.email,
          password: "",
          status: "Labo",
          tags: { value: item.subscription },
          send: item.active,
          id: item.id,
          sendEmail: item.sendEmail,
          destroyCas: item.destroyCas,
        });
      });
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 100px;
}
.rounded {
  border-radius: 15px !important;
}
.mainContent {
  display: flex;
  flex-direction: row;
}
.leftMenu {
  width: 5%;
}
.userManagmentContent {
  width: 95%;
}
.customToolbar {
  position: relative;
  border-radius: 10px;
  bottom: 2em;
  height: 100% !important;
}
.user-avatar {
  background: #1f6ed5;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: right;
  position: relative;
  bottom: 15px;
  right: 1em;
}
.initial {
  color: white;
  font-size: 20px;
  position: relative;
  margin: 10px 0px 0px 12px;
}
.modalIcon {
  color: #1f6ed5;
  background: #3498db40 0% 0% no-repeat padding-box;
  padding: 5px;
  border-radius: 50px;
}
.modalTitle {
  display: flex;
  justify-content: space-between;
}
.modalForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10000;
}
.modalFormInputStatus {
  width: 40%;
  padding: 30px;
}
.modalFormInput {
  width: 40%;
  padding: 30px;
}
@media screen and (max-width: 900px) {
  .leftMenu {
    display: none;
  }
  .casTable {
    width: 100%;
  }
  .modalForm[data-v-1ae719b6] {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .modalFormInput {
    width: 100%;
  }
  .modalFormInputStatus {
    width: 100%;
  }
  .saveBtnCard {
    text-align: center;
  }
}
</style>
