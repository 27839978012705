<template>
  <div>
    <mainLayout />
    <checkAcces page-access="admin" />
    <div class="contentAdmin mainContent">
      <leftMenu class="leftMenu" />
      <div class="param">
        <v-tabs v-model="tab" color="#003b5b" class="param-menu">
          <v-tab>Gestion des compétences</v-tab>
          <v-tab>Gestion des articles</v-tab>
          <v-tab>Import reférienciel articles</v-tab>
          <v-tab>Configuration InvoiceIt</v-tab>
          <v-tab>Destruction des Cas</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <tagsCompo />
          </v-tab-item>
          <v-tab-item> <materialCompo item-to-edit="article" /> </v-tab-item>
          <v-tab-item> <bulkAdd item-to-edit="article" /> </v-tab-item>
          <v-tab-item>
            <invoiceItConfiguration item-to-edit="article" />
          </v-tab-item>
          <v-tab-item> <destroyCasCompo /> </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import checkAcces from "../components/checkAcces.vue";
import mainLayout from "../layout/mainLayout.vue";
import leftMenu from "../layout/leftMenu.vue";
import tagsCompo from "../components/tags.vue";
import materialCompo from "../components/material.vue";
import bulkAdd from "../components/bulkAdd.vue";
import invoiceItConfiguration from "@/components/invoiceItConfiguration";
import destroyCasCompo from "../components/destroyCas.vue";

export default {
  components: {
    mainLayout,
    leftMenu,
    checkAcces,
    tagsCompo,
    materialCompo,
    destroyCasCompo,
    bulkAdd,
    invoiceItConfiguration,
  },
  data() {
    return {
      tab: null,
    };
  },
};
</script>

<style>
.theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent;
}
</style>

<style scoped>
.contentAdmin {
  display: flex;
  flex-direction: row;
}

.theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent;
}
.mainContent {
  display: flex;
  flex-direction: row;
}
.leftMenu {
  width: 5%;
}
.casTable {
  width: 95%;
  padding: 100px 20px 0px 20px;
  background: #fafafa;
}
.param {
  width: 100%;
}
.param-menu {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 900px) {
  .leftMenu {
    display: none;
  }
  .casTable {
    width: 100%;
  }
}
</style>
