import { API_BASE_URL, API_ROUTES } from "../api-constant.js";

import axios from "axios";
import store from "../store";

export default {
  async getArticles() {
    try {
      const request = await axios.get(
        API_BASE_URL + API_ROUTES.getArticlesByLaboId + store.state.user.laboId,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        }
      );
      return request;
    } catch (error) {
      if (process.browser) {
        localStorage.removeItem("vuex");
        window.location.href = "/login";
      }
    }
  },

  async postArticle(articles) {
    const request = await axios.post(
      API_BASE_URL + API_ROUTES.articles,
      {
        articles,
        laboId: store.state.user.laboId,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async putArticle(article) {
    const request = await axios.put(
      API_BASE_URL + API_ROUTES.articles + article.id,
      {
        articles: article.articles,
        laboId: store.state.user.laboId,
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },

  async deleteArticle(article) {
    const request = await axios.delete(
      API_BASE_URL + API_ROUTES.articles + article.id,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      }
    );
    return request;
  },
};
