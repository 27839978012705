<template>
  <div>
    <globalLayout />
    <checkAcces pageAccess="ecran" />
    <div class="globalContent">
      <CasTable v-bind:global="true" />
    </div>
  </div>
</template>

<script>
import checkAcces from "../components/checkAcces.vue";
import CasTable from "../components/casTable.vue";
import globalLayout from "../layout/globalLayout";

export default {
  data() {
    return {};
  },
  components: {
    CasTable,
    globalLayout,
    checkAcces,
  },
  mounted() {
    window.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        document.location.reload();
      }
    });
  },
  methods: {},
};
</script>

<style scoped>
.globalContent {
  background: #fafafa;
  padding: 40px;
  height: 100%;
  margin-top: 50px;
}
</style>
