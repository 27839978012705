<template>
  <div class="tagPage">
    <v-snackbar
      v-model="snackbar.visible"
      auto-height
      :color="snackbar.color"
      :multi-line="snackbar.mode === 'multi-line'"
      :timeout="snackbar.timeout"
      :top="snackbar.position === 'top'"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>
          {{ snackbar.icon }}
        </v-icon>
        <v-layout column>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 25px;
      "
    >
      <p v-if="existingEmail !== ''">
        <b>Identifiant existant :</b>
        {{ existingEmail }}
        <v-badge
          style="margin-left: 3px; margin-bottom: 3px"
          :color="isLoginChecked ? 'green' : 'red'"
          dot
        />
      </p>
    </div>
    <v-btn
      style="margin-top: 25px"
      color="#003b5b"
      outlined
      dark
      class="mb-2 addCasBtn"
      @click="openModal()"
    >
      Ajouter les identifiants
      <v-icon class="ml-3"> mdi-plus-circle-outline </v-icon>
    </v-btn>
    <template />
    <v-dialog v-model="modalAdd" max-width="400px" height="55%">
      <v-card class="modaltags">
        <v-card-title class="modalTitle">
          <span class="headline">
            {{ modalTitle }}
            <v-icon class="modalTagIcon" medium> {{ icon }} </v-icon>
          </span>
          <v-icon large @click="closeModal"> mdi-close </v-icon>
        </v-card-title>

        <div>
          <v-container class="lighten-5">
            <v-row>
              <v-col style="width: '100%">
                <v-card class="pa-2" outlined tile style="width: '100%">
                  <div class="header-col1">
                    <h5>Saisir vos identifiants InvoiceIt</h5>
                    <p style="font-size: 0.8rem">
                      Pour pouvoir exporter vos Cas directement sur InvoiceIt
                    </p>
                  </div>
                  <v-divider />
                  <div
                    style="display: flex; flex-direction: column; width: 100%"
                  >
                    <FormulateInput
                      v-model="email"
                      class="inputFormulate"
                      type="email"
                      name="email"
                      style="width: 100%"
                      label="Identifiant email"
                      placeholder="Saisir email"
                      validation="required|email"
                      error-behavior="live"
                    />

                    <FormulateInput
                      v-model="password"
                      class="inputFormulate"
                      type="password"
                      name="password"
                      label="Mot de passe"
                      placeholder="Saisir mot de passe"
                      style="padding-top: 20px; width: 100%"
                    />
                  </div>
                  <v-btn
                    class="greenBtn"
                    color="green darken-1"
                    text
                    @click="saveToBdd"
                  >
                    Ajouter les identifiants
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import laboRepo from "../../repository/laboRepository.js";
import axios from "axios";
export default {
  components: {},
  props: {
    itemToEdit: String,
  },
  data() {
    return {
      isLoginChecked: false,
      existingEmail: "",
      existingPass: "",
      email: "",
      password: "",
      parseCsv: [],
      articlesToSave: [],
      active: false,
      modalAdd: false,
      tags: null,
      editModal: false,
      currentTag: [],
      item: {
        name: "",
        color: "",
        visible: true,
      },
      tagId: null,
      rules: {
        required: (val) => val.length > 0 || "Ce champ est requis",
      },
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 7500,
        visible: false,
      },
      timeout: 7500,
    };
  },
  computed: {
    selector: function () {
      if (!this.selectedColor) {
        return "Couleur";
      } else {
        return (
          '<span style="background: ' +
          this.selectedColor +
          '"></span> ' +
          this.selectedColorName
        );
      }
    },
    modalTitle() {
      return "Configuration";
    },
    addButton() {
      return "Confirmer";
    },
    deleteButton() {
      return "Effacer les identifiants";
    },
    icon() {
      if (this.editModal === true) {
        return "mdi-pencil-outline";
      } else {
        return "mdi-account-plus";
      }
    },
  },
  async mounted() {
    const { data } = await laboRepo.getLaboById();
    if (data) {
      if (data.invoiceItEmail && data.invoiceItPassword) {
        this.existingEmail = data.invoiceItEmail;
        this.existingPass = data.invoiceItPassword;
        this.checkLogins(data.invoiceItEmail, data.invoiceItPassword);
      }
    }
  },
  methods: {
    async checkLogins(email, password) {
      let projectJSON = {
        Project: {
          DateTime: new Date().toLocaleString("en-US"),
          DueDate: new Date().toLocaleString("en-US"),
        },
      };
      //
      const url = "https://invoicit.app/api/project";
      //
      const params = new URLSearchParams();
      params.append("email", email);
      params.append("password", password);
      params.append("project", JSON.stringify(projectJSON));
      //
      const config = {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      };
      //
      var self = this;
      await axios
        .post(url, params, config)
        .then(function (response) {
          if (response.status === 400) {
            // Login is OK
            self.isLoginChecked = true;
          } else {
            self.isLoginChecked = false;
          }
          //
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            // Login is OK
            self.isLoginChecked = true;
          } else {
            self.isLoginChecked = false;
          }
        });
    },
    SnackbarShow(text, status, isOk) {
      if (status === isOk) {
        this.snackbar = {
          color: "success",
          icon: "mdi-check-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: text,
          visible: true,
        };
      } else {
        this.snackbar = {
          color: "error",
          icon: "mdi-alert-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: "Une erreur est survenue",
          visible: true,
        };
      }
    },
    saveToBdd: async function () {
      const { data } = await laboRepo.getLaboById();
      const request = await laboRepo.putLabo({
        id: data.id,
        name: data.name,
        active: data.active,
        subscription: data.subscription,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        sendEmail: data.sendEmail,
        destroyCas: data.destroyCas,
        invoiceItEmail: this.email || "",
        invoiceItPassword: this.password || "",
      });
      if (request.status === 204 || request.status === 200) {
        this.closeModal();
      }
    },
    toggleDropdown: function () {
      this.active = !this.active;
    },
    openModal(tag, index, edit) {
      if (edit) {
        this.editModal = true;
        this.item.name = tag.name;
        this.item.visible = tag.visible;
        this.item.index = index;
      }
      this.modalAdd = true;
    },
    closeModal() {
      this.modalAdd = false;
      this.editModal = false;
      this.item.name = "";
      this.SnackbarShow("Les identifiants on bien été ajoutés ");
    },
  },
};
</script>

<style>
.inputFormulate input {
  width: 90%;
  border: 1px solid #d1d5db;
  padding: 5px;
  border-style: inherit !important;
}
</style>

<style>
input[type="file"] {
  display: flex;
}

.theme--light.v-card {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 10px !important;
}

.header-col1 {
  display: flex;
  padding-top: 20px;
  padding-left: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.v-data-table > .v-data-table__wrapper > table {
  box-shadow: none !important;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: flex-start;
  align-items: flex-start;
}

.form-check {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.form-check-label {
  padding-left: 20px;
}

.form-check-input:checked {
  background-color: #003b5b;
  border-color: #003b5b;
}

.formulate-input-error {
  font-size: 0.5rem;
  color: indianred;
  font-weight: bold;
}

.btn-primary:disabled {
  color: #fff !important;
  background-color: #003b5b;
  border-color: #003b5b;
}

.btn-primary {
  color: #fff !important;
  background-color: #003b5b;
  border-color: #003b5b;
}

.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #003b5b;
  border-color: #003b5b;
}

.vue-csv-mapping {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  width: 100%;
}
.form-group {
  display: flex;
}

.form-control-file {
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  margin-bottom: 25px;
  justify-content: inherit;
  line-height: normal;
  position: relative;
  transition: inherit;
  transition-property: opacity;
}

.form-control-file + label {
  margin-left: 50%;
  transform: translateX(-50%);
  font-size: 1.25em;
  background-color: white;
  width: 13ch;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
  display: inline-block;
}

.form-control-file:focus + label,
.form-control-file + label:hover {
  background-color: #84ceeb;
  cursor: pointer;
}
/* end input stlyes */

select {
  moz-appearance: block !important;
  webkit-appearance: block !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  height: fit-content;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

.tagPage {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalTagIcon {
  color: green !important;
  background: #1abc9c40;
  padding: 5px;
  border-radius: 50px;
}
.tagName {
  margin-bottom: 0px !important;
  font-size: 15px;
  font-weight: bold;
}
.tagsForm {
  display: flex;
  flex-direction: row;
}
.tagsInput {
  margin: 20px 50px 20px 50px;
  width: 50% !important;
}
.modaltags {
  text-align: center !important;
  height: 350px;
}
#color-picker {
  width: 50% !important;
  margin: 30px 0px 0px 0px !important;
}
.tagsCards {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 50px;
  align-items: center;
  justify-content: center;
}
.tagCard {
  padding: 30px;
  height: 50px !important;
  text-align: center;
  border-radius: 30px !important;
  display: flex;
  align-items: center;
  margin: 20px;
  justify-content: center;
}
.center {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deleteTag {
  font-size: 25px !important;
  color: white !important;
  margin-left: 10px;
}
.v-chip .v-chip__content {
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: space-between;
}

* {
  box-sizing: border-box;
  font-family: "Arial";
}
.wrapper-dropdown {
  position: relative;
  width: 90% !important;
  background: #fff;
  color: #2e2e2e;
  outline: none;
  cursor: pointer;
  border-bottom: solid 1px rgba(0, 0, 0, 0.87);
  text-align: left;
}
.wrapper-dropdown > span {
  width: 100%;
  display: block;
  padding: 5px;
}
.wrapper-dropdown > span > span {
  padding: 5px 13px;
  margin-right: 10px;
  border-radius: 50px;
}
.wrapper-dropdown > span:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: 16px;
  top: calc(50% + 4px);
  margin-top: -6px;
  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #2e2e2e transparent;
}

.wrapper-dropdown .dropdown {
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  font-weight: normal;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  border-top: 0;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%),
    0 0 0 0 rgb(0 0 0 / 12%);
}

.upload {
  display: flex;
  flex-direction: column;
}
.upload > input {
  margin: 0 auto 20px auto;
}

.wrapper-dropdown .dropdown li {
  display: block;
  text-decoration: none;
  color: #2e2e2e;
  padding: 5px;
  cursor: pointer;
}
.theme--light.v-tabs-items {
  background: transparent !important;
}
.wrapper-dropdown .dropdown li > span {
  padding: 5px 13px;
  margin-right: 10px;
  border-radius: 50px;
}

.wrapper-dropdown .dropdown li:hover {
  background: #eee;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .tagsForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tagsInput {
    margin: 0px 0px 0px 0px;
    width: 80% !important;
  }
  #color-picker {
    margin-left: 45px !important;
    width: 80% !important;
  }
}
</style>
