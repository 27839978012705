<template>
  <div @keyup="enterLogin">
    <v-snackbar
      v-model="snackbar.visible"
      auto-height
      :color="snackbar.color"
      :multi-line="snackbar.mode === 'multi-line'"
      :timeout="snackbar.timeout"
      :top="snackbar.position === 'top'"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>
          {{ snackbar.icon }}
        </v-icon>
        <v-layout column>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
    <v-col class="content loginPage">
      <v-sheet width="100%" height="100vh" class="logo2">
        <img class="logo" alt="Logo" src="../assets/logo.png" />
        <div>
          <h1 class="candidat-title">
            {{ title }}
          </h1>
          <div class="input">
            <v-text-field
              v-if="!this.isLogin"
              v-model="email"
              :rules="[rules.email, rules.wrongemail]"
              label="Email"
              outlined
              color="#2b3f53"
              required
            />
            <v-text-field
              v-if="this.isLogin"
              v-model="this.$route.query.email"
              :rules="[rules.email, rules.wrongemail]"
              label="Email"
              outlined
              color="#2b3f53"
              required
              :disabled="getParamUrl() !== ''"
            />
          </div>
          <div class="input">
            <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              :rules="[rules.password, rules.wrongpassword]"
              label="Mot de passe"
              color="#2b3f53"
              outlined
              @click:append="show1 = !show1"
            />
            <p v-if="isLogin" id="rules">
              * Le mot de passe doit contenir au moins 1 majuscule, 8
              caractère,1 chiffre et 1 caractère spécial
            </p>
            <v-dialog
              v-model="dialog"
              transition="dialog-top-transition"
              max-width="600"
            >
              <template v-slot:activator="{ on, attrs }">
                <span v-if="!isLogin" id="msg" v-bind="attrs" v-on="on">
                  Mot de passe oublié ?
                </span>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar class="headModal" height="80px">
                    Mot de passe oublié ?
                  </v-toolbar>
                  <v-card-text>
                    <div class="text-body-1 pa-12 text-center">
                      Veuillez indiquer votre adresse email afin de recevoir un
                      mail dans le but de reinitialiser votre mot de passe.
                    </div>
                  </v-card-text>
                  <div class="modalContent">
                    <v-text-field
                      v-model="emailAdress"
                      placeholder="Adresse email"
                      style="width: 60%"
                      outlined
                    />
                    <v-btn
                      :loading="isLoading"
                      color="#2b3f53"
                      dark
                      x-large
                      elevation="2"
                      large
                      @click="putPassword()"
                    >
                      Valider
                    </v-btn>
                  </div>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false"> Fermer </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </div>
          <div class="input">
            <v-text-field
              v-if="isLogin"
              v-model="passwordConfirm"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              :rules="[rules.password, rules.wrongpassword]"
              label="Confirmation du mot de passe"
              color="#2b3f53"
              outlined
              @click:append="show1 = !show1"
            />
          </div>
          <div class="connexion">
            <v-btn
              :loading="isLoading"
              :disabled="isDisabled"
              outlined
              color="#2b3f53"
              @click="login"
            >
              {{ button }}
            </v-btn>
          </div>
          <!-- <div class="lost">
            <a href="accueil">Mot de passe perdu ?</a>
          </div> -->
        </div>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import users from "../../repository/userRepository.js";
import loginRepo from "../../repository/loginRepository.js";
import labo from "../../repository/laboRepository";
import { API_BASE_URL } from "../../api-constant.js";
import axios from "axios";
//import store from '../../store';
//import axios from "axios";
export default {
  data() {
    return {
      show1: false,
      password: "",
      passwordConfirm: "",
      email: "",
      urlResetKey: this.$route.query.resetKey,
      disabled: true,
      isLoading: false,
      dialog: false,
      emailAdress: "",
      rules: {
        email: (val) => val.length > 0 || "Ce champ est requis",
        password: (val) => val.length > 0 || "Ce champ est requis",
        wrongemail: null,
        wrongpassword: null,
      },
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 7500,
        visible: false,
      },
    };
  },
  computed: {
    isDisabled() {
      if (this.$route.query.email) {
        if (this.password.length > 0 && this.passwordConfirm.length > 0) {
          return false;
        }
        return true;
      } else {
        if (this.email.length > 0 && this.password.length > 0) {
          return false;
        }
        return true;
      }
    },
    title() {
      return this.$route.query.email ? "Créer mon mot de passe" : "Connexion";
    },
    isLogin() {
      return this.$route.query.email;
    },
    button() {
      return this.$route.query.email ? "Valider" : "Connexion";
    },
  },
  methods: {
    SnackbarShow(text, status, isOk) {
      if (status === isOk) {
        this.snackbar = {
          color: "success",
          icon: "mdi-check-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: text,
          visible: true,
        };
      } else {
        this.snackbar = {
          color: "error",
          icon: "mdi-alert-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: "Une erreur est survenue",
          visible: true,
        };
      }
    },
    getParamUrl() {
      if (this.$route.query.email) {
        return this.$route.query.email;
      } else {
        return "";
      }
    },
    enterLogin(event) {
      if (event.keyCode === 13) {
        this.login();
      }
    },
    async putPassword() {
      this.isLoading = true;
      const request = await loginRepo.resetPassword(this.emailAdress);
      if (request.status === 200) {
        this.SnackbarShow("L'email a été envoyé.", 204, 204);
        this.isLoading = false;
        this.emailAdress = "";
        this.dialog = false;
      } else {
        this.snackbar = {
          color: "error",
          icon: "mdi-alert-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: "Aucun compte n'est enregistré avec cette adresse email",
          visible: true,
        };
        this.isLoading = false;
      }
    },

    async login() {
      this.isLoading = true;
      if (this.$route.query.resetKey) {
        this.resetPassword();
      } else if (this.$route.query.loginResetKey) {
        if (
          this.password.match(/[0-9]/g) &&
          this.password.match(/[A-Z]/g) &&
          this.password.match(/[a-z]/g) &&
          this.password.match(/[^a-zA-Z\d]/g) &&
          this.password.length >= 8
        ) {
          if (this.passwordConfirm != this.password) {
            this.snackbar = {
              color: "error",
              icon: "mdi-alert-circle-outline",
              mode: "multi-line",
              position: "top",
              timeout: 5000,
              text: "Les mots de passes ne correspondent pas.",
              visible: true,
            };
            this.isLoading = false;
          } else {
            this.email = this.$route.query.email;
            const login = {
              email: this.email,
              password: this.password,
              resetKey: this.$route.query.loginResetKey,
            };
            const request = await loginRepo.postUserLogin(login);
            this.isDisabled = true;
            if (request.status === 200) {
              this.SnackbarShow(
                "Votre mot de passe à été créé avec succés",
                request.status,
                200
              );
              this.$router.replace("/login");
              // location.reload(login)
              // document.location.href="http://localhost:8080/login"
              this.isLoading = false;
            } else {
              this.SnackbarShow(
                "Votre mot de passe à été créé avec succés",
                request.status,
                200
              );
              this.isLoading = false;
            }
            this.isLoading = false;
            this.isDisabled = false;
          }
        } else {
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 5000,
            text: "Mot de passe faible",
            visible: true,
          };
          this.isLoading = false;
        }
      } else {
        const login = {
          email: this.email,
          password: this.password,
        };
        const request = await loginRepo.postConnection(login);
        this.isDisabled = true;
        if (request.status != 200) {
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 5000,
            text: "Une erreur est survenue",
            visible: true,
          };
          this.isLoading = false;
          // }
        } else {
          this.getUserWithToken(request.data.token);
          this.isLoading = false;
        }
        this.isDisabled = false;
      }
    },

    async resetPassword() {
      if (
        this.password.match(/[0-9]/g) &&
        this.password.match(/[A-Z]/g) &&
        this.password.match(/[a-z]/g) &&
        this.password.match(/[^a-zA-Z\d]/g) &&
        this.password.length >= 8
      ) {
        if (this.passwordConfirm != this.password) {
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle-outline",
            mode: "multi-line",
            position: "top",
            timeout: 5000,
            text: "Les mots de passes ne correspondent pas.",
            visible: true,
          };
          this.isLoading = false;
        } else {
          this.email = this.$route.query.email;
          const login = {
            resetKey: this.$route.query.resetKey,
            password: this.password,
            confirmPassword: this.passwordConfirm,
          };
          const request = await loginRepo.resetPasswordFinish(login);
          this.isDisabled = true;
          if (request.status === 200) {
            this.SnackbarShow(
              "Votre mot de passe à été créé avec succés",
              request.status,
              200
            );
            this.$router.replace("/login");
            // location.reload(login)
            // document.location.href="http://localhost:8080/login"
            this.urlResetKey = "";
            this.isLoading = false;
          } else {
            this.SnackbarShow(
              "Votre mot de passe à été créé avec succés",
              request.status,
              200
            );
            this.urlResetKey = "";
            this.isLoading = false;
          }
          this.isLoading = false;
          this.isDisabled = false;
        }
      } else {
        this.snackbar = {
          color: "error",
          icon: "mdi-alert-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: "Mot de passe faible",
          visible: true,
        };
        this.isLoading = false;
      }
    },

    async getUserWithToken(token) {
      await axios
        .get(API_BASE_URL + "/whoAmI", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(
          async (result) => {
            const user = await users.getUser(result.data.email, token);
            sessionStorage.setItem("currentUser", JSON.stringify(user.data[0]));
            this.$store.commit("SET_USER_INFO", user.data[0]);
            this.$store.commit("SET_USER_TOKEN", token);
            this.getLabo();
            if (
              user.data[0].email === "dequidt8@gmail.com" ||
              user.data[0].email === "greg@dentalinc.fr" ||
              user.data[0].email === "cyril@dentalinc.fr"
            ) {
              return this.$router.push("laboratories");
            }
            if (user.data[0].status === "admin") {
              return this.$router.push("accueil");
            }
            if (user.data[0].status === "ecran") {
              return this.$router.push("global");
            }
            if (user.data[0].status === "operateur") {
              return this.$router.push("operateur");
            }
            if (user.data[0].status === "praticien") {
              return this.$router.push("praticien");
            }
          },
          () => {
            this.snackbar = {
              color: "error",
              icon: "mdi-alert-circle-outline",
              mode: "multi-line",
              position: "top",
              timeout: 5000,
              text: "L'email ou le mot de passe ne correspondent pas.",
              visible: true,
            };
          }
        );
      /*        if (token) {
        const user = await users.getUser(result.data.email,token);
        sessionStorage.setItem("currentUser", JSON.stringify(user.data[0]));
        this.$store.commit("SET_USER_INFO", user.data[0]);
        this.getLabo()
        if (user.data[0].email === "dequidt8@gmail.com") {
          return this.$router.push("laboratories");
        }
        if (user.data[0].status === "admin") {
          return this.$router.push("accueil");
        }
        if (user.data[0].status === "ecran") {
          return this.$router.push("global");
        }
        if (user.data[0].status === "operateur") {
          return this.$router.push("operateur");
        }
        if (user.data[0].status === "praticien") {
          return this.$router.push("praticien");
        }
      } else {
        this.snackbar = {
          color: "error",
          icon: "mdi-alert-circle-outline",
          mode: "multi-line",
          position: "top",
          timeout: 5000,
          text: "L'email ou le mot de passe ne correspondent pas.",
          visible: true,
        };
      }  */
    },
    async getLabo() {
      const laboData = await labo.getLaboById();
      const laboName = laboData.data.name;
      this.$store.commit("SET_USER_LABO", laboName);
    },
  },
};
</script>

<style scoped>
.content {
  display: flex;
  width: 100%;
}
.headModal {
  background: #2b3f53 !important;
  color: white !important;
}
.modalContent {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.candidat-title {
  font-size: 20px;
  text-align: center;
  margin: 20px;
  color: #2b3f53;
}
.img-style {
  width: 100%;
  position: relative;
  max-height: 100%;
}
.logo {
  width: 300px;
}
.logo2 {
  width: 20%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.loginPage {
  padding: 0px;
}
#rules {
  color: grey;
  font-size: 13px;
  width: 100%;
  margin-left: 10px;
}
h3 {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 15px;
  color: #2b3f53;
}
.connexion {
  display: flex;
  justify-content: center;
  margin: 15px;
}
#msg {
  cursor: pointer;
  color: #2b3f53;
}
.lost {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  font-size: x-small;
  text-decoration: unset;
}

.input {
  width: 300px;
}

@media screen and (max-width: 1200px) {
  .imgLogin {
    display: none;
  }
  .loginPage {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo2 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
}
</style>
